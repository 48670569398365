import React, { useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid, IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Share } from '@mui/icons-material';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { riskControlsClient } from 'clients/riskControls/riskControlsClient';
import { RiskControlForm } from './RiskControlForm';


export const CreateRiskControl = () => {

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { genericError, genericFeedback, copyUrlToShare } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  const createRiskControl = useMutation(riskControlsClient.createRiskControl, {
    mutationKey: cacheKeys.riskControls.createRiskControl,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      navigate(routes.riskControls);
      genericFeedback(dictionary.riskControls.form.riskControlCreated);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (riskControl) => {
    return createRiskControl.mutate(riskControl);
  }, [createRiskControl]);

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item>
          <Card sx={{ p: 3, position: 'relative' }}>
            <Box position="absolute" top={16} right={16}>
              <Tooltip title={dictionary.riskControls.form.copyUrlToShare}>
                <IconButton onClick={() => copyUrlToShare(dictionary.riskControls.form.riskControlUrlCopied)}>
                  <Share/>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 4 }}>
              {dictionary.riskControls.form.createFormTitle}
            </Typography>
            <RiskControlForm
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.riskControls.form.createButtonLabel}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
