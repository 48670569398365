import { mapMinimalUserData } from 'clients/users/userClient.formatter';
import { mapComment } from 'clients/documentComments/documentCommentsClient.formatter';


export const mapDocument = (document: any, level: number = 1) => ({
  ...document,
  sub_pages: document?.sub_pages?.map((subPage: any) => mapDocument(subPage, level + 1)) || [],
  comments: document?.comments?.map(mapComment),
  created_by: document.created_by ? mapMinimalUserData(document.created_by) : {},
  updated_at: new Date(document.updated_at),
  created_at: new Date(document.created_at),
  level,
});
