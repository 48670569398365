import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { reportsClient } from 'clients/reports/reportsClient';

export type Params = {
  id: string;
};

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useReportTemplate = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: template } = {}, status, error } = useQuery(
    [cacheKeys.reports.getReportTemplate, params],
    () => reportsClient.getReportTemplate(params.id),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    template,
  };
};
