import React, { useCallback, useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Grid, IconButton, TextField, Tooltip, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { featureFlags, routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { emailRegex, passwordRegex } from 'helpers/validation';

import { useSignup } from 'shared/hooks/useSignup';
import { ReactComponent as GoogleLogo } from 'shared/img/google.svg';
import { ReactComponent as MicrosoftLogo } from 'shared/img/microsoft.svg';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';


export const SignUp = () => {

  const navigate = useNavigate();
  const { genericError, validationError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [showEmailAlreadyInUse, setShowEmailAlreadyInUse] = useState(false);
  const { watch, handleSubmit, control, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatPassword: '',
      acceptedTerms: false,
      company: '',
      jobTitle: '',
    },
  });

  const password = watch('password');

  const { signupAndLogin, signupWithSSO } = useSignup();

  const onSSOSignup = useCallback(async (provider: string) => {
    try {
      await signupWithSSO(provider);
    } catch (error: any) {
      validationError(error.message);
    }
  }, [signupWithSSO, validationError]);

  const onSubmit = useCallback(async (data) => {
    try {
      await signupAndLogin(data);
      navigate(routes.welcome);
    } catch(error: any) {
      if (error.response?.status === 400) {
        setShowEmailAlreadyInUse(true);
      }
      console.error(error);
      genericError();
    }
  }, [genericError, signupAndLogin, navigate]);

  return (
    <LoggedOutActionsLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center" style={{ maxWidth: 500 }}>
          <Grid item xs={12}>
            <Typography variant="h3" align="center">{dictionary.signUp}</Typography><br/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.user.fieldFirstName}
                  name="firstName"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.user.fieldLastName}
                  name="lastName"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="company"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.fieldCompany}
                  name="company"
                  error={!!errors.company}
                  helperText={errors.company?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="jobTitle"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.user.fieldJobTitle}
                  name="jobTitle"
                  error={!!errors.jobTitle}
                  helperText={errors.jobTitle?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                pattern: {
                  value: emailRegex,
                  message: dictionary.forms.validations.invalidEmail,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.fieldEmail}
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                minLength: {
                  value: 6,
                  message: dictionary.forms.validations.minLength(6),
                },
                maxLength: {
                  value: 20,
                  message: dictionary.forms.validations.maxLength(20),
                },
                pattern: {
                  value: passwordRegex,
                  message: dictionary.forms.validations.invalidPassword,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.fieldPassword}
                  name="password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="repeatPassword"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                validate: repeatPassword => repeatPassword === password || dictionary.passwordsDoNotMatch,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.signup.fieldRepeatPassword}
                  name="repeatPassword"
                  type="password"
                  error={!!errors.repeatPassword}
                  helperText={errors.repeatPassword?.message}
                />
              )}
            />
          </Grid>
          {showEmailAlreadyInUse && (
            <Grid item xs={12} sm={6}>
              <Typography>
                {dictionary.accountAlreadyExists}{' '}
                {dictionary.please}{' '}
                <Link to={routes.login} color="inherit"><u>{dictionary.login}</u></Link>
                {' '}{dictionary.orUseAnotherEmail}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                <Button size="large" variant="outlined" color="secondary" onClick={() => navigate(routes.login)}>
                  {dictionary.login}
                </Button>
              </Grid>
              <Box ml="auto"/>
              <Grid item>
                <LoadingButton loading={isSubmitting} size="large" type="submit" variant="contained" color="secondary">
                  {dictionary.signUp}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Box
        sx={theme => ({
          textAlign: 'center',
          '& svg': {
            width: 40,
            height: 40,
          },
        })}
      >
        {featureFlags.googleSSO && (
          <Tooltip title={dictionary.signUpWithGoogle}>
            <IconButton onClick={() => onSSOSignup('google')}>
              <GoogleLogo/>
            </IconButton>
          </Tooltip>
        )}
        {featureFlags.microsoftSSO && (
          <Tooltip title={dictionary.signUpWithMicrosoft}>
            <IconButton onClick={() => onSSOSignup('microsoft')}>
              <MicrosoftLogo/>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </LoggedOutActionsLayout>
  );
};
