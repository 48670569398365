import React, { FC, Fragment, useState, useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { Company } from 'clients/companies/companyClient.types';
import { UserCrud } from 'clients/users/userClient.types';
import { UserForm } from 'views/LoggedIn/User/partials/UserForm';


type Props = {
  company: Company;
};

export const AddCompanyUser: FC<Props> = ({ company }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { genericError, changesSaved } = useContext(LayoutContext);
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const queryClient = useQueryClient();
  const createUser = useMutation(usersClient.createUser, {
    mutationKey: cacheKeys.users.createUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      onClose();
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback((data: UserCrud) => {
    return createUser.mutate(data);
  }, [createUser]);

  return (
    <Fragment>
      <Button
        size="large"
        color="secondary"
        variant="contained"
        onClick={onOpen}
      >
        {dictionary.company.edit.buttonAddUser}
      </Button>
      <Dialog onClose={onClose} aria-labelledby="company-add-user" open={open}>
        <DialogTitle>
          {dictionary.company.edit.addUserTitle(company.name)}
        </DialogTitle>
        <DialogContent dividers>
          <UserForm
            onSubmitRequest={onSubmit}
            onSubmitButtonText={dictionary.company.edit.buttonAddUser}
            defaultValues={{
              company: company,
            }}
            disabledFields={['company', 'type']}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};
