import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs as Crumbs, Link } from '@mui/material';

export type Crumb = {
  text: string;
  link: string;
};

type Props = {
  list: Crumb[]; 
}

export const Breadcrumbs: FC<Props> = ({ list }) => {
  return (
    <Crumbs aria-label="breadcrumb">
      {list.map(({ text, link }) => (
        <Link key={link} color="inherit" component={RouterLink} to={link}>
          {text}
        </Link>
      ))}
    </Crumbs>
  );
};
