import React, { FC, MouseEventHandler, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar, Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid, IconButton,
  Tooltip,
} from '@mui/material';
import { ChevronRight, Settings } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext, UserContext, CompanyContext } from 'contexts';
import { getCountryLabel } from 'helpers/country';
import { Company } from 'clients/companies/companyClient.types';

export const CompanyCard: FC<Company> = (company) => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { isAdmin } = useContext(UserContext);
  const { selectCompany } = useContext(CompanyContext);

  const setCompany: MouseEventHandler<HTMLElement> = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    navigate(routes.home);
    selectCompany(company);
  }, [navigate, selectCompany, company]);

  const openSettings: MouseEventHandler<HTMLElement> = useCallback(event => {
    event.stopPropagation();
    event.preventDefault();
    navigate(routes.editCompany(company.id));
  }, [navigate, company.id]);

  return (
    <Card
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
      }}
      onClick={openSettings}
    >
      <CardHeader
        title={company.name}
        subheader={company.country && getCountryLabel(dictionary, company.country)}
      />
      <CardContent style={{ marginTop: -16 }}>
        <Grid container spacing={2}>
          {company.primaryContact && (
            <Grid item>
              <Tooltip title={dictionary.company.primaryContactLabel}>
                <Chip
                  style={{ background: 'none' }}
                  label={company.primaryContact.displayName}
                  avatar={<Avatar alt={company.primaryContact.displayName} src={company.primaryContact.profilePicture}/>}
                />
              </Tooltip>
            </Grid>
          )}
          {company.accountManager && (
            <Grid item>
              <Tooltip title={dictionary.company.accountManagerLabel}>
                <Chip
                  style={{ background: 'none' }}
                  label={company.accountManager.displayName}
                  avatar={<Avatar alt={company.accountManager.displayName} src={company.accountManager.profilePicture}/>}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions style={{ marginTop: 'auto' }}>
        <Grid container spacing={2} alignItems="center">
          {isAdmin && (
            <Grid item>
              <Tooltip title={dictionary.edit}>
                <IconButton
                  onClick={openSettings}
                >
                  <Settings/>
                </IconButton>
              </Tooltip>
            </Grid>
          )}
          <Box ml="auto"/>
          <Grid item>
            <Button
              variant="contained"
              endIcon={<ChevronRight/>}
              onClick={setCompany}
            >
              {dictionary.company.selectCompanyLabel}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
