import React, { Fragment, useContext } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { Footer } from 'shared/components/layout/Footer/Footer';
import { UsersFilters } from 'views/LoggedIn/Users/partials/UsersFilters';
import { UsersList } from 'views/LoggedIn/Users/UsersList';

export const Users = () => {

  const { dictionary } = useContext(LocalizationContext);

  const breadcrumbs = useBreadcrumbs('users');

  return (
    <Fragment>
      <Container maxWidth="xl">
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={4}>
                <Breadcrumbs list={breadcrumbs} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h2">{dictionary.users.title}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{ position: 'relative' }}>
                <UsersFilters>
                  {({ filters }) => (
                    <UsersList
                      filters={filters}
                    />
                  )}
                </UsersFilters>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer/>
    </Fragment>
  );
};
