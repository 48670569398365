import React, { FC, Fragment, useCallback, useContext } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useQueryClient, useMutation } from 'react-query';
import { Container, Grid, Box, Paper, Typography, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';

import { cacheKeys, routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { usersClient } from 'clients/users/usersClient';
import { UserCrud } from 'clients/users/userClient.types';
import { useUser } from 'shared/hooks/useUser';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { Footer } from 'shared/components/layout/Footer/Footer';

import { UserForm } from 'views/LoggedIn/User/partials/UserForm';

export const EditUser: FC = () => {

  const { userId = '' } = useParams();
  const navigate = useNavigate();
  const { genericError, changesSaved, askForUserConfirmation } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { user, status } = useUser({
    id: userId,
  });
  const breadcrumbs = useBreadcrumbs('editUser', {
    text: {
      editUser: user?.displayName,
    },
    routes: {
      editUser: [user?.id],
    },
  });

  const queryClient = useQueryClient();
  const editUser = useMutation(usersClient.editUser, {
    mutationKey: cacheKeys.users.editUser,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      queryClient.invalidateQueries([cacheKeys.users.getUser, {
        id: userId,
      }]);
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const deleteUser = useMutation(usersClient.deleteUser, {
    mutationKey: cacheKeys.users.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      queryClient.removeQueries([cacheKeys.users.getUser, {
        id: userId,
      }]);
      changesSaved();
      navigate(routes.users);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback((data: UserCrud) => {
    if (!user) {
      return false;
    }
    return editUser.mutate({
      ...data,
      id: user.id,
    });
  }, [user, editUser]);

  const onDelete = useCallback(async () => {
    const userConfirmed = await askForUserConfirmation({ title: dictionary.user.edit.deleteConfirmation });
    if (!user || !userConfirmed) {
      return false;
    }
    return deleteUser.mutate({
      id: user.id,
    });
  }, [user, deleteUser, askForUserConfirmation, dictionary]);

  return (
    <Fragment>
      <Container maxWidth="xl">
        <Box my={2}>
          {status !== 'success' || !user ? (
            <BodyLoading height="100vh"/>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mt={4} mb={2}>
                  <Breadcrumbs list={breadcrumbs} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} lg={8}>
                    <Typography component="h1" variant="h2">
                      {user.displayName}
                    </Typography>
                    <Box mt={2} p={4} component={Paper}>
                      <UserForm
                        defaultValues={{
                          firstName: user.firstName,
                          lastName: user.lastName,
                          email: user.email,
                          jobTitle: user.jobTitle,
                          company: user.company,
                          type: user.type,
                          phone: user.phone,
                          profilePicture: user.profilePicture,
                        }}
                        onSubmitRequest={onSubmit}
                        onSubmitButtonText={dictionary.forms.saveChanges}
                      />
                    </Box>
                    <Box mt={2}/>
                    <Button
                      onClick={onDelete}
                      size="large"
                      variant="outlined"
                      startIcon={<Delete/>}
                    >
                      {dictionary.user.edit.buttonDelete}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
      <Footer/>
    </Fragment>
  );
};
