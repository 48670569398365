import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';

import { featureFlags, routes } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { useInvitation } from 'shared/hooks/useInvitation';

import { useSignup } from 'shared/hooks/useSignup';
import { useQueryState } from 'shared/hooks/useQueryState';
import { ReactComponent as GoogleLogo } from 'shared/img/google.svg';
import { ReactComponent as MicrosoftLogo } from 'shared/img/microsoft.svg';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';
import { UserSignupForm } from './partials/UserSignupForm';

export const Invite = () => {

  const navigate = useNavigate();
  const { genericError, validationError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [id] = useQueryState('id');
  const { status, invite } = useInvitation({ id: id || '' }, {
    enabled: !!id,
  });

  const { signupAndLoginInvitation, signupWithSSOInvitation } = useSignup();

  const onSSOSignup = useCallback(async (provider: string) => {
    try {
      await signupWithSSOInvitation(provider, id);
    } catch (error: any) {
      validationError(error.message);
    }
  }, [signupWithSSOInvitation, validationError, id]);

  const onSubmit = useCallback(async (data) => {
    try {
      await signupAndLoginInvitation(data);
      navigate(routes.welcome);
    } catch(error: any) {
      console.error(error);
      genericError();
    }
  }, [genericError, signupAndLoginInvitation, navigate]);

  return (
    <LoggedOutActionsLayout>
      <Typography variant="h4" align="center">{dictionary.invite.title}</Typography>
      <Typography variant="body2" align="center">{dictionary.invite.subtitle(invite?.company?.name)}</Typography>
      <Box pt={4}>
        {status === 'success' && (
          <UserSignupForm
            disabledFields={['company']}
            defaultValues={{ userInvitationId: id, email: invite?.email }}
            onSubmitRequest={onSubmit}
            onSubmitButtonText={dictionary.invite.buttonAcceptInvitation}
          />
        )}
      </Box>
      <Box my={2}>
        <Divider orientation="horizontal" flexItem>
          {dictionary.auth.or}
        </Divider>
      </Box>
      <Box
        sx={theme => ({
          textAlign: 'center',
          '& svg': {
            width: 40,
            height: 40,
          },
        })}
      >
        {featureFlags.googleSSO && (
          <Tooltip title={dictionary.loginWithGoogle}>
            <IconButton onClick={() => onSSOSignup('google')}>
              <GoogleLogo/>
            </IconButton>
          </Tooltip>
        )}
        {featureFlags.microsoftSSO && (
          <Tooltip title={dictionary.loginWithMicrosoft}>
            <IconButton onClick={() => onSSOSignup('microsoft')}>
              <MicrosoftLogo/>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </LoggedOutActionsLayout>
  );
};
