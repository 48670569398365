import React, { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Tooltip,
} from '@mui/material';
import { ChevronRight, Work, Business } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { User, UserType } from 'clients/users/userClient.types';

export const UserCard: FC<User> = (user) => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        avatar={
          <Avatar alt={user.displayName} src={user.profilePicture} />
        }
        title={
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {user.displayName}
            </Grid>
            <Box ml="auto"/>
            {user.type === UserType.Admin && (
              <Grid item>
                <Chip
                  size="small"
                  label={dictionary.user.adminLabel}
                />
              </Grid>
            )}
          </Grid>
        }
        subheader={user.email}
      />
      <CardContent style={{ marginTop: -16 }}>
        <Grid container spacing={2}>
          {user.company && (
            <Grid item>
              <Tooltip title={dictionary.user.companyLabel}>
                <Chip
                  style={{ background: 'none' }}
                  label={user.company?.name}
                  icon={<Business/>}
                />
              </Tooltip>
            </Grid>
          )}
          {user.jobTitle && (
            <Grid item>
              <Tooltip title={dictionary.user.jobTitleLabel}>
                <Chip
                  style={{ background: 'none' }}
                  label={user.jobTitle}
                  icon={<Work/>}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions style={{ marginTop: 'auto' }}>
        <Grid container spacing={2} alignItems="center">
          <Box ml="auto"/>
          <Grid item>
            <Button
              variant="contained"
              endIcon={<ChevronRight/>}
              onClick={() => navigate(routes.editUser(user.id))}
            >
              {dictionary.user.edit.buttonEdit}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};
