import React, { FC } from 'react';
import { Paper } from '@mui/material';

import { AddCommentForm } from './Comments/AddCommentForm';
import { CommentList } from './Comments/CommentList';


export const Comments: FC<any> = ({ comments }) => {
  return (
    <Paper
      elevation={1}
      sx={{
        width: '240px',
        background: 'white',
        padding: '16px',
      }}
    >
      <CommentList comments={comments}/>
      <AddCommentForm/>
    </Paper>
  );
};
