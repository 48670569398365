import React, { useContext, useMemo } from 'react';
import { Container, Grid } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useTasks } from 'shared/hooks/useTasks';
import { useDashboardMetadata } from 'shared/hooks/useDashboardMetadata';
import { useDocuments } from 'shared/hooks/useDocuments';
import { useReports } from 'shared/hooks/useReports';
import { DashboardBox, DashboardBoxProps } from 'shared/components/layout/Dashboard/DashboardBox';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { DashboardListElement } from 'shared/components/layout/Dashboard/DashboardListElement';


export const Home = () => {

  const { scorecards, status } = useDashboardMetadata();
  const { dashboardDocuments, status: documentsStatus } = useDocuments();
  const { dashboardReports, status: reportsStatus } = useReports();
  const { tasks: tasksHighPriority } = useTasks({ status: 'immediate-action' });
  const { tasks: tasksRequiringAction } = useTasks({ status:'require-action' });
  const { dictionary } = useContext(LocalizationContext);

  const dashboardElements = useMemo(() => (
    [
      {
        title: dictionary.dashboard.tasks, elements: [tasksHighPriority, tasksRequiringAction],
      },
      {
        title: dictionary.dashboard.documents, elements: dashboardDocuments,
      },
      {
        title: dictionary.dashboard.reports, elements: dashboardReports,
      },
    ]
  ), [dictionary, tasksRequiringAction, tasksHighPriority, dashboardReports, dashboardDocuments]);


  if (status === 'loading' || documentsStatus === 'loading' || reportsStatus === 'loading') {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Container maxWidth="xl" sx={{ p: 1 }}>
      <Grid container direction="row" alignItems="center" justifyContent="center">
        {scorecards.map((
          item: DashboardBoxProps,
        ) => (
          <DashboardBox
            key={item.title}
            title={item.title}
            allItemsCount={item.allItemsCount}
            subElements={item.subElements}
            pointer={item?.pointer}
            link={item.link}
            subElementsMultipleRows={item.subElementsMultipleRows}
          />
        ))}
      </Grid>
      <Grid container direction="row" alignItems="center" justifyContent="center" mt={2}>
        <DashboardListElement dashboardElements={dashboardElements}/>
      </Grid>
    </Container>
  );
};
