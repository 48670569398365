import { useContext } from 'react';
import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { LocalizationContext } from 'contexts';
import { getFormattedDate } from 'helpers/date';
import { documentsClient } from 'clients/documents/documentsClient';

type Options = {
    enabled: boolean
  };
  
const defaultOptions: Partial<Options> = {
  enabled: true,
};

const flattenDocuments = (document: any) => {
  return [
    document,
    ...document.sub_pages.map(flattenDocuments),
  ];
};

const getFlattenedDocuments = (documents: any) => {
  if (!documents) {
    return [];
  }

  let flattenedDocuments = documents?.map(flattenDocuments);
  flattenedDocuments = flattenedDocuments.flat(4);
  const filteredDocuments = flattenedDocuments?.filter((document: any) => document.level < 5);
  return filteredDocuments;
};

export const useDocuments = (params?: any, options: Partial<Options> = defaultOptions) => {

  const { dictionary } = useContext(LocalizationContext);

  const { data: { data: { results: documents = [], count = undefined } = {} } = {}, status: documentsStatus, error } = useQuery(
    [cacheKeys.documents.getDocuments, params],
    () => documentsClient.getDocuments(params),
    { enabled: options.enabled },
  );

  const flatDocuments = getFlattenedDocuments(documents);
  const dashboardDocuments = documents &&
      documents.map((document: any) => ({
        id: document.id,
        title: document.title,
        updatedAt: getFormattedDate(document.updated_at, dictionary),
      }));

  return {
    flatDocuments,
    documents,
    count,
    status: documentsStatus,
    error,
    dashboardDocuments,
  };
};
