import React, { FC, useContext } from 'react';
import { Grid, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';

type Props = {
  message?: string;
}
export const ListEmpty: FC<Props> = ({ message }) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <Grid item>
        <Typography component="span" variant="h4" color="textSecondary">
          {message || dictionary.noResults}
        </Typography>
      </Grid>
    </Grid>
  );
};
