import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { cacheKeys, routes } from 'config';
import { LocalizationContext } from 'contexts';
import { createScoreCardSubElements, getDashboardElementType } from 'helpers/dashboard';
import { DashboardElementType } from 'clients/dashboard/dashboardClient.types';
import { dashboardClient } from 'clients/dashboard/dashboardClient';

export type Params = {
    id: string;
};

type Options = {
    enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useDashboardMetadata = (params?: Params, options: Partial<Options> = defaultOptions) => {
  const { dictionary } = useContext(LocalizationContext);

  const { data: { data: dashboardMetadata = [] } = {}, status, error } = useQuery(
    [cacheKeys.dashboard.getDashboardMetadata, params],
    () => dashboardClient.getDashboardMetadata(params),
    {
      enabled: options.enabled,
    },
  );

  const scorecards = useMemo(() => [
    {
      title: getDashboardElementType(dictionary, DashboardElementType.Incidents),
      allItemsCount: dashboardMetadata.events?.all,
      link: routes.incidents,
      pointer: true,
      subElements: dashboardMetadata.events && createScoreCardSubElements(dashboardMetadata.events),
    },
    {
      title: getDashboardElementType(dictionary, DashboardElementType.Corrections),
      allItemsCount: dashboardMetadata.corrections?.all,
      link: routes.incidents,
      subElementsMultipleRows: false,
      subElements: dashboardMetadata.corrections && createScoreCardSubElements(dashboardMetadata.corrections),
    },
    {
      title: getDashboardElementType(dictionary, DashboardElementType.Risks),
      allItemsCount: dashboardMetadata.risks?.all,
      link: routes.risks,
      subElements: dashboardMetadata.risks && createScoreCardSubElements(dashboardMetadata.risks).reverse(),
    },
    {
      title: getDashboardElementType(dictionary, DashboardElementType.RiskControls),
      allItemsCount: dashboardMetadata.risk_controls?.all,
      link: routes.riskControls,
      subElementsMultipleRows: false,
      subElements: dashboardMetadata.risk_controls && createScoreCardSubElements(dashboardMetadata.risk_controls),
    },
  ], [dictionary, dashboardMetadata]);

  return {
    status,
    error,
    scorecards,
  };
};