import React, { FC, useCallback } from 'react';
import { Slider } from '@mui/material';

export const SliderWrapper: FC<any> = ({ SliderProps, onChange, ...props }) => {

  const handleValueChange = useCallback((event, value) => {
    if (onChange === undefined) return;

    onChange(event, value);
  }, [onChange]);

  return (
    <Slider
      {...props}
      valueLabelDisplay={props.valueLabelDisplay ?? 'auto'}
      step={props.step ?? 1}
      onChange={(e, value) => handleValueChange(e, value)}
      disableSwap
    />
  );
};
