import React, { useContext, useCallback } from 'react';
import { Container, Grid, Typography, Box, TextField, Paper, MenuItem, MenuList } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';

import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { getDocumentCategoryLabel } from 'helpers/documents';
import { DocumentType } from 'clients/documents/documentsClient.types';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { DocumentTypeSelect } from 'shared/components/form/DocumentTypeSelect/DocumentTypeSelect';
import { ButtonDropdown } from 'shared/components/interactive/ButtonDropdown/ButtonDropdown';

import { DocumentsList } from './DocumentsList';

export const Documents = () => {

  const breadcrumbs = useBreadcrumbs('documents');
  const [category, setCategory] = useQueryState('category');
  const [search, , setSearch] = useDebouncedQueryState('search');
  const { dictionary } = useContext(LocalizationContext);
  const { openDialog } = useContext(DialogContext);
  const { isMobile } = useContext(LayoutContext);

  const onSelect = useCallback((documentType: any) => {
    localStorage.setItem('createDocumentType', documentType);     
    openDialog(Dialog.CreateDocument);
  }, [openDialog]);

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} position="relative">
          <Typography variant="h3" align="center">{dictionary.documents.list.title}</Typography>
          <ButtonDropdown
            label={dictionary.documents.create.createButton}
            placement="bottom-end"
            buttonProps={{
              sx: {
                position: 'absolute',
                top: '26px',
                right: '20px',
                minWidth: '120px',
              },
              variant: 'contained',
              size: 'medium',
              endIcon: open => open ? <ArrowDropUp/> : <ArrowDropDown/>,
            }}
            dropDownStyle={{
              minWidth: '120px',
              border: '1px solid #10285f',
            }}
            content={({ closeDropdown }) => (
              <MenuList>
                {Object.values(DocumentType).map(documentType => (
                  <MenuItem
                    onClick={() => {
                      onSelect(documentType);
                      closeDropdown();
                    }}
                  >
                    {getDocumentCategoryLabel(dictionary, documentType)}
                  </MenuItem>
                ))}
              </MenuList>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Breadcrumbs list={breadcrumbs}/>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Grid item xs={12} sm={9}>
                <TextField
                  label={dictionary.filters.search}
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DocumentTypeSelect
                  value={category}
                  onChange={(event: any) => setCategory(event.target.value !== 'all' ? event.target.value : undefined)}
                  defaultValue="all"
                  allowAll
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DocumentsList/>
        </Grid>
      </Grid>
    </Container>
  );
};
