import React, { FC, useContext, useState } from 'react';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { format } from 'date-fns';

import { LocalizationContext } from 'contexts';
import { getActionLabel } from 'helpers/activityLog';
import { ActivityLogAction } from 'clients/reports/reportsClient.types';


export const ActivityLog: FC<any> = ({ activityLogs }) => {
  const { dictionary } = useContext(LocalizationContext);
  const [isExpanded, setIsExpanded] = useState(false);

  if (!activityLogs?.length) {
    return null;
  }

  const latestActivity = activityLogs[0];
  
  return (
    <Box justifyContent="end" alignItems="center" display="flex" position="relative" className="no-print">
      {isExpanded && (
        <Paper
          elevation={1}
          sx={{
            display: 'flex',
            position: 'absolute',
            flexWrap: 'nowrap',
            top: '0',
            paddingX: '10px',
            zIndex: 2,
          }}
        >
          <Box>
            {activityLogs.map((log: any, index: number) => (
              <Box
                key={log.id}
                alignItems="center"
                display="flex"
                sx={{
                  borderTop: index === 0 ? undefined : '1px solid #e0e0e0',
                  paddingY: '10px',
                }}>
                <Typography variant="body1" ml="4px" sx={{ width: '120px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {log.user?.displayName || '-'}
                </Typography>
                <Typography variant="body1" sx={{ width: '118px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                  {format(new Date(log.created_at), 'dd MMM yyyy, HH:mm')}
                </Typography>
                <Typography variant="body1" ml="10px">
                  {getActionLabel(dictionary)[log.action as ActivityLogAction]}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box ml="10px">
            <IconButton onClick={() => setIsExpanded(false)}>
              <KeyboardArrowUp/>
            </IconButton>
          </Box>
        </Paper>
      )}
      <Typography variant="body1">{dictionary.activityLog.label}</Typography>
      <Typography variant="body1" ml="30px">{latestActivity.user?.displayName || '-'}</Typography>
      <Typography variant="body1" ml="16px">{format(latestActivity.created_at, 'dd MMM yyyy, HH:mm')}</Typography>
      <Typography variant="body1" ml="16px">{getActionLabel(dictionary)[latestActivity.action as ActivityLogAction]}</Typography>
      {activityLogs.length > 1 && (
        <IconButton onClick={() => setIsExpanded(true)} sx={{ ml: '20px', mt: '-2px' }}>
          <KeyboardArrowDown/>
        </IconButton>
      )}
    </Box>
  );
};
