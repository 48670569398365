import { Dispatch, SetStateAction, useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router';

import { Maybe } from 'types';
import { addQuery, parseQuery } from 'helpers/query';

export const useDebouncedQueryState = <T = string>(name: string, delay: number = 500): [Maybe<T>, Maybe<T>, Dispatch<SetStateAction<T | undefined>>] => {

  const navigate = useNavigate();
  const location = useLocation();
  const timeout = useRef<number>();

  const query = useMemo(() => {
    const value = parseQuery(location.search)[name];
    return value as unknown as Maybe<T>;
  }, [location.search, name]);

  const [value, setValue] = useState(query);

  const updateHistory = useCallback(() => {
    navigate({
      search: addQuery(location.search, {
        [name]: value,
      }),
    });
  }, [navigate, location.search, name, value]);

  useEffect(
    () => {
      if (query !== value) {
        timeout.current = window.setTimeout(() => {
          updateHistory();
        }, delay);
      }

      return () => {
        window.clearTimeout(timeout.current);
      };
    },
    [updateHistory, delay, query, value],
  );

  useEffect(() => {
    setValue(query);
  }, [query]);

  return [
    value,
    query,
    setValue,
  ];
};
