import React, { FC, useContext, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField, Tooltip,
  Typography,
} from '@mui/material';
import { Check, Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DateRangePicker } from '@mui/lab';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { Dictionary } from 'contexts/LocalizationContext/types';
import { addQuery } from 'helpers/query';
import { getRiskControlStatusLabel } from 'helpers/riskControl';
import { Severity } from 'helpers/helpers';
import { riskControlsClient } from 'clients/riskControls/riskControlsClient';
import { RiskControlStatus } from 'clients/riskControls/riskControlsClient.types';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useUsers } from 'shared/hooks/useUsers';
import { useRiskControls } from 'shared/hooks/useRiskControls';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { RiskControlStatusSelect } from 'shared/components/form/RiskControlStatusSelect/RiskControlStatusSelect';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


export const RiskControlsActions: FC<any> = ({ row }) => {
  const queryClient = useQueryClient();
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChecking, setIsChecking] = useState(false);

  const onDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      const userConfirmed = await asyncConfirmation({ title: dictionary.riskControls.list.deletePrompt });

      if (userConfirmed) {
        await riskControlsClient.deleteRiskControl(row.id);
        await queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
        await queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
        await queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
        genericFeedback(dictionary.riskControls.form.riskControlDeleted);
      } else {
        setIsDeleting(false);
      }
    } catch(e) {
      genericError();
      console.error(e);
    }
    setIsDeleting(false);
  }, [row, queryClient, asyncConfirmation, genericError, genericFeedback, dictionary]);

  const onCheck = useCallback(async () => {
    setIsChecking(true);
    try {
      await riskControlsClient.checkRiskControl(row.id);
      await queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      await queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      genericFeedback(dictionary.riskControls.form.riskControlChecked);
    } catch(e) {
      genericError();
      console.error(e);
    }
    setIsChecking(false);
  }, [row, queryClient, genericError, genericFeedback, dictionary]);

  return (
    <Grid container justifyContent="end" wrap="nowrap">
      {(row.status !== RiskControlStatus.Effective) && (
        <Grid item>
          <Tooltip
            title={dictionary.riskControls.list.markAsCheckedTooltip}
          >
            <IconButton onClick={onCheck} disabled={row.status === RiskControlStatus.Effective}>
              {isChecking ? <CircularProgress style={{ width: 22, height: 22 }}/> : <Check/>}
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <Tooltip
          title={dictionary.riskControls.list.editTooltip}
        >
          <IconButton onClick={() => navigate(routes.editRiskControl(row.id))}>
            <Edit/>
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={dictionary.riskControls.list.deleteTooltip}
        >
          <IconButton onClick={onDelete}>
            {isDeleting ? <CircularProgress style={{ width: 22, height: 22 }}/> : <Delete/>}
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const columns = (dictionary: Dictionary): GridColDef[] => ([
  {
    field: 'id',
    headerName: dictionary.riskControls.list.fieldId,
    width: 60,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'source',
    headerName: dictionary.riskControls.list.fieldSource,
    width: 120,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.control.source,
    renderCell: ({ value }) => {
      return (
        <Tooltip
          title={dictionary.riskControls.list.clickToOpen}
        >
          <Link 
            href={value}
            target="_blank"
            rel="noreferrer noopener"
            style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {value}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'status',
    headerName: dictionary.riskControls.list.fieldStatus,
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    width: 140,
    renderCell: ({ value, row }) => {
      if (!value) {
        return null;
      }

      let color: Severity = Severity.Success;

      if (value === RiskControlStatus.RequiresAction) {
        color = Severity.Warning;
      }

      if (value === RiskControlStatus.NonEffective) {
        color = Severity.Error;
      }

      return (
        <Chip
          variant="outlined"
          color={color}
          label={getRiskControlStatusLabel(dictionary, value)}
        />
      );
    },
  },
  {
    field: 'control',
    headerName: dictionary.riskControls.list.fieldControl,
    flex: 1,
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value }) => value && (
      <Tooltip
        title={
          <div>
            <div>{dictionary.riskControls.list.controlTooltipTitle}</div>
            <ul>
              <li>{value.title}</li>
            </ul>
          </div>
        }
      >
        <Chip
          size="small"
          label={value.control_id}
        />
      </Tooltip>
    ),
  },
  {
    field: 'risks',
    headerName: 'Risks',
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    flex: 2,
    renderCell: ({ value, row }) => (
      <Tooltip
        title={
          <div>
            <div>{dictionary.riskControls.list.risksTooltipTitle}</div>
            <ul>
              {(value || []).map((risk: any, i: number) => <li key={i}>{risk.risk}</li>)}
            </ul>
          </div>
        }
      >
        <div style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{(value || []).map((risk: any) => risk.risk).join(', ')}</div>
      </Tooltip>
    ),
  },
  {
    field: 'design',
    headerName: dictionary.riskControls.list.fieldDesign,
    width: 120,
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    valueGetter: ({ row }) => row.control.design,
    renderCell: ({ value }) => {
      return (
        <Tooltip
          title={dictionary.riskControls.list.clickToOpen}
        >
          <Link 
            href={value} 
            target="_blank" 
            rel="noreferrer noopener"
            style={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
          >
            {value}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    field: 'owner',
    headerName: dictionary.riskControls.list.fieldOwner,
    flex: 1,
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value }) => value && (
      <Grid container alignItems="center" spacing={1} wrap="nowrap">
        <Grid item>
          <Avatar sx={{ margin: 'auto', mr: 1, width: 30, height: 30, fontSize: 12, color: '#ececec' }}>
            {(`${value?.first_name || ''} ${value?.last_name || ''}`).split(' ').map((s: string) => s[0]).join('')}
          </Avatar>
        </Grid>
        <Grid item textOverflow="ellipsis" overflow="hidden">
          {`${value?.first_name || ''} ${value?.last_name || ''}`}
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'edit',
    headerName: '',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    width: 120,
    renderCell: RiskControlsActions,
  },
]);



export const RiskControls: FC<any> = () => {

  useScrollTop();
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = useUsers();
  const { isMobile, genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const [gteDate] = useQueryState('gteDate');
  const [lteDate] = useQueryState('lteDate');
  const [search, currSearch, setSearch] = useDebouncedQueryState('search');
  const [owner, setOwner] = useQueryState('owner');
  const [status, setStatus] = useQueryState('status');

  const { riskControls, status: riskControlsStatus, error } = useRiskControls({
    search: currSearch,
    status,
    owner,
    gteDate,
    lteDate,
  });

  if (users.length === 0) {
    return null;
  }

  if (error) {
    genericError();
  }

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mb: 8 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">{dictionary.riskControls.list.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={dictionary.riskControls.list.fieldSearch}
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateRangePicker
                  inputFormat="dd.MM.yyyy"
                  onChange={(([newGteDate, newLteDate]: any) => {
                    try {
                      navigate({
                        search: addQuery(location.search, {
                          gteDate: newGteDate ? format(newGteDate, 'yyyy-MM-dd'): null,
                          lteDate: newLteDate ? format(newLteDate, 'yyyy-MM-dd'): null,
                        }),
                      });
                    } catch(e) {
                      console.error(e);
                    }
                  })}
                  value={[gteDate ? new Date(gteDate) : null, lteDate ? new Date(lteDate) : null]}
                  renderInput={(startProps, endProps) => (
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          {...startProps}
                          label={dictionary.riskControls.list.fieldDueFrom}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          {...endProps}
                          label={dictionary.riskControls.list.fieldDueTo}
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <RiskControlStatusSelect
                  value={status}
                  onChange={(e: any) => setStatus(e.target.value !== 'all' ? e.target.value : undefined)}
                  defaultValue="all"
                  allowAll
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <UserAutocomplete
                  value={users.find(({ id }) => `${id}` === owner)}
                  onChange={(value: any) => setOwner(value?.id)}
                  label={dictionary.riskControls.list.fieldOwner}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {(riskControlsStatus === 'loading' || !riskControls) && (
          <BodyLoading height="40vh"/>
        )}
        {riskControlsStatus !== 'loading' && (
          <Grid item xs={12}>
            <Box p={2} component={Paper}>
              <DataGrid
                columns={columns(dictionary)}
                rows={riskControls}
                autoHeight
                rowsPerPageOptions={[10, 20, 50, 100]}
                getRowClassName={() => 'row'}
                disableSelectionOnClick
                sx={{
                  '& .row': {
                    cursor: 'pointer',
                  },
                  '& .row:hover': {
                    background: 'rgba(255, 255, 255, 0.1) !important',
                  },
                  '& .row:nth-of-type(even)': {
                    background: 'rgba(255, 255, 255, 0.05)',
                  },
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
