import React, { FC, useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useRisks } from 'shared/hooks/useRisks';

export const RiskAutocomplete: FC<any> = ({ label, TextFieldProps, ...props }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { risks } = useRisks();

  return (
    <Autocomplete
      options={risks}
      renderInput={props => (
        <TextField
          {...props}
          label={label || dictionary.riskControls.form.fieldRisks}
          InputLabelProps={{ shrink: true }}
          {...TextFieldProps}
        />
      )}
      getOptionLabel={(risk: any) => risk.risk}
      {...props}
      onChange={(e, value) => props.onChange(value)}
    />
  );
};
