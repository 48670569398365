import { AxiosResponse } from 'axios';
import { request } from 'clients/baseClient';

import { mapReportData } from './reportsClient.formatter';

const reportsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getReports = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/reports/`,
      method: 'GET',
      params,
    },
  });
};

const getReport = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/reports/${id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: mapReportData(data.data),
  }));;
};

const createReport = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/reports/`,
      method: 'POST',
      data: data,
    },
  });
};

const editReport = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/reports/${data.id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchReport = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/reports/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteReport = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/reports/${id}/`,
      method: 'DELETE',
    },
  });
};

const getReportTemplates = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/report-templates/`,
      method: 'GET',
      params: {
        category__in: (params.category || []).join(','),
      },
    },
  });
};

const getReportTemplate = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${reportsApiBaseUrl}/report-templates/${id}`,
      method: 'GET',
    },
  });
};


export const reportsClient = {
  createReport,
  deleteReport,
  editReport,
  patchReport,
  getReports,
  getReport,
  getReportTemplates,
  getReportTemplate,
};
