import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { companyClient } from 'clients/companies/companyClient';

export type Params = {
  id: number | string;
};

export const useCompany = (params: Params, enabled: boolean = true) => {
  const { data: { data: company } = {}, status, error, refetch } = useQuery(
    [cacheKeys.companies.getCompany, params],
    () => companyClient.getCompany(params),
    { enabled },
  );

  return {
    status,
    error,
    company,
    refetch,
  };
};
