import { useCallback, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Edit,
  Save,
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { getEventStatusLabels } from 'helpers/event';
import { isValidDate } from 'helpers/date';
import { isAnswerEmpty } from 'helpers/report';
import { sanitizeDescription } from 'helpers/html';
import { EventStatus } from 'clients/events/eventsClient.types';
import { reportQuestionsClient } from 'clients/reportQuestions/reportQuestionsClient';
import { eventsClient } from 'clients/events/eventsClient';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useEvent } from 'shared/hooks/useEvent';
import { useUsers } from 'shared/hooks/useUsers';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { ActivityLog } from 'shared/components/layout/ActivityLog/ActivityLog';

import { ReportQuestionList } from 'views/LoggedIn/Report/ReportQuestionList';
import { QuestionForm } from 'views/LoggedIn/Report/QuestionForm';


export const IncidentFulfillmentForm = () => {

  useScrollTop();
  const navigate = useNavigate();
  const { users } = useUsers();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = useContext(LayoutContext);
  const [expandAll, setExpandAll] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const { incidentId = null }: any = useParams();

  const { event, error, status } = useEvent({ id: incidentId });

  const queryClient = useQueryClient();
  const createAnswer = useMutation(reportQuestionsClient.createAnswer, {
    mutationKey: cacheKeys.reports.createAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.events.getEvent);
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const patchAnswer = useMutation(reportQuestionsClient.patchAnswer, {
    mutationKey: cacheKeys.reports.patchAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.events.getEvent);
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const deleteAnswer = useMutation(reportQuestionsClient.deleteAnswer, {
    mutationKey: cacheKeys.reports.deleteAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.events.getEvent);
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const patchEvent = useMutation(eventsClient.patchEvent, {
    mutationKey: cacheKeys.events.editEvent,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.events.getEvent);
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const onEventFieldChange = useCallback((fieldName: string, value: any) => {
    const updatedEvent = {
      id: event.id,
      [fieldName]: value,
    };
    return patchEvent.mutateAsync(updatedEvent);
  },[patchEvent, event]);

  const onAnswerChange = useCallback((data: any) => {
    const payload = {
      reportId: event.report_set[0].id,
      questionId: data.questionId,
      answerId: data.answerId,
      data: {
        [data.answerType]: data.answerValue,
      },
    };

    if (isAnswerEmpty(data)) {
      return deleteAnswer.mutateAsync(payload);
    }

    if (payload.answerId) {
      return patchAnswer.mutateAsync(payload);
    }
    
    return createAnswer.mutateAsync(payload);
  }, [createAnswer, deleteAnswer, patchAnswer, event]);

  if(status === 'loading' || !users?.length) {
    return <BodyLoading height="40vh"/>;
  }

  if(error) {
    navigate(routes.incidents);
    return null;
  }

  return (
    <Container maxWidth="xl">
      {!isEditingTitle && (
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <Typography variant="h4" sx={{ p: 2, textTransform: 'capitalize' }} align="center">{event?.title}</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={dictionary.incidents.form.editTheIncidentTitle}>
              <IconButton
                aria-label={dictionary.incidents.form.editTheIncidentTitle}
                onClick={() => setIsEditingTitle(true)}
                edge="end"
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {isEditingTitle && (
        <Grid container alignItems="center" justifyContent="center" mb={2} mt={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              defaultValue={event?.title}
              onBlur={(event) => {
                onEventFieldChange('title', event.target.value);
                setIsEditingTitle(false);
              }}
            />
          </Grid>
          <Grid item ml={2}>
            <Tooltip title={dictionary.incidents.form.saveTheIncidentTitle}>
              <IconButton
                aria-label={dictionary.incidents.form.saveTheIncidentTitle}
                onClick={() => setIsEditingTitle(false)}
              >
                <Save />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <LinearProgress variant="determinate" value={event?.report_set?.[0]?.meta?.progress?.percentage || 0} sx={{ m: 'auto', maxWidth: 400, mb: 4 }}/>
      {event?.report_set?.[0]?.description && (<Typography variant="body1" sx={{ mt: 2, mb: 3 }} dangerouslySetInnerHTML={{ __html: sanitizeDescription(event.report_set[0].description) }}/>)}
      <ActivityLog activityLogs={event.activity_logs}/>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={2} wrap="nowrap">
            <Grid item xs={12} sm={4}>
              <UserAutocomplete
                label={dictionary.reports.form.fieldReporter}
                TextFieldProps={{ size: 'small' }}
                defaultValue={users.find(({ id }: any) => event?.created_by?.id === id)}
                onChange={(selectedUser: any) => selectedUser && onEventFieldChange('created_by', selectedUser?.id)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <QuestionForm
                name="start_date"
                defaultValue={event?.entry_date}
                onSubmit={(value: any) => isValidDate(value) && onEventFieldChange('start_date', format(value, 'yyyy-MM-dd'))}
              >
                <Controller
                  name="start_date"
                  rules={{ required: dictionary.incidents.form.incidentDateRequired }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      inputFormat="dd.MM.yyyy"
                      mask="__.__.____"
                      label={dictionary.incidents.form.fieldIncidentDate}
                      renderInput={(props) => <TextField size="small" {...props}/>}
                    />
                  )}
                />
              </QuestionForm>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label={dictionary.incidents.form.fieldStatus}
                value={getEventStatusLabels(dictionary)[event.status as EventStatus]}
                size="small"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item>
              <Tooltip title={expandAll ? dictionary.reports.form.colapseAll : dictionary.reports.form.expandAll}>
                <IconButton onClick={() => setExpandAll(prevState => !prevState)} sx={{ mr: -1 }}>
                  {expandAll ? <UnfoldLess/> : <UnfoldMore/>}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ReportQuestionList report={event.report_set[0]} expandAll={expandAll} setExpandAll={setExpandAll} onAnswerChange={onAnswerChange}/>
      <Box textAlign="center" mt={4} mb={20}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => navigate(routes.incidents)}
        >
          {dictionary.incidents.form.backToIncidentsButton}
        </Button>
      </Box>
    </Container>
  );
};
