import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';
import { GetUsersParams } from 'clients/users/userClient.types';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useUsers = (params: GetUsersParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.users.getUsers, params],
    () => usersClient.getUsers(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    users: data?.results || [],
  };
};
