import React, { useContext, useMemo, useState, forwardRef } from 'react';
import { useNavigate } from 'react-router';
import { SpeedDial as MuiSpeedDial, SpeedDialAction } from '@mui/material';
import { Add, Assignment, AssignmentLate, Campaign, Equalizer, Policy, Warning } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts';


export const SpeedDial = forwardRef((props, ref) => {

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  const actions = useMemo(() => {
    const actions = [
      { icon: <AssignmentLate/>, name: dictionary.speedDial.risk, link: routes.createRisk, enabled: true },
      { icon: <Policy/>, name: dictionary.speedDial.control, link: routes.createControl, enabled: false },
      { icon: <Assignment/>, name: dictionary.speedDial.task, link: routes.createTask, enabled: true },
      { icon: <Warning/>, name: dictionary.speedDial.riskControl, link: routes.createRiskControl, enabled: true },
      { icon: <Equalizer/>, name: dictionary.speedDial.report, link: routes.createReport, enabled: true },
      { icon: <Campaign/>, name: dictionary.speedDial.incident, link: routes.createIncident, enabled: true },
    ];
    return actions.filter(({ enabled }) => enabled);
  }, [dictionary]);

  return (actions.length > 0) ? (
    <MuiSpeedDial
      ref={ref}
      ariaLabel="SpeedDial"
      color="secondary"
      sx={theme => ({
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
      })}
      icon={<Add sx={{ fill: (theme: any) => theme.palette.primary.light }}/>}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      direction="up"
    >
      {actions.map((action) => (
        <SpeedDialAction
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          onClick={() => [navigate(action.link), setOpen(false)]}
          style={{ whiteSpace: 'nowrap' }}
          tooltipOpen
        />
      ))}
    </MuiSpeedDial>
  ) : null;
});
