import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { controlsClient } from 'clients/controls/controlsClient';

type Options = {
    enabled: boolean
  };
  
const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useControls = (params?: any, options: Partial<Options> = defaultOptions) => {

  const { data: { data: { results: controls = [], count = undefined } = {} } = {}, status, error } = useQuery(
    [cacheKeys.controls.getControls, params],
    () => controlsClient.getControls(params),
    { enabled: options.enabled },
  );

  return {
    controls,
    count,
    status,
    error,
  };
};
