export enum EventClassification {
  Incident = 'incident',
  Complaint = 'complaint',
  NonConformity = 'nonconformity',
  Feedback = 'feedback',
  Report = 'report',
};

export enum EventStatus {
  New = 'new',
  CorrectionPlanned = 'correction_planned',
  CorrectionInProgress = 'correction_in_progress',
  Planned = 'planned',
  InProgress = 'in_progress',
  Closed = 'closed',
};

export enum EventActivityLogAction {
  CorrectionPlanned='correction_planned',
  CorrectionInProgress='correction_in_progress',
  Closed='closed'
};
