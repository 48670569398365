import isEmpty from 'lodash/isEmpty';

import { Dictionary } from 'contexts/LocalizationContext/types';
import { ReportStatus } from 'clients/reports/reportsClient.types';

export const getReportStatusLabels = (dictionary: Dictionary) => ({
  [ReportStatus.Draft]: dictionary.draft,
  [ReportStatus.InReview]: dictionary.in_review,
  [ReportStatus.Approved]: dictionary.approved,
});

export const isAnswerEmpty = (data: any = {}) => {
  if (data.answerType !== 'bool' && isEmpty(data.answerValue)) {
    return true;
  }

  if (data.answerType === 'long_text' && data.answerValue === '<p><br></p>') {
    return true;
  }
  return false;
};
