import React, { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid, IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Share } from '@mui/icons-material';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { getAllRelatedTaskEvents } from 'helpers/task';
import { tasksClient } from 'clients/tasks/tasksClient';
import { useDropdownResults } from 'shared/hooks/useDropdownResults';
import { useUsers } from 'shared/hooks/useUsers';
import { useTask } from 'shared/hooks/useTask';
import { ActivityLog } from 'shared/components/layout/ActivityLog/ActivityLog';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { TaskForm } from './TaskForm';


export const EditTask = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const queryClient = useQueryClient();
  const { users } = useUsers();
  const { copyUrlToShare, genericFeedback, genericError } = useContext(LayoutContext);
  const { id = null }: any = useParams();

  const { task, error, status } = useTask({ id });
  const { dropdownResults } = useDropdownResults();

  const editTask = useMutation(tasksClient.patchTask, {
    mutationKey: cacheKeys.tasks.editTask,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.tasks.getTasks);
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.tasks.getTask);
      queryClient.invalidateQueries(cacheKeys.events.getEvent);
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControl);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      navigate(routes.tasks);
      genericFeedback(dictionary.tasks.form.taskSaved);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (task) => {
    if (!task) {
      return false;
    }
    return editTask.mutateAsync(task);
  }, [editTask]);

  if (error) {
    genericError();
  }

  if (status !== 'success') {
    return <BodyLoading height="40vh"/>;
  }

  const defaultValues = {
    ...task,
    due_date: new Date(task.due_date),
    assigned_to: users.find(({ id }: any) => task?.assigned_to?.id === id),
    reported_by: users.find(({ id }: any) => task?.reported_by?.id === id),
    event: getAllRelatedTaskEvents(task, dropdownResults)[0],
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item>
          <Card sx={{ p: 3, position: 'relative' }}>
            <Box position="absolute" top={16} right={16}>
              <Tooltip title={dictionary.forms.copyUrlToShare}>
                <IconButton onClick={() => copyUrlToShare()}>
                  <Share/>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 4 }}>{dictionary.tasks.form.editTaskTitle}</Typography>
            <ActivityLog activityLogs={task?.activity_logs}/>
            <TaskForm
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.forms.save}
              defaultValues={defaultValues}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
