import React, { FC } from 'react';
import { ErrorOutline, WarningAmber } from '@mui/icons-material';

export const StatusIcon: FC<{ color: string }> = ({ color }) => {
  const icons = {
    'error': ErrorOutline,
    'warning.main': WarningAmber,
  };

  const iconColor = color === 'warning.main' ? '#ffa900' : '#FF2600';

  const Component = icons?.[color as keyof typeof icons];
  return <Component sx={{ '&.MuiSvgIcon-root': { color: iconColor, fill: iconColor } }} />;
};