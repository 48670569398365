import React, { FC, useCallback, useContext } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  AutocompleteValue,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
import { set } from 'date-fns';

import { LayoutContext, LocalizationContext, UserContext } from 'contexts';
import { sanitizeDescription } from 'helpers/html';
import { useDropdownResults } from 'shared/hooks/useDropdownResults';
import { SelectOptionChip } from 'shared/components/form/SelectOptionChip/SelectOptionChip';
import { SliderWrapper } from 'shared/components/form/SliderWrapper/SliderWrapper';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { RichTextField } from 'shared/components/form/RichTextField/RichTextField';
import { TaskStatusSelect } from 'shared/components/form/TaskStatusSelect/TaskStatusSelect';
import { EventAutocomplete } from 'shared/components/form/EventAutocomplete/EventAutocomplete';

interface Props {
  defaultValues?: any;
  onSubmitRequest: (values: any) => void;
  onSubmitButtonText: string;
};

const DEFAULT_VALUES: any = {
  title: '',
  description: '',
  priority: 0,
  status: '',
  assigned_to: null,
  reported_by: null,
  due_date: null,
  event: null,
};

export const TaskForm: FC<Props> = ({ defaultValues = DEFAULT_VALUES, onSubmitRequest, onSubmitButtonText }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = useContext(LayoutContext);
  const { user } = useContext(UserContext);
  const { dropdownResults } = useDropdownResults();

  const methods = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const { handleSubmit, watch, setValue, formState: { isSubmitting, errors } } = methods;

  const priority = watch('priority');

  const onSubmit = useCallback(async ({ assigned_to, reported_by, event, ...data }) => {
    try {
      const mappedData = {
        ...data,
        due_date: set(data?.due_date, { hours: 5 })?.toISOString()?.substr(0, 10),
        assigned_to: assigned_to?.id || null,
        reported_by: reported_by?.id || null,
        task_events: (event?.id ? [
          {
            'id': event.id,
            'type': event.type,
          },
        ] : []
        ),
      };

      onSubmitRequest(mappedData);
    } catch(e) {
      genericError();
      console.error(e);
    }
  }, [genericError, onSubmitRequest]);

  const handleSelectEvent = useCallback((value: AutocompleteValue<unknown, undefined, undefined, undefined>) => {
    const selectedEvent = dropdownResults.find((event: AutocompleteValue<unknown, undefined, undefined, undefined>) => event === value);
    if (selectedEvent) {
      setValue('reported_by', user);
      selectedEvent.due_date ?
        setValue('due_date', new Date(selectedEvent.due_date))
        : setValue('due_date', new Date());
      setValue('status', 'to_do');
    } else {
      setValue('reported_by', null);
      setValue('due_date', null);
      setValue('status', '');
    }
  }, [dropdownResults, setValue, user]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="title"
              rules={{ required: dictionary.tasks.form.fieldRequired }}
              render={({ field }) => (
                <TextField
                  label={dictionary.tasks.form.fieldTitle}
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              render={({ field }) => (
                <RichTextField
                  {...field}
                  label={dictionary.tasks.form.fieldDescription}
                  sanitize={sanitizeDescription}
                  lines={4}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="event"
              render={({ field }) => (
                <EventAutocomplete
                  options={dropdownResults}
                  label={dictionary.tasks.form.fieldEvent}
                  renderOption={(props: any, option: any) => <SelectOptionChip item={option} {...props} key={option.id + option.title} />}
                  selectEvent={(value: AutocompleteValue<unknown, undefined, undefined, undefined>) => handleSelectEvent(value)}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.tasks.form.fieldPriority} {priority}
              </Typography>
              <Controller
                name="priority"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    min={0}
                    max={5}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="due_date"
              rules={{ required: dictionary.tasks.form.fieldRequired }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                  label={dictionary.tasks.form.fieldDueDate}
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      error={!!errors.due_date}
                      helperText={errors.due_date?.message}
                    />
                  )}
                />
              )}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="status"
              rules={{ required: dictionary.tasks.form.fieldRequired }}
              render={({ field }) => (
                <TaskStatusSelect
                  allowAll={false}
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="reported_by"
              rules={{ required: dictionary.tasks.form.fieldRequired }}
              render={({ field }) => (
                <UserAutocomplete
                  label={dictionary.tasks.form.fieldReporter}
                  TextFieldProps={{
                    error: !!errors.reported_by,
                    helperText: errors.reported_by?.message,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="assigned_to"
              render={({ field }) => (
                <UserAutocomplete
                  label={dictionary.tasks.form.fieldAssignee}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="outlined"
                size="large"
                color="primary"
                loading={isSubmitting}
                endIcon={<Save/>}
              >
                {onSubmitButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
