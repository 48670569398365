import React, { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  LinearProgress,
  Paper,
  Chip,
} from '@mui/material';


import { routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';

import { getSeverityIndexColor } from 'helpers/colors';
import { risksClient } from 'clients/risks/risksClient';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useRisks } from 'shared/hooks/useRisks';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { DownloadButton } from 'shared/components/form/DownloadButton/DownloadButton';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { ListEmpty } from 'shared/components/layout/ListEmpty/ListEmpty';
import { SliderWrapper } from 'shared/components/form/SliderWrapper/SliderWrapper';

export const Risks = () => {
  useScrollTop();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = useContext(LayoutContext);
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumbs('risks');
  const { isMobile } = useContext(LayoutContext);
  const [search, currSearch, setSearch] = useDebouncedQueryState('search');
  const [riskIndex, currRiskIndex, setRiskIndex] = useDebouncedQueryState('residual_risk_index');

  const { risks = [], status } = useRisks({
    search: currSearch,
    residual_risk_index__gte: currRiskIndex?.[0] || 1,
    residual_risk_index__lte: currRiskIndex?.[1] || 25,
  });

  const exportRisks = useMutation(risksClient.getRisksFile, {
    onSuccess: (result) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const fileURL = URL.createObjectURL(result.data);
      a.href = fileURL;
      a.download = 'risks_overview_export' + new Date().toISOString().split('T')[0];
      a.click();
      window.URL.revokeObjectURL(fileURL);
    },
    onError: () => {
      genericError();
    },
  });

  const onDownload = useCallback(async (event: any) => {
    event.stopPropagation();
    await exportRisks.mutate();
  }, [exportRisks]);

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
      <Grid container spacing={isMobile ? 2 : 3}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">{dictionary.risks.list.title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Breadcrumbs list={breadcrumbs}/>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              wrap={!isMobile ? 'nowrap' : undefined}
              spacing={isMobile ? 2 : 3}
            >
              <Grid item xs={12} sm={6}>
                <TextField
                  label={dictionary.risks.list.fieldSearch}
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <Box px={4} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 90 }}>
                  <Typography variant="subtitle2" sx={{ ml: -1, mb: -2 }}>
                    {dictionary.risks.form.fieldResidualRiskResultingRiskIndex}: {riskIndex?.[0] || 1} - {riskIndex?.[1] || 25}
                  </Typography><br/>
                  <SliderWrapper
                    value={[parseInt(riskIndex?.[0] as string) || 1, parseInt(riskIndex?.[1] as string) || 25]}
                    onChange={(e: Event) => setRiskIndex((e.target as HTMLInputElement).value)}
                    min={1}
                    max={25}
                    size="small"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={1} display="flex" justifyContent="flex-end">
                <DownloadButton onClick={onDownload} tooltipTitle={dictionary.risks.list.export}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {(status === 'loading') && (
          <BodyLoading height="40vh"/>
        )}
        {(status === 'success' && risks.length === 0) && (
          <ListEmpty />
        )}
        {risks?.map(({
          id,
          risk,
          hazard,
          harm,
          probability,
          severity_score,
          risk_index,
          residual_probability,
          residual_severity_score,
          residual_risk_index,
          controls,
        }: any, i: number) => (
          <Grid item xs={12} sm={6} key={i}>
            <Box
              component={Paper}
              p={4}
              sx={{
                overflow: 'hidden',
                position: 'relative',
                height: '100%',
                cursor: 'pointer',
                '&:hover': {
                  background: 'none',
                  bgcolor: '#ececec',
                },
              }}
              onClick={() => navigate(routes.editRisk(id))}
            >
              <Typography variant="h4" fontWeight={300}>{risk}</Typography>
              <Grid container mt={3}>
                <Typography variant="body1" fontWeight="500">{dictionary.risks.list.hazardLabel}</Typography>
                <Typography variant="body1" ml="9px">{hazard}</Typography>
              </Grid>
              <Grid container mt={1}>
                <Typography variant="body1" fontWeight="500">{dictionary.risks.list.harmLabel}</Typography>
                <Typography variant="body1" ml="20px">{harm}</Typography>
              </Grid>

              <Grid container justifyContent="space-between" mt={3} mb={1}>
                <Typography variant="subtitle2">{dictionary.risks.list.riskEvaluationProbability}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{probability}/5</Typography>
              </Grid>
              <LinearProgress sx={{ '.MuiLinearProgress-bar': { background: getSeverityIndexColor(probability, 5) } }} variant="determinate" value={probability/5 * 100}/>
              <Box mt={2}/>

              <Grid container justifyContent="space-between" mb={1}>
                <Typography variant="subtitle2">{dictionary.risks.list.riskEvaluationSeverityScore}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{severity_score}/5</Typography>
              </Grid>
              <LinearProgress sx={{ '.MuiLinearProgress-bar': { background: getSeverityIndexColor(severity_score, 5) } }} variant="determinate" value={severity_score/5 * 100}/>
              <Box mt={2}/>

              <Grid container justifyContent="space-between" mb={1}>
                <Typography variant="subtitle2">{dictionary.risks.list.riskEvaluationResultingRiskIndex}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{risk_index}/25</Typography>
              </Grid>
              <LinearProgress sx={{ '.MuiLinearProgress-bar': { background: getSeverityIndexColor(risk_index, 25) } }} variant="determinate" value={risk_index/25 * 100}/>
              <Box mt={4}/>

              <Grid container justifyContent="space-between" mb={1}>
                <Typography variant="subtitle2">{dictionary.risks.list.residualRiskEvaluationProbability}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{residual_probability}/5</Typography>
              </Grid>
              <LinearProgress sx={{ '.MuiLinearProgress-bar': { background: getSeverityIndexColor(residual_probability, 5) } }} variant="determinate" value={residual_probability/5 * 100}/>
              <Box mt={2}/>

              <Grid container justifyContent="space-between" mb={1}>
                <Typography variant="subtitle2">{dictionary.risks.list.residualRiskSeverityScore}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{residual_severity_score}/5</Typography>
              </Grid>
              <LinearProgress sx={{ '.MuiLinearProgress-bar': { background: getSeverityIndexColor(residual_severity_score, 5) } }} variant="determinate" value={residual_severity_score/5 * 100}/>
              <Box mt={2}/>

              <Grid container justifyContent="space-between" mb={1}>
                <Typography variant="subtitle2">{dictionary.risks.list.residualRiskResultingRiskIndex}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{residual_risk_index}/25</Typography>
              </Grid>
              <LinearProgress sx={{ '.MuiLinearProgress-bar': { background: getSeverityIndexColor(residual_risk_index, 25) } }} variant="determinate" value={residual_risk_index/25 * 100}/>
              <Box mt={2}/>

              <Grid container justifyContent="space-between">
                <Typography variant="subtitle2">{dictionary.risks.list.controls}</Typography>
                <Typography variant="subtitle2" fontWeight={500}>{controls.length}</Typography>
              </Grid>
              <Box mt={1}/>

              <Grid container spacing={1} wrap="nowrap" sx={{ width: 'calc(100% - 0)', overflow: 'auto' }}>
                {controls.map(({ id: RiskControlId, control }: any, i: number) => (
                  <Grid item key={i}>
                    <Chip
                      color="secondary"
                      variant="outlined"
                      label={control.title}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        navigate(routes.editRiskControl(RiskControlId));
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
