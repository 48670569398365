import React from 'react';
import { MenuItem, TextField } from '@mui/material';


export const TaskStatusSelect = ({ onChange, allowAll, ...props }: any) => {

  return (
    <TextField
      label="Status"
      {...props}
      onChange={e => onChange({ ...e, target: { ...e.target, value: e.target.value === 'all' ? undefined : e.target.value } })}
      select
    >
      {allowAll && <MenuItem value="all">All</MenuItem>}
      {
        [
          ['to_do', 'To Do'],
          ['in_progress', 'In Progress'],
          ['in_review', 'In Review'],
          ['completed', 'Completed'],
          ...allowAll ? [
            ['require-action', 'Require Action'],
            ['immediate-action', 'Immediate Action'],
          ] : [],
        ].map(([value, label]) => (
          <MenuItem key={value} value={value}>{label}</MenuItem>
        ))
      }
    </TextField>
  );
};
