import { useCallback, useContext } from 'react';
import { trackSignUp } from 'tracking/tracking';
import { LocalizationContext, LayoutContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';


export const useSignup = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { login, ssoLogin, logout } = useContext(UserContext);
  const { validationError, genericError } = useContext(LayoutContext);

  const signupAndLogin = useCallback(async (formData) => {
    try {
      await usersClient.signupWithEmailAndPassword(formData);
      trackSignUp();
      await login(formData?.email, formData?.password);
    } catch (error: any) {
      genericError();
      return Promise.reject();
    }
  }, [login, genericError]);

  const signupAndLoginInvitation = useCallback(async (formData) => {
    try {
      await usersClient.signupWithEmailAndPasswordInvitation(formData);
      trackSignUp();
      await login(formData?.email, formData?.password);
    } catch (error: any) {
      genericError();
      return Promise.reject();
    }
  }, [login, genericError]);

  const signupWithSSO = useCallback(async (provider: string, invitationToken?: string) => {
    const result = await ssoLogin(provider);

    if (result.success !== true) {
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      try {
        await usersClient.signupWithSSO();
        trackSignUp();
      } catch (error: any) {
        if (error.response?.status === 400) {
          validationError(dictionary.userWithThisEmailAlreadyExists);
          logout();
        } else {
          genericError();
          logout();
        }
      }
    }
  }, [ssoLogin, genericError, logout, validationError, dictionary]);

  const signupWithSSOInvitation = useCallback(async (provider: string, invitationToken?: string) => {
    const result = await ssoLogin(provider);
    
    if (result.success !== true) {
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      try {
        await usersClient.signupWithSSOInvitation(invitationToken);
        trackSignUp();
      } catch (error: any) {
        if (error.response?.status === 400) {
          validationError(dictionary.userWithThisEmailAlreadyExists);
          logout();
        } else {
          genericError();
          logout();
        }
      }
    }
  }, [ssoLogin, genericError, logout, validationError, dictionary]);

  return {
    signupAndLogin,
    signupAndLoginInvitation,
    signupWithSSO,
    signupWithSSOInvitation,
  };
};
