import React, { FC, useCallback, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';

interface DownloadButtonProps {
    onClick: (event: any) => void;
    tooltipTitle: string;
}

export const DownloadButton: FC<DownloadButtonProps> = ({ onClick, tooltipTitle }) => {
  const [isItemBeingDownloaded, setIsItemBeingDownloaded] = useState(false);

  const onDownload = useCallback(async (event: any) => {
    setIsItemBeingDownloaded(true);
    await onClick(event);
    setIsItemBeingDownloaded(false);
  }, [onClick]);

  return (
    <IconButton
      onClick={onDownload}
      edge="end"
      sx={{
        margin: 'auto',
      }}
    >
      <Tooltip title={tooltipTitle}>
        {isItemBeingDownloaded ? <CircularProgress style={{ width: 22, height: 22 }}/> : <Download/>}
      </Tooltip>
    </IconButton>
  );
};