import React, { FC, Fragment, useContext } from 'react';

import { UserContext } from 'contexts';

export const Authenticated: FC = ({ children }) => {

  const { isLoggedIn } = useContext(UserContext);

  if (isLoggedIn) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }

  return null;
};
