import React, { FC, ReactElement, useContext } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';

import { DialogContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';

type Props = {
  children: ReactElement;
}

export const TeamHeader: FC<Props> = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { openDialog } = useContext(DialogContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="h6" component="h1">{dictionary.team.title}</Typography>
            </Grid>
            <Grid item xs={7} textAlign="right">
              <Button size="small" onClick={() => openDialog(Dialog.InviteTeamMember)}>
                {dictionary.team.buttonInvite}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
