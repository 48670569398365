import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Drawer,
  IconButton,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Toolbar,
  Typography,
  Menu,
  Avatar,
  Grid, useTheme, Box, useMediaQuery, Tooltip,
} from '@mui/material';
import {
  Menu as Hamburger,
  Lock,
  Logout,
  Person,
  Search,
  Notifications,
} from '@mui/icons-material';

import { routes } from 'config';
import { UserContext, LocalizationContext } from 'contexts';
import { useNavSections } from 'shared/hooks/useNavSections';
import { SpeedDial } from 'shared/components/layout/SpeedDial/SpeedDial';
import { Logo } from 'shared/components/layout/Logo/Logo';


export const UserMenu = () => {

  const navigate = useNavigate();
  const { user, logout, isLoggedIn, firebaseUser } = useContext(UserContext);
  const { displayName = undefined, profilePicture = undefined } = user || {};
  const { dictionary } = useContext(LocalizationContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <IconButton onClick={handleClick}>
        <Avatar alt={displayName} src={profilePicture || firebaseUser?.photoURL}/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          elevation: 5,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isLoggedIn ? [(
          <MenuItem key="my-account" onClick={() => [navigate(routes.myAccount), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><Person/></ListItemIcon>
            <ListItemText><Typography color="secondary">{dictionary.myAccount}</Typography></ListItemText>
          </MenuItem>
        ), (
          <MenuItem key="logout" onClick={() => [logout(), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><Logout/></ListItemIcon>
            <ListItemText><Typography color="secondary">{dictionary.logOut}</Typography></ListItemText>
          </MenuItem>
        )] : (
          <MenuItem onClick={() => [navigate(routes.login), handleClose()]} sx={{ p: 3 }}>
            <ListItemIcon><Lock/></ListItemIcon>
            <ListItemText><Typography color="secondary">{dictionary.login}</Typography></ListItemText>
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};


export const Nav = () => {

  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoggedIn } = useContext(UserContext);

  const { navSections } = useNavSections();

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  if(!isLoggedIn) {
    return null;
  }

  return(
    <Fragment>
      <Drawer open={menuOpen} onClose={closeMenu} className="no-print">
        <Grid container direction="column" sx={{ minWidth: 200, minHeight: '100%' }} wrap="nowrap">
          <Typography
            variant="h5"
            onClick={() => [navigate('/'), closeMenu()]}
            sx={theme => ({
              padding: theme.spacing(4),
              cursor: 'pointer',
            })}
          >
            <Logo onClick={() => navigate(routes.home)}/>
          </Typography>
          {navSections.map(({ title, link, Icon }, i: number) => (
            <MenuItem
              key={i}
              onClick={() => {
                navigate(link);
                setMenuOpen(false);
              }}
              sx={{ p: 3 }}
            >
              <ListItemIcon><Icon/></ListItemIcon>
              <ListItemText><Typography color="secondary">{title}</Typography></ListItemText>
            </MenuItem>
          ))}
        </Grid>
      </Drawer>
      <AppBar
        position="sticky"
        elevation={0}
        className="no-print"
      >
        <Toolbar>
          <Grid container alignItems="center" direction="row" spacing={1}>
            {isLoggedIn && (
              <Grid item>
                <IconButton onClick={() => setMenuOpen(prevState => !prevState)} edge="start">
                  <Hamburger sx={{ color: 'primary.main' }}/>
                </IconButton>
              </Grid>
            )}
            {!isMobile && (
              <Grid item>
                <Logo onClick={() => navigate(routes.home)}/>
              </Grid>
            )}
            <Box ml="auto"/>
            {!isMobile && [
              <Grid item key="search">
                <Tooltip title="Search - Coming Soon">
                  <IconButton>
                    <Search/>
                  </IconButton>
                </Tooltip>
              </Grid>,
              <Grid item key="notifications">
                <Tooltip title="Notifications - Coming Soon">
                  <IconButton>
                    <Notifications/>
                  </IconButton>
                </Tooltip>
              </Grid>,
            ]}
            <Grid item>
              <UserMenu/>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <SpeedDial/>
    </Fragment>
  );
};
