import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { addDays, format } from 'date-fns';

import { cacheKeys } from 'config';
import { UserContext } from 'contexts';
import { tasksClient } from 'clients/tasks/tasksClient';

export const useTasks = (params: any) => {

  const { search, status, assignedTo, gteDate, lteDate, limit = 1000 } = params || {};

  const { isLoggedIn } = useContext(UserContext);

  const statusFilter = useMemo(() => {
    if(status === 'immediate-action') {
      return {
        due_date__lte: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
        due_date__gte: undefined,
        status__exclude: 'completed',
      };
    }
    if(status === 'require-action') {
      return {
        due_date__gte: format(addDays(new Date(), 7), 'yyyy-MM-dd'),
        due_date__lte: undefined,
        status__exclude: 'completed',
      };
    }
    return {
      status,
    };
  }, [status]);

  const { data: { data: { results: tasks = [], count = undefined } = {} } = {} } = useQuery(
    [cacheKeys.tasks.getTasks, params],
    () => tasksClient.getTasks({
      due_date__gte: gteDate,
      due_date__lte: lteDate,
      ...statusFilter,
      search,
      limit,
      assigned_to: assignedTo,
    }),
    { enabled: isLoggedIn },
  );

  return {
    tasks,
    count,
  };

};
