import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { usePrevious } from 'react-use';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Link,
  Button,
  Chip,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { compact, intersectionWith, isEqual } from 'lodash';
import { formatISO } from 'date-fns';

import { routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { getSeverityIndexColor } from 'helpers/colors';
import { SliderWrapper } from 'shared/components/form/SliderWrapper/SliderWrapper';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { RiskControlAutocomplete } from 'shared/components/form/RiskControlAutocomplete/RiskControlAutocomplete';
import { ControlAutocomplete } from 'shared/components/form/ControlAutocomplete/ControlAutocomplete';
import { SelectOptionChip } from 'shared/components/form/SelectOptionChip/SelectOptionChip';
import { RiskControlIntervalSelect } from 'shared/components/form/RiskControlIntervalSelect/RiskControlIntervalSelect';
import { AddRiskControlTab } from './AddRiskControlTab';


interface Props {
  defaultValues?: any;
  onSubmitRequest: (values: any) => void;
  onSubmitButtonText: string;
};

const DEFAULT_VALUES: any = {
  hazard: '',
  risk: '',
  harm: '',
  probability: 0,
  severity_score: 0,
  risk_index: 0,
  residual_probability: 0,
  residual_severity_score: 0,
  residual_risk_index: 0,
  owner: null,
  existing_risk_controls: [],
  new_risk_controls: [],
  new_risk_control: {
    control: null,
    last_checked_at: null,
    owner: null,
    interval: '',
  },
};

export const RiskForm: FC<Props> = ({ defaultValues = DEFAULT_VALUES, onSubmitRequest, onSubmitButtonText }) => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = useContext(LayoutContext);
  const [ isAddingNewRiskControl, setIsAddingNewRiskControl ] = useState(false);

  const methods = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const { handleSubmit, watch, setValue, formState: { isSubmitting, isSubmitted } } = methods;

  const probability = watch('probability');
  const severity_score = watch('severity_score');
  const risk_index = watch('risk_index');
  const residual_probability = watch('residual_probability');
  const residual_severity_score = watch('residual_severity_score');
  const residual_risk_index = watch('residual_risk_index');
  const existing_risk_controls = watch('existing_risk_controls');
  const new_risk_controls = watch('new_risk_controls');
  const new_risk_control = watch('new_risk_control');

  const onSubmit = useCallback(async ({ owner, existing_risk_controls, new_risk_control, ...data }) => {
    try {
      const mappedData = {
        ...data,
        existing_risk_controls: compact(existing_risk_controls?.map((risk_control: any) => risk_control.id)),
        owner: owner?.id || null,
      };

      onSubmitRequest(mappedData);
    } catch(e) {
      genericError();
      console.error(e);
    }
  }, [genericError, onSubmitRequest]);

  const onAddRiskControl = useCallback(() => {
    if (
      !new_risk_control.control?.id
      || !new_risk_control.owner?.id
      || !new_risk_control.last_checked_at
      || !new_risk_control.interval
    ) {
      return;
    }
    const updatedRiskControls = [
      ...existing_risk_controls, {
        control: new_risk_control.control,
      },
    ];
    const updatedNewRiskControls = [
      ...new_risk_controls, {
        control: new_risk_control.control?.id,
        last_checked_at: new_risk_control.last_checked_at ? formatISO(new_risk_control.last_checked_at) : null,
        owner: new_risk_control.owner?.id,
        interval: new_risk_control.interval,
      },
    ];

    setValue('new_risk_controls', updatedNewRiskControls);
    setValue('new_risk_control', { ...defaultValues.new_risk_control });
    setValue('existing_risk_controls', updatedRiskControls);
    setIsAddingNewRiskControl(false);
  }, [setValue, existing_risk_controls, new_risk_control, new_risk_controls, defaultValues]);

  const existingRiskControlsRef = usePrevious(existing_risk_controls);

  const handleSliderChange = useCallback((e: Event, value: number | number[], resultValName: string, multiplier: number) => {
    const target = e.target as HTMLInputElement;
    setValue(target.name, value as number);
    setValue(resultValName, (value as number * multiplier));
  }, [setValue]);

  useEffect(() => {
    if (isEqual(existingRiskControlsRef, existing_risk_controls)) {
      return;
    }

    const updatedNewRiskControls = intersectionWith(new_risk_controls, existing_risk_controls, (newValue: any, existingValue: any) => {
      return existingValue.control.id === newValue.control;
    });

    setValue('new_risk_controls', updatedNewRiskControls);
  }, [existing_risk_controls, setValue, new_risk_controls, existingRiskControlsRef]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="hazard"
              render={({ field }) => (
                <TextField {...field} label={dictionary.risks.form.fieldHazard}/>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="risk"
              render={({ field }) => (
                <TextField {...field} label={dictionary.risks.form.fieldRisk}/>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="harm"
              render={({ field }) => (
                <TextField {...field} label={dictionary.risks.form.fieldHarm}/>
              )}
            />
          </Grid>


          <Grid item xs={12} sm={4}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.risks.form.fieldRiskEvaluationProbability}: {probability}
              </Typography>
              <Controller
                name="probability"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    onChange={(e: Event, value: number) => handleSliderChange(e, value, 'risk_index', severity_score)}
                    min={0}
                    max={5}
                    sx={{
                      '.MuiSlider-track': {
                        background: getSeverityIndexColor(probability, 5),
                      },
                      '.MuiSlider-thumb': {
                        background: getSeverityIndexColor(probability, 5),
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.risks.form.fieldRiskEvaluationSeverityScore}: {severity_score}
              </Typography>
              <Controller
                name="severity_score"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    onChange={(e: Event, value: number) => handleSliderChange(e, value, 'risk_index', probability)}
                    min={0}
                    max={5}
                    sx={{
                      '.MuiSlider-track': {
                        background: getSeverityIndexColor(severity_score, 5),
                      },
                      '.MuiSlider-thumb': {
                        background: getSeverityIndexColor(severity_score, 5),
                      },
                    }}
                    valueLabelDisplay="auto"
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.risks.form.fieldResidualRiskResultingRiskIndex}: {risk_index}
              </Typography>
              <Controller
                name="risk_index"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    disabled
                    min={0}
                    max={25}
                    sx={{
                      '.MuiSlider-track': {
                        background: getSeverityIndexColor(risk_index, 25),
                      },
                      '.MuiSlider-thumb': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          
          {!defaultValues.id && (
            <>
              <Grid item xs={12} sm={8}>
                <Controller
                  name="existing_risk_controls"
                  render={({ field }) => (
                    <RiskControlAutocomplete
                      label={dictionary.risks.form.fieldRiskControls}
                      multiple
                      {...field}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <AddRiskControlTab isOpen={isAddingNewRiskControl} onClick={() => setIsAddingNewRiskControl(!isAddingNewRiskControl)}/>
              </Grid>

              {isAddingNewRiskControl && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      border: 'solid 1px rgba(0, 0, 0, 0.23)',
                      padding: 2,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="new_risk_control.control"
                          rules={{ required: dictionary.riskControls.form.fieldIsRequired }}
                          render={({ field }) => (
                            <ControlAutocomplete
                              label={dictionary.riskControls.form.fieldControl}
                              renderOption={(props: any, option: any) => <SelectOptionChip item={option} {...props}/>}
                              {...field}
                            />
                          )}
                        />
                        {new_risk_control?.control?.source && (
                          <Typography color="secondary" mt={1}>
                            <Link
                              href={new_risk_control.control.source}
                              target="_blank"
                              rel="noreferrer noopener"
                              variant="body2"
                            >
                              {dictionary.riskControls.form.controlSourceLabel}
                            </Link>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="new_risk_control.last_checked_at"
                          rules={{ required: dictionary.riskControls.form.fieldIsRequired }}
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              inputFormat="dd.MM.yyyy"
                              label={dictionary.riskControls.form.fieldLastCheckedDate}
                              maxDate={new Date()}
                              renderInput={(props) => <TextField {...props} />}
                            />
                          )}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="new_risk_control.owner"
                          render={({ field }) => (
                            <UserAutocomplete
                              label={dictionary.riskControls.form.fieldOwner}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Controller
                          name="new_risk_control.interval"
                          rules={{ required: dictionary.riskControls.form.fieldIsRequired }}
                          render={({ field }) => (
                            <RiskControlIntervalSelect
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          type="button"
                          variant="outlined"
                          size="large"
                          color="primary"
                          disabled={isSubmitting || isSubmitted}
                          onClick={onAddRiskControl}
                        >
                          {dictionary.riskControls.form.addButtonLabel}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </>
          )}

          {!!defaultValues.id && (
            <>
              <Grid item xs={12} display="flex">
                <Typography variant="subtitle2">{dictionary.risks.list.controls}</Typography>
                <Typography variant="subtitle2" fontWeight={500} ml="10px">{existing_risk_controls.length}</Typography>
              </Grid>
              <Box mt={2}/>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {existing_risk_controls.map(({ id: RiskControlId, control }: any, i: number) => (
                    <Grid item key={i}>
                      <Chip
                        color="secondary"
                        variant="outlined"
                        label={<Typography variant="caption" style={{ whiteSpace: 'normal' }}>{control.title}</Typography>}
                        style={{ height:'100%', borderRadius: 30 }}
                        onClick={(event) => {
                          event.stopPropagation();
                          event.preventDefault();
                          navigate(routes.editRiskControl(RiskControlId));
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
      
          <Grid item xs={12} sm={4}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.risks.form.fieldResidualRiskEvaluationProbability}: {residual_probability}
              </Typography>
              <Controller
                name="residual_probability"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    onChange={(e: Event, value: number) => handleSliderChange(e, value, 'residual_risk_index', residual_severity_score)}
                    min={0}
                    max={5}
                    sx={{
                      '.MuiSlider-track': {
                        background: getSeverityIndexColor(residual_probability, 5),
                      },
                      '.MuiSlider-thumb': {
                        background: getSeverityIndexColor(residual_probability, 5),
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.risks.form.fieldResidualRiskSeverityScore}: {residual_severity_score}
              </Typography>
              <Controller
                name="residual_severity_score"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    onChange={(e: Event, value: number) => handleSliderChange(e, value, 'residual_risk_index', residual_probability)}
                    min={0}
                    max={5}
                    sx={{
                      '.MuiSlider-track': {
                        background: getSeverityIndexColor(residual_severity_score, 5),
                      },
                      '.MuiSlider-thumb': {
                        background: getSeverityIndexColor(residual_severity_score, 5),
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box px={2} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 53 }}>
              <Typography variant="subtitle2" sx={{ ml: -1, mb: -1 }}>
                {dictionary.risks.form.fieldRiskEvaluationResultingRiskIndex}: {residual_risk_index}
              </Typography>
              <Controller
                name="residual_risk_index"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    min={0}
                    max={25}
                    disabled
                    sx={{
                      '.MuiSlider-track': {
                        background: getSeverityIndexColor(residual_risk_index, 25),
                      },
                      '.MuiSlider-thumb': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="owner"
              render={({ field }) => (
                <UserAutocomplete
                  label={dictionary.risks.form.fieldOwner}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="outlined"
                size="large"
                color="primary"
                loading={isSubmitting}
                endIcon={<Save/>}
                disabled={isSubmitting || isSubmitted || isAddingNewRiskControl}
              >
                {onSubmitButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
