import { Dictionary } from 'contexts/LocalizationContext/types';
import { EventClassification, EventStatus } from 'clients/events/eventsClient.types';


export const getEventClassificationLabels = (dictionary: Dictionary) => ({
  [EventClassification.Complaint]: dictionary.incidents.complaintClassification,
  [EventClassification.Feedback]: dictionary.incidents.feedbackClassification,
  [EventClassification.Incident]: dictionary.incidents.incidentClassification,
  [EventClassification.NonConformity]: dictionary.incidents.nonconformityClassification,
  [EventClassification.Report]: dictionary.incidents.nonconformityClassification,
});

export const getEventStatusLabels = (dictionary: Dictionary) => ({
  [EventStatus.New]: dictionary.new,
  [EventStatus.CorrectionInProgress]: dictionary.correctionInProgress,
  [EventStatus.CorrectionPlanned]: dictionary.correctionPlanned,
  [EventStatus.Planned]: dictionary.planned,
  [EventStatus.InProgress]: dictionary.inProgress,
  [EventStatus.Closed]: dictionary.closed,
});

export const getEventStatusLabel = (dictionary: Dictionary, status: EventStatus) => getEventStatusLabels(dictionary)[status];
export const getEventClassficationLabel = (dictionary: Dictionary, label: EventClassification) => getEventClassificationLabels(dictionary)[label];

