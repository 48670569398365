import React, { useCallback, useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Box,
  Paper,
  Tooltip,
  IconButton,
  Chip,
  Avatar,
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { getEventClassificationLabels, getEventStatusLabels } from 'helpers/event';
import { eventsClient } from 'clients/events/eventsClient';
import { EventClassification, EventStatus } from 'clients/events/eventsClient.types';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useEvents } from 'shared/hooks/useEvents';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';


export const IncidentList = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError, genericFeedback, isMobile } = useContext(LayoutContext);
  const queryClient = useQueryClient();
  const { asyncConfirmation } = useContext(DialogContext);
  const [, currSearch] = useDebouncedQueryState('search');
  const [gteDate] = useQueryState('gteDate');
  const [lteDate] = useQueryState('lteDate');
  const [status] = useQueryState('status');
  const [classification] = useQueryState('classification');
  const [reportBeingDeleted, setReportBeingDeleted] = useState<number>();

  const { events, status: eventsStatus } = useEvents({
    search: currSearch,
    gteDate,
    lteDate,
    status,
    classification,
  });

  const onDelete = useCallback(async (reportId: number) => {
    setReportBeingDeleted(reportId);
    
    try {
      const userConfirmed = await asyncConfirmation({
        title: dictionary.incidents.list.deleteConfirmation,
      });

      if(userConfirmed) {
        await eventsClient.deleteEvent(reportId);
        await queryClient.invalidateQueries(cacheKeys.events.getEvents);
        await queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
        await queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
        genericFeedback(dictionary.incidents.form.incidentDeleted);
      }
    } catch(e) {
      genericError();
      console.error(e);
    }
    
    setReportBeingDeleted(undefined);
  }, [dictionary, queryClient, asyncConfirmation, genericError, genericFeedback]);

  return (
    <>
      {(eventsStatus === 'loading' || !events) && (
        <BodyLoading height="40vh"/>
      )}
      {events?.map(({ id, title, status, classification, created_by, entry_date, updated_at, updated_by, meta }: any, i: number) => (
        <Grid item xs={12} key={i}>
          <Box
            p={4}
            component={Paper}
            sx={{
              overflow: 'hidden',
              position: 'relative',
              height: '100%',
              cursor: 'pointer',
              '&:hover': {
                background: 'none',
                bgcolor: '#ececec',
              },
            }}
            onClick={() => navigate(routes.fulfillIncident(id))}
          >
            <Grid container spacing={2} alignItems="center" wrap={!isMobile ? 'nowrap' : undefined}>
              <Grid item>
                <Typography
                  variant="h6"
                  color="primary"
                  fontWeight={500}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {title} - {format(new Date(entry_date), 'dd MMM yy')}
                </Typography>
                <LinearProgress variant="determinate" value={meta.progress?.percentage}/>
                <Typography variant="body1" mt={1}>
                  {
                    dictionary.reports.list.metaInfo(
                      meta.progress?.answered,
                      meta.progress?.total,
                      format(updated_at, 'dd MMM yy'),
                      updated_by?.displayName || '',
                    )
                  }
                </Typography>
              </Grid>
              <Box ml="auto"/>
              <Grid item xs={6} sm="auto">
                <Chip label={getEventStatusLabels(dictionary)[status as EventStatus]} variant="outlined" color="primary"/>
              </Grid>
              <Grid item xs={6} sm="auto">
                <Chip label={getEventClassificationLabels(dictionary)[classification as EventClassification]} variant="outlined" color="primary"/>
              </Grid>
              {created_by && (
                <Grid item xs={6} sm="auto">
                  <Chip
                    avatar={<Avatar alt={created_by?.displayName}>{created_by?.first_name?.charAt(0)?.toUpperCase()}</Avatar>}
                    label={created_by?.displayName}
                  />
                </Grid>
              )}
              <Grid item xs={6} sm="auto">
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onDelete(id);
                  }}
                  edge="end"
                >
                  <Tooltip title={dictionary.delete}>
                    {reportBeingDeleted === id ? <CircularProgress style={{ width: 22, height: 22 }}/> : <Delete/>}
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      ))}
    </>
  );
};
