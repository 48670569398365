import * as chroma from 'chroma.ts';


export const severityIndexGradientColors = ['green', 'orange', 'red'];

export const getSeverityIndexColor = (value: number, totalValue: number) => {
  const formatter = chroma.scale(severityIndexGradientColors);

  return formatter(value / totalValue).css();
};
