import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';

import { mapMediaCreateCrudData } from './documentMediaClient.formatter';


const DocumentsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getAttachments = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${params.documentId}/media/`,
      method: 'GET',
      params: {
        limit: params.limit || DEFAULT_QUERY_LIMIT,
      },
    },
  });
};

const getAttachment = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${params.documentId}/media/${params.attachmentId}/`,
      method: 'GET',
    },
  });
};

const createAttachment = (data: any): Promise<AxiosResponse> => {
  const { documentId, ...payload } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${documentId}/media/`,
      method: 'POST',
      data: mapMediaCreateCrudData(payload),
    },
  });
};

const editAttachment = (data: any): Promise<AxiosResponse> => {
  const { documentId, attachmentId, ...payload } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${documentId}/media/${attachmentId}/`,
      method: 'PUT',
      data: payload,
    },
  });
};

const patchAttachment = (data: any): Promise<AxiosResponse> => {
  const { documentId, attachmentId, ...payload } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${documentId}/media/${attachmentId}/`,
      method: 'PATCH',
      data: payload,
    },
  });
};

const deleteAttachment = (data: any ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${data.documentId}/media/${data.attachmentId}/`,
      method: 'DELETE',
    },
  });
};

const getAttachmentUploadUrl = (documentId: number | string) => `${DocumentsApiBaseUrl}/page/${documentId}/media/`;

export const documentsMediaClient = {
  createAttachment,
  deleteAttachment,
  editAttachment,
  patchAttachment,
  getAttachments,
  getAttachment,
  getAttachmentUploadUrl,
};
