import React, { useCallback, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Button, Card, Container, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { sanitizeDescription } from 'helpers/html';
import { addQuery } from 'helpers/query';
import { EventStatus } from 'clients/events/eventsClient.types';
import { eventsClient } from 'clients/events/eventsClient';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useReportTemplate } from 'shared/hooks/useReportTemplate';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { ReportQuestionList } from 'views/LoggedIn/Report/ReportQuestionList';


export const PreviewIncidentForm = () => {
  const [reportDate] = useQueryState('reportDate');
  const [title] = useDebouncedQueryState('title');
  const { templateId = null }: any = useParams();
  const { dictionary } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { genericError, genericFeedback } = useContext(LayoutContext);

  const methods = useForm<any>({
    defaultValues: {
      status: EventStatus.New,
      report_template: Number(templateId),
      entry_date: new Date(reportDate || ''),
      title,
    },
  });
  const { handleSubmit, watch, formState: { isSubmitting } } = methods;
  const { template, error, status } = useReportTemplate({ id: templateId });

  const queryClient = useQueryClient();
  const createEvent = useMutation(eventsClient.createEvent, {
    mutationKey: cacheKeys.events.createEvent,
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      navigate(routes.fulfillIncident(data.data.id));
      genericFeedback(dictionary.incidents.form.incidentSaved);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (data: any) => {
    const mappedData = {
      report_template: data.report_template,
      entry_date: format(data.entry_date, 'yyyy-MM-dd'),
      title: data.title,
    };
    return createEvent.mutateAsync(mappedData);
  }, [createEvent]);

  if (status === 'loading') {
    return <BodyLoading height="40vh"/>;
  }

  if (error) {
    navigate(routes.incidents);
    return null;
  }

  const entryDate = watch('entry_date');

  return (
    <Container maxWidth="md">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" alignItems="center" sx={{ minHeight: 'calc(100vh - 100px)', mt: '100px' }}>
            <Grid item>
              <Card sx={{ p: 3, position: 'relative', backgroundColor: '#F7F7F7' }}>
                <Grid container>
                  <Grid item sm={10}>
                    <Typography variant="h4">{title}</Typography>
                    {template?.description && (<Typography variant="body1" sx={{ mt: 2, mb: 3 }} dangerouslySetInnerHTML={{ __html: sanitizeDescription(template.description) }}/>)}
                  </Grid>
                  <Grid item sm={2}>
                    <Controller
                      name="entry_date"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputFormat="dd.MM.yyyy"
                          mask="__.__.____"
                          label={dictionary.incidents.form.fieldIncidentDate}
                          renderInput={props => <TextField size="small" {...props}/>}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} paddingY="30px">
                    <Box sx={{ maxHeight: '350px', overflowY: 'auto' }}>
                      <ReportQuestionList report={template} isReadOnly />
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item alignItems="center" display="flex" justifyContent="space-between" width="100%" mt="30px">
              <Button
                variant="outlined"
                type="button"
                size="large"
                color="primary"
                onClick={() => navigate({
                  pathname: routes.createIncident,
                  search: addQuery(location.search, {
                    reportDate: entryDate ? format(entryDate, 'yyyy-MM-dd') : undefined,
                    templateId,
                    title,
                  }) })
                }
              >
                {dictionary.incidents.form.backButton}
              </Button>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !templateId || !entryDate || !title}
                loading={isSubmitting}
              >
                {dictionary.incidents.form.createButton}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Container>
  );
};
