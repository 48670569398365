import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { documentsClient } from 'clients/documents/documentsClient';

export type Params = {
  id: string;
};

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useDocument = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: document } = {}, status, error } = useQuery(
    [cacheKeys.documents.getDocument, params],
    () => documentsClient.getDocument(params.id),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    document,
  };
};
