import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { companyClient } from 'clients/companies/companyClient';
import { GetCompaniesParams } from 'clients/companies/companyClient.types';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useCompanies = (params: GetCompaniesParams = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data: companies = [] } = {}, status, error } = useQuery(
    [cacheKeys.companies.getCompanies, params],
    () => companyClient.getCompanies(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    companies,
  };
};
