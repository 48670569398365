import { AxiosResponse } from 'axios';
import { request } from 'clients/baseClient';

const ApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getQuestion = (params: any): Promise<AxiosResponse> => {
  const { reportId, questionId } = params;
  return request({
    options: {
      url: `${ApiBaseUrl}/reports/${reportId}/questions/${questionId}/`,
      method: 'GET',
    },
  });
};

const createAnswer = (params: any): Promise<AxiosResponse> => {
  const { reportId, questionId, data } = params;
  return request({
    options: {
      url: `${ApiBaseUrl}/reports/${reportId}/questions/${questionId}/answer/`,
      method: 'POST',
      data: data,
    },
  });
};

const getAnswer = (params: any): Promise<AxiosResponse> => {
  const { reportId, questionId, answerId } = params;
  return request({
    options: {
      url: `${ApiBaseUrl}/reports/${reportId}/questions/${questionId}/answer/${answerId}/`,
      method: 'POST',
    },
  });
};

const editAnswer = (params: any): Promise<AxiosResponse> => {
  const { reportId, questionId, answerId, data } = params;
  return request({
    options: {
      url: `${ApiBaseUrl}/reports/${reportId}/questions/${questionId}/answer/${answerId}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchAnswer = (params: any): Promise<AxiosResponse> => {
  const { reportId, questionId, answerId, data } = params;
  return request({
    options: {
      url: `${ApiBaseUrl}/reports/${reportId}/questions/${questionId}/answer/${answerId}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteAnswer = (params: any): Promise<AxiosResponse> => {
  const { reportId, questionId, answerId } = params;
  return request({
    options: {
      url: `${ApiBaseUrl}/reports/${reportId}/questions/${questionId}/answer/${answerId}/`,
      method: 'DELETE',
    },
  });
};

export const reportQuestionsClient = {
  getQuestion,
  createAnswer,
  getAnswer,
  editAnswer,
  patchAnswer,
  deleteAnswer,
};
