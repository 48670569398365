import React, { useContext } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { DocumentType } from 'clients/documents/documentsClient.types';

export const DocumentTypeSelect = ({ onChange, allowAll, ...props }: any) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <TextField
      label={dictionary.forms.typeLabel}
      {...props}
      onChange={e => onChange({ ...e, target: { ...e.target, value: e.target.value === 'all' ? undefined : e.target.value } })}
      select
    >
      {allowAll && <MenuItem value="all">{dictionary.all}</MenuItem>}
      <MenuItem key={DocumentType.Policy} value={DocumentType.Policy}>{dictionary.documents.type.policy}</MenuItem>
      <MenuItem key={DocumentType.Document} value={DocumentType.Document}>{dictionary.documents.type.document}</MenuItem>
      <MenuItem key={DocumentType.Report} value={DocumentType.Report}>{dictionary.documents.type.report}</MenuItem>
    </TextField>
  );
};
