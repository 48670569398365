import sanitize from 'sanitize-html';

export const sanitizeHtml = (html: string, options?: sanitize.IOptions) => sanitize(html, options);

export const sanitizeDescription = (description: string) => sanitizeHtml(description, {
  allowedTags: [
    'li', 'ol', 'p', 'ul', 'a', 'b', 'br', 'em', 'i',
    'span', 'strong', 'u',
  ],
  allowedSchemes: [ 'http', 'https', 'mailto' ],
  allowedAttributes: {
    a: ['href', 'name', 'target'],
    p: ['style'],
    span: ['style'],
    li: ['style'],
  },
});
