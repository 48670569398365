import React, { FC, useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import isEqual from 'lodash/isEqual';

export const QuestionForm: FC<any> =({
  onSubmit,
  name,
  defaultValue,
  submitOnBlur = false,
  children,
}) => {
  const formMethods = useForm<any>({
    defaultValues: {
      [name]: defaultValue,
    },
  });

  const value = formMethods.watch(name);
  
  const handleSubmit = useCallback(() => {
    if (!isEqual(defaultValue, value)) {
      onSubmit(value);
    }
  }, [defaultValue, value, onSubmit]);

  useEffect(() => {
    const subscription = formMethods.watch((formValues: any, { name, type }: any) => {
      if (!submitOnBlur) {
        onSubmit(formValues[name]);
      }
    });
    return () => subscription.unsubscribe();
  }, [formMethods, submitOnBlur, onSubmit]);


  return (
    <FormProvider {...formMethods}>
      <form onBlur={() => submitOnBlur && handleSubmit()}>
        {children}
      </form>
    </FormProvider>
  );
};
