import React, { useContext } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { getEventStatusLabels } from 'helpers/event';
import { EventStatus } from 'clients/events/eventsClient.types';


export const IncidentStatusSelect = ({ onChange, label, ...props }: any) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <TextField
      label={label || dictionary.incidents.form.fieldStatus}
      {...props}
      onChange={onChange}
      select
    >
      <MenuItem key={EventStatus.New} value={EventStatus.New}>
        {getEventStatusLabels(dictionary)[EventStatus.New]}
      </MenuItem>
      <MenuItem key={EventStatus.CorrectionPlanned} value={EventStatus.CorrectionPlanned}>
        {getEventStatusLabels(dictionary)[EventStatus.CorrectionPlanned]}
      </MenuItem>
      <MenuItem key={EventStatus.CorrectionInProgress} value={EventStatus.CorrectionInProgress}>
        {getEventStatusLabels(dictionary)[EventStatus.CorrectionInProgress]}
      </MenuItem>
      <MenuItem key={EventStatus.Closed} value={EventStatus.Closed}>
        {getEventStatusLabels(dictionary)[EventStatus.Closed]}
      </MenuItem>
    </TextField>
  );
};
