import React, { FC, useCallback, useContext } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  Box,
  Grid,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';
import { formatISO } from 'date-fns';

import { LayoutContext, LocalizationContext } from 'contexts';
import { getRiskControlIntervalByIndex, getRiskControlIntervalLabel } from 'helpers/riskControl';
import { RiskControlInterval } from 'clients/riskControls/riskControlsClient.types';
import { SliderWrapper } from 'shared/components/form/SliderWrapper/SliderWrapper';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { ControlAutocomplete } from 'shared/components/form/ControlAutocomplete/ControlAutocomplete';
import { RiskAutocomplete } from 'shared/components/form/RiskAutocomplete/RiskAutocomplete';
import { SelectOptionChip } from 'shared/components/form/SelectOptionChip/SelectOptionChip';

interface Props {
  defaultValues?: any;
  onSubmitRequest: (values: any) => void;
  onSubmitButtonText: string;
};

const DEFAULT_VALUES: any = {
  interval: 0,
  last_checked_at: null,
  risks: [],
  control: null,
  owner: null,
};

export const RiskControlForm: FC<Props> = ({ defaultValues = DEFAULT_VALUES, onSubmitRequest, onSubmitButtonText }) => {

  const { genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit, watch,formState: { isSubmitting, errors } } = methods;

  const onSubmit = useCallback(async ({ owner, control, risks, interval, ...data }) => {  
    try {
      const mappedData = {
        ...data,
        last_checked_at: data.last_checked_at ? formatISO(data.last_checked_at) : null,
        owner: owner?.id || null,
        control: control?.id || null,
        risks: risks?.map((risk: any) => risk.id),
        interval: getRiskControlIntervalByIndex(interval),
      };

      onSubmitRequest(mappedData);
    } catch(e) {
      genericError();
      console.error(e);
    }
  }, [genericError, onSubmitRequest]);

  const control = watch('control');

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="risks"
              rules={{
                validate: (value) => !!value?.length || dictionary.riskControls.form.fieldIsRequired,
              }}
              render={({ field }) => (
                <RiskAutocomplete
                  label={dictionary.riskControls.form.fieldRisks}
                  multiple
                  TextFieldProps={{
                    error: !!errors.risks,
                    helperText: !!errors.risks && dictionary.riskControls.form.fieldIsRequired,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="control"
              rules={{ required: dictionary.riskControls.form.fieldIsRequired }}
              render={({ field }) => (
                <ControlAutocomplete
                  label={dictionary.riskControls.form.fieldControl}
                  TextFieldProps={{
                    error: !!errors.control,
                    helperText: errors.control?.message,
                  }}
                  renderOption={(props: any, option: any) => <SelectOptionChip item={option} {...props}/>}
                  {...field}
                />
              )}
            />
          </Grid>
          {control?.source && (
            <Grid item xs={12} mb={1}>
              <Typography color="secondary">
                <Link
                  href={control.source}
                  target="_blank"
                  rel="noreferrer noopener"
                  variant="body2"
                >
                  {dictionary.riskControls.form.controlSourceLabel}
                </Link>
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Controller
              name="last_checked_at"
              rules={{ required: dictionary.riskControls.form.fieldIsRequired }}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  inputFormat="dd.MM.yyyy"
                  label={dictionary.riskControls.form.fieldLastCheckedDate}
                  maxDate={new Date()}
                  renderInput={(props) => <TextField {...props} error={!!errors.last_checked_at} helperText={errors.last_checked_at?.message} />}
                />
              )}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="owner"
              render={({ field }) => (
                <UserAutocomplete
                  label={dictionary.riskControls.form.fieldOwner}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Box px={4} py={1} sx={{ border: '1px solid rgba(0,0,0,0.23)', maxHeight: 90 }}>
              <Typography variant="subtitle1" sx={{ ml: -2, mb: -3 }}>{dictionary.riskControls.form.fieldFrequency}</Typography><br/>
              <Controller
                name="interval"
                render={({ field }) => (
                  <SliderWrapper
                    {...field}
                    size="small"
                    min={0}
                    max={5}
                    marks={Object.values(RiskControlInterval).map((interval, index) => ({
                      value: index,
                      label: getRiskControlIntervalLabel(dictionary, interval),
                    }))}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <LoadingButton
              size="large"
              type="submit"
              variant="outlined"
              color="primary"
              loading={isSubmitting}
              loadingPosition="end"
              endIcon={<Save/>}
            >
              {onSubmitButtonText}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
