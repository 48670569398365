import React, { Fragment, useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Box, Paper, Typography } from '@mui/material';

import { routes, cacheKeys } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { companyClient } from 'clients/companies/companyClient';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';

import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { Footer } from 'shared/components/layout/Footer/Footer';
import { CompanyForm } from './partials/CompanyForm';

export const CreateCompany = () => {

  const navigate = useNavigate();
  const { genericError } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const breadcrumbs = useBreadcrumbs('createCompany');

  const queryClient = useQueryClient();
  const createCompany = useMutation(companyClient.createCompany, {
    mutationKey: cacheKeys.companies.createCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.companies.getCompanies);
      navigate(routes.companies);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(data => {
    return createCompany.mutate(data);
  }, [createCompany]);

  return (
    <Fragment>
      <Container maxWidth="xl">
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={4} mb={2}>
                <Breadcrumbs list={breadcrumbs} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={8}>
                  <Typography component="h1" variant="h2">
                    {dictionary.company.create.title}
                  </Typography>
                  <Box mt={2} p={4} component={Paper}>
                    <CompanyForm
                      onSubmitRequest={onSubmit}
                      onSubmitButtonText={dictionary.company.create.buttonCreate}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer/>
    </Fragment>
  );
};
