import React, { useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Share } from '@mui/icons-material';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { risksClient } from 'clients/risks/risksClient';

import { RiskForm } from './RiskForm';


export const CreateRisk = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const queryClient = useQueryClient();
  const { genericError, genericFeedback, copyUrlToShare } = useContext(LayoutContext);

  const createRisk = useMutation(risksClient.createRisk, {
    mutationKey: cacheKeys.risks.createRisk,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.risks.getRisks);
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      navigate(routes.risks);
      genericFeedback(dictionary.risks.form.riskCreated);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (risk) => {
    return createRisk.mutateAsync(risk);
  }, [createRisk]);

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item>
          <Card sx={{ p: 3, position: 'relative' }}>
            <Box position="absolute" top={16} right={16}>
              <Tooltip title={dictionary.forms.copyUrlToShare}>
                <IconButton onClick={() => copyUrlToShare(dictionary.risks.form.riskUrlCopied)}>
                  <Share/>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 4 }}>
              {dictionary.risks.form.createRiskTitle}
            </Typography>
            <RiskForm
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.forms.save}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
