import faker from 'faker';

export const demoTasks = Array(30).fill(1).map((_, i) => ({
  id: i + 213,
  title: faker.random.words(4),
  asignee: `${faker.name.firstName()} ${faker.name.lastName()}`,
  dueDate: faker.date.future(),
  status: faker.helpers.randomize(['completed', 'require-action', 'immediate-action']),
  priority: faker.helpers.randomize([1, 2, 3, 4, 5]),
}));

export const demoDocuments = Array(17).fill(1).map((_, i) => ({
  id: i + 1432,
  title: faker.random.words(2),
  subtitle: faker.random.words(4),
  type: faker.helpers.randomize(['Policy', 'Report Template', 'Org. Objective']),
}));
export const demoPages = Array(17).fill(1).map((_, i) => ({
  id: i + 1432,
  title: faker.random.words(2),
  subtitle: faker.random.words(4),
  category: faker.helpers.randomize(['policy', 'report', 'document']),
  html_content: faker.lorem.paragraphs(3),
  is_template: faker.datatype.boolean(),
  is_locked: faker.datatype.boolean(),
  updated_at: faker.date.past(),
  created_by: {
    displayName: faker.name.firstName() + ' ' + faker.name.lastName(),
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber(),
    id: faker.datatype.number(),
    firebase_uid: faker.internet.userName(),
    company: {
      id: faker.datatype.number(),
      name: faker.company.companyName(),
    },
    job_title: faker.commerce.department(),
    profile_picture: faker.internet.avatar(),
  },
  updated_by: {
    displayName: faker.name.firstName() + ' ' + faker.name.lastName(),
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
    email: faker.internet.email(),
    phone: faker.phone.phoneNumber(),
    id: faker.datatype.number(),
    firebase_uid: faker.internet.userName(),
    company: {
      id: faker.datatype.number(),
      name: faker.company.companyName(),
    },
    job_title: faker.commerce.department(),
    profile_picture: faker.internet.avatar(),
  },
}));

export const demoIncidents = Array(17).fill(1).map((_, i) => ({
  id: i + 1432,
  title: faker.random.words(4),
  subtitle: faker.random.words(7),
  date: faker.date.past(),
  severity: faker.helpers.randomize(['low', 'medium', 'high']),
}));

export const demoReports = Array(17).fill(1).map((_, i) => ({
  id: i + 1432,
  title: faker.random.words(4),
  subtitle: faker.random.words(7),
  date: faker.date.past(),
  severity: faker.helpers.randomize(['low', 'medium', 'high']),
}));

export const demoRisks = Array(12).fill(1).map((_, riskIndex) => ({
  id: riskIndex + 3213,
  title: faker.random.words(2),
  subtitle: faker.random.words(4),
  probability: faker.helpers.randomize([1,2,3,4,5,6,7,8,9,10]),
  severity: faker.helpers.randomize([1,2,3,4,5,6,7,8,9,10]),
  controls: Array(Math.round(Math.random() * 10)).fill(1).map((_, controlIndex) => ({
    id: controlIndex + 3921,
    title: faker.random.words(2),
    subtitle: faker.random.words(4),
    risk: riskIndex + 3213,
  })),
}));

export const demoRiskControls = Array(12).fill(1).map((_, riskIndex) => ({
  id: riskIndex + 3213,
  risks: faker.helpers.randomize([Array(5).fill(1).map((_) => faker.random.words(2)), undefined]),
  title: faker.random.words(2),
  assigned_to: {
    first_name: faker.name.firstName(),
    last_name: faker.name.lastName(),
  },
  interval: faker.helpers.randomize(['weekly', 'monthly', 'quarterly', 'semesterly', 'yearly']),
  status: faker.helpers.randomize(['effective', 'non_effective', 'requires_action']),
  design: 'https://www.google.com',
  source: 'https://www.google.com',
  last_checked_at: faker.date.past(),
  next_checked_at: faker.date.future(),
}));

export const demoUsers = Array(10).fill(1).map((_, i) => ({
  id: Math.round(Math.random() * 1000),
  asignee: `${faker.name.firstName()} ${faker.name.lastName()}`,
}));

export const demoFAQ = Array(26).fill(1).map((_, i) => ({
  id: Math.round(Math.random() * 1000),
  q: faker.random.words(10) + '?',
  a: faker.random.words(43),
}));


export const yesNoOptions = [
  { value: 'Yes' },
  { value: 'No' },
];


export const ratingOptions = Array(5).fill(1).map((_, i) => ({ value: i + 1 }));
export const textOptions = () => Array(3).fill(1).map((_, i) => ({
  value: faker.random.words(3),
}));

export const demoReportTemplates = Array(13).fill(1).map((_, id) => ({
  id: id + 1,
  title: faker.random.words(5),
  description: faker.random.words(30),
  type: faker.helpers.randomize(['Incident', 'Report']),
  questions_total: faker.datatype.number({ min: 1, max: 20 }),
  questions: Array(Math.round(Math.random() * 20)).fill(1).map((_, i) => ({
    id: Math.round(Math.random() * 1000),
    title: faker.random.words(4),
    description: faker.helpers.randomize([faker.random.words(10), faker.random.words(20), '']),
    answer_options: faker.helpers.randomize([yesNoOptions, ratingOptions, textOptions()]),
    answer_type: faker.helpers.randomize(['text', 'single_choice', 'multiple_choice', 'date', 'time', 'datetime', 'bool']),
  })),
}));

export const demoFulfilledReports = Array(3).fill(1).map((_, id) => ({
  id: id + 1,
  title: faker.random.words(5),
  description: faker.random.words(30),
  type: faker.helpers.randomize(['Report']),
  status: faker.helpers.randomize(['Draft', 'Approved']),
  reporter: `${faker.name.firstName()} ${faker.name.lastName()}`,
  start_date: faker.date.past(),
  last_updated: faker.date.past(),
  questions: Array(Math.round(Math.random() * 20)).fill(1).map(() => ({
    id: Math.round(Math.random() * 1000),
    title: faker.random.words(4),
    description: faker.helpers.randomize([faker.random.words(10), faker.random.words(20), '']),
    answer_options: faker.helpers.randomize([yesNoOptions, ratingOptions, textOptions()]),
    answer_type: faker.helpers.randomize(['text', 'single_choice', 'multiple_choice', 'date', 'time', 'datetime', 'bool']),
  })).map((question) => ({
    ...question,
    answer: faker.helpers.randomize([true, false]) ? {
      //@ts-ignore
      value: faker.helpers.randomize(question.answer_options).value,
      ...question.answer_type === 'multiple_choice' && {
        //@ts-ignore
        value: [faker.helpers.randomize(question.answer_options).value],
      },
      ...question.answer_type === 'text' && {
        value: faker.random.words(5),
      },
    } : undefined,
  })),
})).map((report) => {
  const answeredQuestions = report.questions.filter(({ answer }) => !!answer);
  return ({
    ...report,
    meta: {
      progress: {
        total: report.questions.length,
        answered: answeredQuestions.length,
        percentage: (answeredQuestions.length / report.questions.length) * 100,
      },
    },
  });
});
