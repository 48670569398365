import React, { FC } from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { format } from 'date-fns';


export const CommentList: FC<any> = ({ comments }) => {
  return (
    comments?.map((comment: any, index: number) => (
      <Box
        key={index}
        sx={{
          marginBottom: '15px',
          borderBottom: '1px solid #ececec',
          paddingBottom: '20px',
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: '10px',
              width: 20,
              height: 20,
              fontSize: 12,
              fontWeight: 500,
              '.MuiAvatar-fallback': { color: '#ececec', fill: '#ececec' },
            }}
            alt={comment.created_by?.displayName}
            src={comment.created_by?.profile_picture}
          />
          <Box>

            <Typography variant="body2" fontWeight={500}>{comment.created_by?.displayName.trim() || ''}</Typography>
            <Typography variant="body2" sx={{ opacity: 0.7 }}>{format(comment.created_at, 'MMMM dd, yyyy')}</Typography>
          </Box>
        </Box>
        <Typography variant="body1" color="#333333" mt="4px" sx={{ wordBreak: 'break-word' }}>{comment.text}</Typography>
      </Box>
    ))
  );
};
