import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { riskControlsClient } from 'clients/riskControls/riskControlsClient';

export type Params = {
  id: string;
};

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useRiskControl = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: riskControl } = {}, status, error } = useQuery(
    [cacheKeys.riskControls.getRiskControl, params],
    () => riskControlsClient.getRiskControl(params.id),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    riskControl,
  };
};
