import React, { useContext } from 'react';
import {
  Container,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary, AccordionDetails,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import { LayoutContext } from 'contexts';
import { demoFAQ } from 'data/demoData';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';


export const FAQ = () => {

  const breadcrumbs = useBreadcrumbs('faq');
  const { isMobile } = useContext(LayoutContext);

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
      <Grid container spacing={1}>
        <Grid xs={12}>
          <Typography variant="h3" align="center">FAQ</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: 'center' }}>
            <Breadcrumbs list={breadcrumbs}/>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}/>
        {demoFAQ.map(({ q, a }, i) => (
          <Grid item xs={12} key={i}>
            <Accordion square>
              <AccordionSummary expandIcon={<ExpandMore/>}>
                <Typography variant="body1" color="secondary" fontWeight={500}>{q}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1">{a}</Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
