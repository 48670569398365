import { mapSimpleUserData } from 'clients/users/userClient.formatter';
import {
  Company,
  CompanyApi,
  CompanyCrud,
  CompanyCrudApi,
} from './companyClient.types';

export const mapCompanyData = (data: CompanyApi): Company => ({
  id: data.id,
  accountManager: data.account_manager && mapSimpleUserData(data.account_manager),
  primaryContact: data.primary_contact && mapSimpleUserData(data.primary_contact),
  preferredLanguage: data.preferred_language,
  country: data.country,
  name: data.name,
  slug: data.slug,
  createdAt: data.created_at,
  updatedAt: data.updated_at,
});

export const mapCompanyCrudData = (data: CompanyCrud): CompanyCrudApi => ({
  name: data.name,
  account_manager_id: data.accountManager?.id || null,
  primary_contact_id: data.primaryContact?.id || null,
  country: data.country || null,
  preferred_language: data.preferredLanguage || null,
});
