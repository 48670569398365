import { RiskControlStatus } from 'clients/riskControls/riskControlsClient.types';
import { RiskIndex } from 'clients/risks/riskClient.types';

export const zip = (a: any, b: any) => Array.from(Array(Math.max(b.length, a.length)), (_, i) => [a[i], b[i]]);

export enum Severity {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
};

export const defineChipColor = (elementLabel : string) => {
  if (elementLabel === RiskControlStatus.RequireAction) {
    return Severity.Warning;
  }

  if (elementLabel === RiskControlStatus.NonEffective || elementLabel === RiskIndex.Index20_25) {
    return Severity.Error;
  }

  if (elementLabel === RiskControlStatus.Effective || elementLabel === RiskIndex.Index1_6) {
    return Severity.Success;
  }

  return 'primary';
};

export const defineTypographyColor = (elementLabel : string) => {
  if (elementLabel === RiskIndex.Index7_12) {
    return 'warning.main';
  }

  if (elementLabel === RiskIndex.Index20_25) {
    return 'error.main';
  }

  if (elementLabel === RiskIndex.Index1_6) {
    return 'success.main';
  }

  return 'primary';
};
