import React, { useContext } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { RiskControlInterval } from 'clients/riskControls/riskControlsClient.types';

export const RiskControlIntervalSelect = ({ onChange, allowAll, label, ...props }: any) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <TextField
      label={label || dictionary.riskControls.form.fieldFrequency}
      {...props}
      onChange={e => onChange({ ...e, target: { ...e.target, value: e.target.value === 'all' ? undefined : e.target.value } })}
      select
    >
      {allowAll && <MenuItem value="all">{dictionary.all}</MenuItem>}
      <MenuItem key={RiskControlInterval.Day} value={RiskControlInterval.Day}>{dictionary.frequency.daily}</MenuItem>
      <MenuItem key={RiskControlInterval.Week} value={RiskControlInterval.Week}>{dictionary.frequency.weekly}</MenuItem>
      <MenuItem key={RiskControlInterval.Month} value={RiskControlInterval.Month}>{dictionary.frequency.monthly}</MenuItem>
      <MenuItem key={RiskControlInterval.Quarter} value={RiskControlInterval.Quarter}>{dictionary.frequency.quarterly}</MenuItem>
      <MenuItem key={RiskControlInterval.Semester} value={RiskControlInterval.Semester}>{dictionary.frequency.semesterly}</MenuItem>
      <MenuItem key={RiskControlInterval.Year} value={RiskControlInterval.Year}>{dictionary.frequency.yearly}</MenuItem>
    </TextField>
  );
};
