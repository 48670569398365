import React, { FC, useContext } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Edit } from '@mui/icons-material';

import { DialogContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { useMe } from 'shared/hooks/useMe';


export const CompanySection: FC = () => {

  const { user } = useMe();
  const { dictionary } = useContext(LocalizationContext);
  const { openDialog } = useContext(DialogContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box p={2}>
          <Grid item xs={7} textAlign="left" spacing={2}>
            <Grid container alignItems="center">
              <Grid item>
                <Tooltip title={dictionary.company.title}>
                  <Typography variant="h6" mr={1}>{user?.company?.name || dictionary.none}</Typography>
                </Tooltip>
              </Grid>
              <Grid>
                {user?.company?.name && (
                  <Tooltip title={dictionary.company.edit.editTheCompanyName}>
                    <IconButton
                      aria-label="Edit company name"
                      onClick={() => openDialog(Dialog.EditCompanyName)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
