import React, { useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Share } from '@mui/icons-material';
import find from 'lodash/find';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { getRiskControlIntervalIndex } from 'helpers/riskControl';
import { riskControlsClient } from 'clients/riskControls/riskControlsClient';
import { useRiskControl } from 'shared/hooks/useRiskControl';
import { useUsers } from 'shared/hooks/useUsers';
import { useRisks } from 'shared/hooks/useRisks';
import { useControls } from 'shared/hooks/useControls';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { RiskControlForm } from './RiskControlForm';


export const EditRiskControl= () => {

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { genericError, genericFeedback, copyUrlToShare } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { riskControlId = null }: any = useParams();
  
  const { users, error: usersError, status: usersStatus } = useUsers();
  const { controls, error: controlError, status: controlStatus } = useControls();
  const { risks: allRisks, error: risksError, status: risksStatus } = useRisks();
  const { riskControl, error, status } = useRiskControl({ id: riskControlId });

  
  const editRiskControl = useMutation(riskControlsClient.patchRiskControl, {
    mutationKey: cacheKeys.riskControls.editRiskControl,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      queryClient.invalidateQueries([cacheKeys.riskControls.getRiskControl, {
        id: riskControlId,
      }]);
      navigate(routes.riskControls);
      genericFeedback(dictionary.riskControls.form.riskControlSaved);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (riskControl) => {
    if (!riskControl) {
      return false;
    }
    return editRiskControl.mutate(riskControl);
  }, [editRiskControl]);

  const allDataIsLoaded = [
    status,
    usersStatus,
    risksStatus,
    controlStatus,
  ].every((requestStatus) => requestStatus === 'success');

  if (error || usersError || risksError || controlError) {
    genericError();
  }

  if (!allDataIsLoaded) {
    return <BodyLoading height="40vh"/>;
  }

  const defaultValues = {
    ...riskControl,
    last_checked_at: new Date(riskControl.last_checked_at),
    owner: users.find(({ id }: any) => riskControl.owner?.id === id),
    control: controls.find(({ id }: any) => riskControl.control.id === id),
    interval: getRiskControlIntervalIndex(riskControl.interval),
    risks: allRisks.filter((risk: any) => find(riskControl.risks, { risk: risk.risk })),
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item>
          <Card sx={{ p: 3, position: 'relative' }}>
            <Box position="absolute" top={16} right={16}>
              <Tooltip title={dictionary.riskControls.form.copyUrlToShare}>
                <IconButton onClick={() => copyUrlToShare(dictionary.riskControls.form.riskControlUrlCopied)}>
                  <Share/>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 4 }}>
              {dictionary.riskControls.form.editFormTitle}
            </Typography>
            <RiskControlForm
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.riskControls.form.saveButtonLabel}
              defaultValues={defaultValues}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
