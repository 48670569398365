
import { Grid } from '@mui/material';

import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useDocuments } from 'shared/hooks/useDocuments';
import { useQueryState } from 'shared/hooks/useQueryState';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { DocumentRow } from './DocumentRow';

export const DocumentsList = () => {
  const [category] = useQueryState('category');
  const [currSearch] = useDebouncedQueryState('search');

  const { documents = [], status } = useDocuments({
    search: currSearch,
    category,
  });

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {(status === 'loading' || !documents) && <BodyLoading height="40vh" />}
        {documents?.map(
          (document: any) => (
            <DocumentRow key={document.id} document={document} isParentRow level={1} isInSearch={Boolean(currSearch)}/>
          ))}
      </Grid>
    </>
  );
};
