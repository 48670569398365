import { useContext } from 'react';
import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { LocalizationContext } from 'contexts';
import { getFormattedDate } from 'helpers/date';
import { reportsClient } from 'clients/reports/reportsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useReports = (params: any = {}, options: Partial<Options> = defaultOptions) => {
  const { dictionary } = useContext(LocalizationContext);

  const { data: { data } = {}, status: queryStatus, error } = useQuery(
    [cacheKeys.reports.getReports, params],
    () => reportsClient.getReports(params),
    {
      enabled: options.enabled,
    },
  );

  const dashboardReports = (data?.results || [])
    .map((report: any) => ({
      id: report.id,
      title: report.title,
      updatedAt: getFormattedDate(new Date(report.updated_at), dictionary),
      progress: report.meta.progress,
    }));

  return {
    status: queryStatus,
    error,
    count: data?.count || 0,
    reports: data?.results || [],
    dashboardReports,
  };
};
