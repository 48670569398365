import { AxiosResponse } from 'axios';
import { request } from 'clients/baseClient';

import { mapTaskData } from './tasksClient.formatter';

const TasksApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getTasks = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/`,
      method: 'GET',
      params,
    },
  });
};

const getDropdownResults = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/dropdown-results/`,
      method: 'GET',
      params,
    },
  });
};

const getTask = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/${id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: mapTaskData(data.data),
  }));
};

const createTask = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/`,
      method: 'POST',
      data: data,
    },
  });
};

const editTask = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/${data.id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchTask = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteTask = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${TasksApiBaseUrl}/tasks/${id}/`,
      method: 'DELETE',
    },
  });
};

export const tasksClient = {
  createTask,
  deleteTask,
  editTask,
  patchTask,
  getTasks,
  getTask,
  getDropdownResults,
};
