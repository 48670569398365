import React, { FC, useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { Container, Grid, Box, Paper } from '@mui/material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { UserCrud } from 'clients/users/userClient.types';
import { useMe } from 'shared/hooks/useMe';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';

import { UserForm } from './partials/UserForm';

export const Profile: FC = () => {

  const { genericError, changesSaved } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { user, status } = useMe();

  const breadcrumbs = useBreadcrumbs('myAccount');

  const queryClient = useQueryClient();
  const editUser = useMutation(usersClient.editUser, {
    mutationKey: cacheKeys.users.editUser,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      queryClient.invalidateQueries(cacheKeys.users.getMe);
      if (user) {
        queryClient.invalidateQueries([cacheKeys.users.getUser, {
          id: user.id,
        }]);
      }
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback((data: UserCrud) => {
    if (!user) {
      return false;
    }
    return editUser.mutate({
      ...data,
      id: user.id,
    });
  }, [user, editUser]);

  return (
    <Container maxWidth="xl">
      <Box my={2}>
        {status !== 'success' || !user ? (
          <BodyLoading height="100vh"/>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={4} mb={2}>
                <Breadcrumbs list={breadcrumbs} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={8}>
                  <Paper variant="elevation" elevation={5} sx={{ mt: 2, p: 4 }}>
                    <UserForm
                      defaultValues={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        jobTitle: user.jobTitle,
                        company: user.company,
                        type: user.type,
                        phone: user.phone,
                        profilePicture: user.profilePicture,
                      }}
                      disabledFields={['type', 'phone']}
                      onSubmitRequest={onSubmit}
                      onSubmitButtonText={dictionary.forms.saveChanges}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
    </Container>
  );
};
