import React, { useCallback, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid, IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Share } from '@mui/icons-material';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { tasksClient } from 'clients/tasks/tasksClient';
import { TaskForm } from './TaskForm';


export const CreateTask = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const queryClient = useQueryClient();
  const { genericError, genericFeedback, copyUrlToShare } = useContext(LayoutContext);

  const createTask = useMutation(tasksClient.createTask, {
    mutationKey: cacheKeys.tasks.createTask,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.tasks.getTasks);
      queryClient.invalidateQueries(cacheKeys.events.getEvents);
      queryClient.invalidateQueries(cacheKeys.tasks.getTask);
      queryClient.invalidateQueries(cacheKeys.events.getEvent);
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControl);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      navigate(routes.tasks);
      genericFeedback(dictionary.tasks.form.taskCreated);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (task) => {
    return createTask.mutateAsync(task);
  }, [createTask]);

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item>
          <Card sx={{ p: 3, position: 'relative' }}>
            <Box position="absolute" top={16} right={16}>
              <Tooltip title="Copy Url to Share">
                <IconButton onClick={() => copyUrlToShare()}>
                  <Share/>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 4 }}>{dictionary.tasks.form.createTaskTitle}</Typography>
            <TaskForm
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.forms.save}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
