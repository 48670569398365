import React, { FC, useContext, useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';


import { cacheKeys } from 'config';
import { LocalizationContext, LayoutContext } from 'contexts';
import { companyClient } from 'clients/companies/companyClient';
import { useMe } from 'shared/hooks/useMe';

export type Form = {
  name: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
}

export const CompanyNameEditDialog: FC<Props> = ({ open, onClose }) => {

  const { user } = useMe();
  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const { handleSubmit, control, formState: { isSubmitting, errors }, reset } = useForm();

  const editCompany = useMutation(companyClient.patchCompany, {
    mutationKey: cacheKeys.companies.editCompany,
    onSuccess: () => {
      genericFeedback(dictionary.company.edit.feedbackCompanyNameUpdated);
      queryClient.invalidateQueries(cacheKeys.users.getMe);
    },
    onError: () => {
      genericError();
    },
  });

  useEffect(() => {
    reset({
      name: '',
    });
  }, [open, reset]);


  const onSubmit = useCallback(async (data: Form) => {
    await editCompany.mutateAsync({
      id: user?.company?.id,
      name: data.name,
    });
    onClose();
  }, [editCompany, onClose, user?.company?.id]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {dictionary.company.edit.editTheCompanyName}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Controller
            name="name"
            control={control}
            rules={{
              required: dictionary.forms.validations.required,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                size="small"
                placeholder={dictionary.company.edit.placeholderCompanyName}
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button type="button" variant="text" onClick={onClose}>{dictionary.dialogs.buttonCancel}</Button>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">{dictionary.forms.saveChanges}</LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
