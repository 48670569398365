import { useContext, FC, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Chip, Grid, IconButton, Paper, Typography } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { AccessTime, AddCircle, ArrowDropDown, ArrowDropUp, ChatOutlined } from '@mui/icons-material';
import { format } from 'date-fns';

import { routes } from 'config';
import { DialogContext, LocalizationContext } from 'contexts';
import { Dialog } from 'contexts/DialogContext/types';
import { sanitizeDescription } from 'helpers/html';

interface Props {
  document: any;
  level?: number;
  isParentRow?: boolean;
  sx?: SxProps;
  isInSearch: boolean;
}

export const DocumentRow: FC<Props> = ({ document, isParentRow, isInSearch = false, sx = {}, level= 1 }) => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const [isOpen, setIsOpen] = useState(false);
  const [showAddDocumentButton, setShowAddDocumentButton] = useState(false);
  const { openDialog } = useContext(DialogContext);

  const onCreateSubdocument = useCallback((id: number) => {
    localStorage.setItem('createDocumentParentId', `${id}`);
    openDialog(Dialog.CreateSubDocument);
  }, [openDialog]);

  useEffect(() => {
    setIsOpen(isInSearch);
  }, [isInSearch]);

  return (
    <Grid
      item
      onMouseEnter={() => setShowAddDocumentButton(true)}
      onMouseLeave={() => setShowAddDocumentButton(false)}
    >
      <Box
        p={3}
        pr={isParentRow ? undefined : 0}
        pb={isParentRow ? undefined : 0}
        component={isParentRow ? Paper : undefined}
        sx={{
          overflow: 'hidden',
          background: isParentRow ? 'white' : undefined,
          height: '100%',
          cursor: 'pointer',
          ...sx,
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          navigate(routes.viewDocument(document.id));
        }}
      >
        <Grid
          container
          xs={12}
          spacing={1}
          wrap="nowrap"
          mt="2px"
          display="flex"
        >
          {(isParentRow && !!document.sub_pages?.length ) && (
            <Grid item>
              <IconButton onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                setIsOpen(!isOpen);
              }}>
                {isOpen ? <ArrowDropUp/> : <ArrowDropDown/>}
              </IconButton>
            </Grid>
          )}
          <Grid item xs={4} position="relative" onMouseEnter={() => setShowAddDocumentButton(true)}>
            { !isParentRow && (
              <Box
                position="absolute"
                sx={(theme) => ({
                  borderTop: '1px solid #C4C4C4',
                  left: theme.spacing(-2),
                  width: theme.spacing(2),
                  top: theme.spacing(2),
                  bottom: 0,
                })}
              />
            )}
            <Typography
              variant={isParentRow ? 'h6' : 'body1'}
              fontWeight={500}
              sx={{ textTransform: 'capitalize' }}
            >
              {document.title}
            </Typography>
            {level < 4 && (
              <Button
                variant="dashed"
                startIcon={<AddCircle/>}
                size="small"
                sx={{
                  mt: '2px',
                  ml: 2,
                  visibility: showAddDocumentButton ? 'visible' : 'hidden',
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onCreateSubdocument(document.id);
                }}
              >
                {dictionary.documents.create.buttonAddSubDocument}
              </Button>
            )}
          </Grid>
          <Grid item display="flex" flexShrink={1} alignItems="center">
            <Typography noWrap
              sx={{ height: '40px', overflow: 'hidden', width: '422px' }}
              variant="body1"
              color="primary"
              dangerouslySetInnerHTML={{ __html: sanitizeDescription(document.html_content) }}
            />
          </Grid>
          <Box ml="auto"/>
          <Grid
            item
            flexBasis="420px"
            flexShrink={0}
            spacing={1}
            wrap="nowrap"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Chip
              sx={{ maxWidth: '160px' }}
              avatar={<Avatar alt={`${document.created_by?.first_name} ${document.created_by?.last_name}`}>{document.created_by?.first_name?.charAt(0)?.toUpperCase()}</Avatar>}
              label={document?.created_by.displayName|| ''}
            />
            <Box
              sx={{ opacity: 0.7 }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center" mr="35px">
                <AccessTime />
                <Typography variant="body1" align="center" ml="5px">
                  {dictionary.documents.list.updatedOn(
                    format(new Date(document.updated_at), 'MMM d, yyyy'),
                  )}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <ChatOutlined />
                <Typography color="primary" ml="5px" noWrap>
                  {document.comments?.length || 0}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {((isOpen || !isParentRow) && !!document.sub_pages?.length) && (
          <Grid container direction="column" position="relative" onMouseEnter={() => setShowAddDocumentButton(false)}>
            <Box
              position="absolute"
              sx={(theme) => ({
                borderRight: '1px solid #C4C4C4',
                top: '-20px',
                bottom: 0,
                left: isParentRow ? theme.spacing(6) : undefined,
              })}
            />
            {document.sub_pages.map((subDocument: any, index: number) => (
              <DocumentRow
                level={level + 1}
                document={{ ...subDocument }}
                sx={{
                  mt: index !== 0 ? 0 : 3,
                  pt: 0,
                  pl: isParentRow ? 9 : 4,
                }}
                isInSearch
              />
            ))}
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
