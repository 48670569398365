import React, { FC, useMemo } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { useDocuments } from 'shared/hooks/useDocuments';

export const DocumentAutocomplete: FC<any> = ({ label, TextFieldProps, excludeId, ...props }) => {

  const { flatDocuments } = useDocuments();
  const flattenDocuments = (document: any) => {
    return [
      document,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ...document.sub_pages.map(flattenDocuments),
    ];
  };

  const filteredDocuments = useMemo(() => {
    return flatDocuments?.filter((document: any) => document.level < 4 && document.id !== excludeId);
  }, [excludeId, flatDocuments]);

  return (
    <Autocomplete
      options={filteredDocuments}
      renderInput={props => <TextField {...props} label={label || 'Document'} InputLabelProps={{ shrink: true }} {...TextFieldProps} />}
      getOptionLabel={(d: any) => `${d.title || ''}`}
      {...props}
      onChange={(e, value) => props.onChange(value)}
    />
  );
};
