import React, { FC, useContext } from 'react';
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps, Typography,
} from '@mui/material';
import { LayoutContext } from 'contexts';

export const CircularReportProgress: FC<CircularProgressProps & { value: number, answered: number, total: number }> = ({ ...props }) => {
  const { isMobile } = useContext(LayoutContext);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#A6ADC0',
        }}
        size={isMobile ? 35 : 40}
        thickness={4}
        {...props}
        value={100}
      />

      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={isMobile ? 35 : 40}
        thickness={4}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="primary"
        >
          {`${props.answered} / ${props.total}`}
        </Typography>
      </Box>
    </Box>
  );
};