import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { tasksClient } from 'clients/tasks/tasksClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useDropdownResults = (params?: any, options: Partial<Options> = defaultOptions) => {

  const { data: { data: dropdownResults = [] } = {}, status, error } = useQuery(
    [cacheKeys.tasks.getDropdownResults, params],
    () => tasksClient.getDropdownResults(params),
    { enabled: options.enabled },
  );

  return {
    dropdownResults,
    status,
    error,
  };
};
