import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { usersClient } from 'clients/users/usersClient';

export type Params = {
  id: string;
};

export const useUser = (params: Params, enabled: boolean = true) => {
  const { data: { data: user } = {}, status, error } = useQuery(
    [cacheKeys.users.getUser, params],
    () => usersClient.getUser(params),
    { enabled },
  );

  return {
    status,
    error,
    user,
  };
};
