
import { Dictionary } from 'contexts/LocalizationContext/types';
import { RiskIndex } from 'clients/risks/riskClient.types';


export const getRiskIndexLabels = (dictionary: Dictionary) => ({
  [RiskIndex.Index1_6]: dictionary.risks.indexes.index1_6,
  [RiskIndex.Index7_12]: dictionary.risks.indexes.index7_12,
  [RiskIndex.Index13_19]: dictionary.risks.indexes.index13_19,
  [RiskIndex.Index20_25]: dictionary.risks.indexes.index20_25,
});

export const getRiskIndexLabel = (dictionary: Dictionary, index: RiskIndex) => getRiskIndexLabels(dictionary)[index];