import { mapTaskRiskControls } from 'clients/riskControls/riskControlsClient.formatter';

export const getAllRelatedTaskEvents = (task: any, dropdownResults: any = null) => {
  const taskEvents = [task.events, task.pages, task.reports, task.risk_controls.map(mapTaskRiskControls)];
  const res: { id: number, title: string, type: string, category?: string, due_date?: string }[] = [];

  taskEvents.forEach((eventTypeArray: []) => {
    eventTypeArray.forEach(({ id, title } : any) => {
      const relatedEvent = dropdownResults.find((e: any) => e.id === id && e.title === title);
      relatedEvent && res.push(relatedEvent);
    });
  });

  return res;
};