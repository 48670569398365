import React, { FC } from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';

export const DashboardCardTitle: FC<{ title: string, numberOfElements: number, color?: string }> = ({ ...props }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container alignItems="center" justifyContent="space-between" sx={{ height: '10%', pl: 1, pr: 1 }}>
      <Grid item>
        <Typography variant={matches ? 'h6' : 'h4'} color="primary" fontWeight={200}>{props.title}</Typography>
      </Grid>
      <Grid item sx={{ textAlign: 'right' }}>
        <Typography
          variant={matches ? 'h6' : 'h4'}
          color={props.color ?? 'primary'}
          fontWeight={400}
        >
          {props.numberOfElements}
        </Typography>
      </Grid>
    </Grid>
  );
};