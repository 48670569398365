import React, { useCallback, useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { emailRegex } from 'helpers/validation';
import { usersClient } from 'clients/users/usersClient';
import { LoggedOutActionsLayout } from 'shared/components/layout/LoggedOutActionsLayout/LoggedOutActionsLayout';


export const ResetPassword = () => {

  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const { dictionary } = useContext(LocalizationContext);
  const { control, setError, handleSubmit, formState: { isSubmitting, errors } } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = useCallback (async ({ email }) => {
    try {
      await usersClient.resetPassword(email);
      setSuccess(true);
    } catch(e: any) {
      if(e.response.status === 404) {
        setError('email', { type: 'error', message: dictionary.notFound });
      }
      console.error(e);
      setSuccess(false);
    }
  }, [setError, dictionary]);

  return (
    <LoggedOutActionsLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3" align="center">{dictionary.resetPassword}</Typography>
          </Grid>
          {success && (
            <Grid item>
              <Alert severity="success">{dictionary.passwordResetEmailSent}</Alert>
            </Grid>
          )}
          <Grid item>
            <Controller
              name="email"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                pattern: {
                  value: emailRegex,
                  message: dictionary.forms.validations.invalidEmail,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.email}
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
          <br/><br/>
          <Grid item>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                <Button size="large" type="button" variant="outlined" color="secondary" onClick={() => navigate(routes.login)}>
                  {dictionary.login}
                </Button>
              </Grid>
              <div style={{ marginLeft: 'auto' }}/>
              <Grid item>
                <LoadingButton loading={isSubmitting} size="large" type="submit" variant="contained" color="secondary">
                  {dictionary.reset}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </LoggedOutActionsLayout>
  );
};
