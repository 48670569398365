
import { Dictionary } from 'contexts/LocalizationContext/types';
import { RiskControlInterval, RiskControlStatus } from 'clients/riskControls/riskControlsClient.types';


export const getRiskControlIntervalLabels = (dictionary: Dictionary) => ({
  [RiskControlInterval.Day]: dictionary.frequency.daily,
  [RiskControlInterval.Week]: dictionary.frequency.weekly,
  [RiskControlInterval.Month]: dictionary.frequency.monthly,
  [RiskControlInterval.Quarter]: dictionary.frequency.quarterly,
  [RiskControlInterval.Semester]: dictionary.frequency.semesterly,
  [RiskControlInterval.Year]: dictionary.frequency.yearly,
});

export const getRiskControlStatusLabels = (dictionary: Dictionary) => ({
  [RiskControlStatus.Effective]: dictionary.riskControls.status.effective,
  [RiskControlStatus.NonEffective]: dictionary.riskControls.status.nonEffective,
  [RiskControlStatus.RequiresAction]: dictionary.riskControls.status.requiresAction,
  [RiskControlStatus.RequireAction]: dictionary.riskControls.status.requireAction,
});

export const getRiskControlIntervalLabel = (dictionary: Dictionary, interval: RiskControlInterval) => getRiskControlIntervalLabels(dictionary)[interval];
export const getRiskControlStatusLabel = (dictionary: Dictionary, status: RiskControlStatus) => getRiskControlStatusLabels(dictionary)[status];

export const getRiskControlIntervalIndex = (interval: RiskControlInterval) => {
  const intervals = Object.values(RiskControlInterval);
  
  return intervals.indexOf(interval);
};

export const getRiskControlIntervalByIndex = (index: number) => {
  const intervals = Object.values(RiskControlInterval);

  return intervals[index];
};
