import React, { Fragment, FC, useCallback, ReactElement } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/system';

export type FileUploaderProps = {
  name: string;
  value?: File[];
  onChange: (value: File[]) => void;
  label: string;
  inputProps?: {
    accept?: string;
    multiple?: boolean;
  },
  buttonRender?: ReactElement;
  buttonProps?: ButtonProps;
}

const HiddenInput = styled('input')({
  display: 'none',
});

export const FileUploader: FC<FileUploaderProps> = ({ name, label, onChange, inputProps, buttonRender, buttonProps }) => {

  const handleFileInput = useCallback((e) => {
    onChange(e.target.files);
  }, [onChange]);

  return (
    <Fragment>
      <HiddenInput
        id={name}
        type="file"
        onChange={handleFileInput}
        value=""
        {...inputProps}
      />
      <label htmlFor={name}>
        {!!buttonRender ? buttonRender : (
          <Button {...buttonProps} component="span">
            {label}
          </Button>
        )}
      </label>
    </Fragment>
  );
};
