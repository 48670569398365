import React, { Fragment, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { trackPageview } from 'tracking/tracking';
import { featureFlags, routes } from 'config';
import { UserContext } from 'contexts/UserContext/UserContext';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';
import { NotAuthenticated } from 'shared/components/Authenticated/NotAuthenticated';
import { Nav } from 'shared/components/layout/Nav/Nav';
import { SharedLayoutComponent } from 'shared/components/layout/SharedLayoutComponent/SharedLayoutComponent';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { SpeedDial } from 'shared/components/layout/SpeedDial/SpeedDial';

import { Login } from 'views/LoggedOut/Login';
import { SignUp } from 'views/LoggedOut/SignUp';
import { ResetPassword } from 'views/LoggedOut/ResetPassword';
import { Invite } from 'views/LoggedOut/Invite';
import { Home } from 'views/LoggedIn/Home/Home';
import { Welcome } from './LoggedIn/Welcome';
import { Companies } from './LoggedIn/Companies/Companies';
import { CreateCompany } from './LoggedIn/Company/CreateCompany';
import { EditCompany } from './LoggedIn/Company/EditCompany';
import { Users } from './LoggedIn/Users/Users';
import { CreateUser } from './LoggedIn/User/CreateUser';
import { EditUser } from './LoggedIn/User/EditUser';
import { Profile } from './LoggedIn/User/Profile';
import { Impersonate } from './LoggedOut/Impersonate';
import { Tasks } from './LoggedIn/Tasks/Tasks';
import { Documents } from './LoggedIn/Documents/Documents';
import { Risks } from './LoggedIn/Risks/Risks';
import { Incidents } from './LoggedIn/Incidents/Incidents';
import { Team } from './LoggedIn/Team/Team';
import { FAQ } from './LoggedIn/FAQ/FAQ';
import { Reports } from './LoggedIn/Reports/Reports';
import { ReportFulfillmentForm } from './LoggedIn/Report/ReportFulfillmentForm';
import { IncidentFulfillmentForm } from './LoggedIn/Incident/IncidentFulfillmentForm';
import { RiskControls } from './LoggedIn/RiskControls/RiskControls';
import { CreateRiskControl } from './LoggedIn/RiskControlForm/CreateRiskControl';
import { EditRiskControl } from './LoggedIn/RiskControlForm/EditRiskControl';
import { CreateRisk } from './LoggedIn/RiskForm/CreateRisk';
import { EditRisk } from './LoggedIn/RiskForm/EditRisk';
import { CreateIncidentForm } from './LoggedIn/Incident/CreateIncidentForm';
import { PreviewIncidentForm } from './LoggedIn/Incident/PreviewIncidentForm';
import { CreateReportForm } from './LoggedIn/Report/CreateReportForm';
import { PreviewReportForm } from './LoggedIn/Report/PreviewReportForm';
import { CreateTask } from './LoggedIn/TaskForm/CreateTask';
import { EditTask } from './LoggedIn/TaskForm/EditTask';
import { DocumentEdit } from './LoggedIn/Documents/Document/DocumentEdit';
import { DocumentView } from './LoggedIn/Documents/Document/DocumentView';


export const Root = () => {

  useScrollTop();
  const { loading } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    trackPageview();
  }, [location]);

  if(loading) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Fragment>
      <Nav/>
      <NotAuthenticated>
        <Routes>
          <Route path={routes.login} element={<Login/>}/>
          <Route path={routes.resetPassword} element={<ResetPassword/>}/>
          <Route path={routes.signup} element={featureFlags.signup ? <SignUp/> : <Navigate to={routes.login}/>} />
          <Route path={routes.nlSignup} element={<SignUp/>} />
          <Route path={routes.invite} element={<Invite/>}/>
          <Route path={routes.impersonateUser} element={<Impersonate/>}/>
          <Route path="*" element={<Navigate to={routes.login}/>}/>
        </Routes>
      </NotAuthenticated>
      <Authenticated>
        <SpeedDial/>
        <Routes>
          <Route path={routes.team} element={<Team/>}/>
          <Route path={routes.impersonateUser} element={<Impersonate/>}/>
          <Route path={routes.reports} element={<Reports/>}/>
          <Route path={routes.fulfillReport()} element={<ReportFulfillmentForm/>}/>
          <Route path={routes.createReport} element={<CreateReportForm/>}/>
          <Route path={routes.previewReportTemplate()} element={<PreviewReportForm/>}/>
          <Route path={routes.faq} element={<FAQ/>}/>
          <Route path={routes.risks} element={<Risks/>}/>
          <Route path={routes.createRisk} element={<CreateRisk/>}/>
          <Route path={routes.editRisk()} element={<EditRisk/>}/>
          <Route path={routes.riskControls} element={<RiskControls/>}/>
          <Route path={routes.createRiskControl} element={<CreateRiskControl/>}/>
          <Route path={routes.editRiskControl()} element={<EditRiskControl/>}/>
          <Route path={routes.incidents} element={<Incidents/>}/>
          <Route path={routes.fulfillIncident()} element={<IncidentFulfillmentForm/>}/>
          <Route path={routes.createIncident} element={<CreateIncidentForm/>}/>
          <Route path={routes.previewIncidentTemplate()} element={<PreviewIncidentForm/>}/>
          <Route path={routes.tasks} element={<Tasks/>}/>
          <Route path={routes.createTask} element={<CreateTask/>}/>
          <Route path={routes.editTask()} element={<EditTask/>}/>
          <Route path={routes.tasksStatus()} element={<Tasks/>}/>
          <Route path={routes.documents} element={<Documents/>}/>
          <Route path={routes.editDocument()} element={<DocumentEdit/>}/>
          <Route path={routes.viewDocument()} element={<DocumentView/>}/>
          <Route path={routes.companies} element={<Companies/>}/>
          <Route path={routes.createCompany} element={<CreateCompany/>}/>
          <Route path={routes.createUser} element={<CreateUser/>}/>
          <Route path={routes.editCompany()} element={<EditCompany/>}/>
          <Route path={routes.editUser()} element={<EditUser/>}/>
          <Route path={routes.home} element={<Home/>}/>
          <Route path={routes.myAccount} element={<Profile/>}/>
          <Route path={routes.users} element={<Users/>}/>
          <Route path={routes.welcome} element={<Welcome/>}/>
          <Route path="*" element={<Navigate to={routes.home}/>}/>
        </Routes>
      </Authenticated>
      <SharedLayoutComponent/>
    </Fragment>
  );
};
