import React, { Fragment, FC, ReactElement, useContext } from 'react';
import {
  Box,
  Grid,
  Paper,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import { LocalizationContext } from 'contexts';
import { UserType } from 'clients/users/userClient.types';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';

export type Filters = {
  search?: string;
  type?: UserType;
}

type Props = {
  children(props: {
    filters: Filters,
  }): ReactElement;
}

export const UsersFilters: FC<Props> = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [newSearch, currSearch, setSearch] = useDebouncedQueryState('search');
  const [type, setType] = useQueryState<UserType>('type');

  return (
    <Fragment>
      <Grid item xs={12} sm={4} lg={3} >
        <Box component={Paper} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{dictionary.filters}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                style={{ minWidth: 200 }}
                label={dictionary.search}
                value={newSearch || null}
                onChange={({ target: { value } }) => setSearch(value.trim() === '' ? undefined : value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                size="small"
                style={{ minWidth: 200 }}
                label={dictionary.forms.user.fieldType}
                defaultValue="all"
                value={type || 'all'}
                onChange={({ target: { value } }) => setType(value === 'all' ? undefined : value as UserType)}
              >
                <MenuItem value="all">{dictionary.all}</MenuItem>
                <MenuItem value={UserType.Customer}>{dictionary.user.typeCustomerLabel}</MenuItem>
                <MenuItem value={UserType.Admin}>{dictionary.user.typeAdminLabel}</MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} lg={9}>
        {children({
          filters: {
            search: currSearch,
            type,
          },
        })}
      </Grid>
    </Fragment>
  );
};
