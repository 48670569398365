import React, { useContext } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { getEventClassificationLabels } from 'helpers/event';
import { EventClassification } from 'clients/events/eventsClient.types';


export const EventClassificationSelect = ({ onChange, label, ...props }: any) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <TextField
      label={label || 'Classification'}
      onChange={onChange}
      {...props}
      select
    >
      <MenuItem key={EventClassification.Complaint} value={EventClassification.Complaint}>{getEventClassificationLabels(dictionary)[EventClassification.Complaint]}</MenuItem>
      <MenuItem key={EventClassification.Feedback} value={EventClassification.Feedback}>{getEventClassificationLabels(dictionary)[EventClassification.Feedback]}</MenuItem>
      <MenuItem key={EventClassification.Incident} value={EventClassification.Incident}>{getEventClassificationLabels(dictionary)[EventClassification.Incident]}</MenuItem>
      <MenuItem key={EventClassification.NonConformity} value={EventClassification.NonConformity}>{getEventClassificationLabels(dictionary)[EventClassification.NonConformity]}</MenuItem>
    </TextField>
  );
};
