import React, { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Card, Container, Grid, Radio, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/lab';
import { format } from 'date-fns';

import { routes } from 'config';
import { LocalizationContext } from 'contexts';
import { ReportType } from 'types/Report';
import { addQuery } from 'helpers/query';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useReportTemplates } from 'shared/hooks/useReportTemplates';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

export const CreateIncidentForm = () => {
  const [date] = useQueryState('reportDate');
  const [id] = useQueryState('templateId');
  const [qTitle] = useQueryState('title');
  const { dictionary } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { templates, status } = useReportTemplates({
    category: [
      ReportType.Incident,
      ReportType.Complaint,
      ReportType.Feedback,
      ReportType.NonConformity,
    ],
  });

  const methods = useForm<any>({
    defaultValues: {
      templateId: id ? Number(id) : undefined,
      reportDate: date ? new Date(date || '') : null,
      title: qTitle,
    },
  });

  const { handleSubmit, watch, setValue } = methods;

  const reportDate = watch('reportDate');
  const templateId = watch('templateId');
  const title = watch('title');

  const onSubmit = useCallback((data: any) => {
    navigate({
      pathname: routes.previewIncidentTemplate(data.templateId),
      search: addQuery(location.search, {
        reportDate: format(data.reportDate, 'yyyy-MM-dd'),
        title: data.title,
        templateId: data.templateId,
      }),
    });
  }, [navigate, location]);

  return (
    <Container maxWidth="md">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" alignItems="center" sx={{ minHeight: 'calc(100vh - 100px)', mt: '100px' }}>
            <Grid item>
              <Card sx={{ p: 3, position: 'relative', backgroundColor: '#F7F7F7' }}>
                <Grid container>
                  <Grid item sm={10}>
                    <Typography variant="h4">{dictionary.incidents.form.createIncident}</Typography>
                    <Typography>{dictionary.incidents.form.createIncidentSubtitle}</Typography>
                  </Grid>
                  <Grid item sm={2}>
                    <Controller
                      name="reportDate"
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          inputFormat="dd.MM.yyyy"
                          mask="__.__.____"
                          label={dictionary.incidents.form.fieldIncidentDate}
                          renderInput={props => <TextField size="small" {...props}/>}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} mt="30px">
                    <Controller
                      name="title"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={dictionary.incidents.form.fieldTitle}
                          defaultValue={title}
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} paddingY="30px">
                    {(status === 'loading' || !templates) && (
                      <BodyLoading height="40vh"/>
                    )}
                    <Box sx={{ maxHeight: '350px', overflowY: 'auto' }}>
                      {templates?.map(({ id, title }: any, i: number) => (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #ECECEC',
                            background: !templateId ? 'white' : parseInt(templateId || '') === id ? 'white' : 'transparent',
                            marginBottom: '10px',
                            cursor: 'pointer',
                          }}
                          key={id}
                          onClick={() => setValue('templateId', id)}
                        >
                          <Controller
                            name="templateId"
                            render={({ field }) => (
                              <Radio
                                {...field}
                                value={id}
                                checked={parseInt(templateId || '') === id}
                              />
                            )}
                          />
                          <Typography fontWeight="500">{title}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item alignItems="center" display="flex" justifyContent="space-between" width="100%" mt="30px">
              <Button
                variant="outlined"
                size="large"
                color="primary"
                type="button"
                onClick={() => navigate(routes.incidents)}
              >
                {dictionary.incidents.form.backButton}
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                disabled={!templateId || !reportDate || !title}
              >
                {dictionary.incidents.form.nextButton}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Container>
  );
};
