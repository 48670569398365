import React, { useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Grid, IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Delete, Share } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import find from 'lodash/find';

import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { risksClient } from 'clients/risks/risksClient';
import { useRisk } from 'shared/hooks/useRisk';
import { useUsers } from 'shared/hooks/useUsers';
import { useRiskControls } from 'shared/hooks/useRiskControls';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';

import { RiskForm } from './RiskForm';


export const EditRisk = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const queryClient = useQueryClient();
  const { genericError, genericFeedback, copyUrlToShare } = useContext(LayoutContext);
  const { riskId = null }: any = useParams();

  const { users, error: usersError, status: usersStatus } = useUsers();
  const { riskControls, error: riskControlsError, status: riskControlsStatus } = useRiskControls();
  const { risk, error, status } = useRisk({ id: riskId });

  const editRisk = useMutation(risksClient.patchRisk, {
    mutationKey: cacheKeys.risks.editRisk,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.risks.getRisks);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      queryClient.invalidateQueries([cacheKeys.risks.getRisk, {
        id: riskId,
      }]);
      navigate(routes.risks);
      genericFeedback(dictionary.risks.form.riskSaved);
    },
    onError: () => {
      genericError();
    },
  });

  const deleteRisk = useMutation(risksClient.deleteRisk, {
    mutationKey: cacheKeys.risks.deleteRisk,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.risks.getRisks);
      queryClient.invalidateQueries(cacheKeys.riskControls.getRiskControls);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      queryClient.removeQueries([cacheKeys.risks.getRisk, {
        id: defaultValues.id,
      }]);

      genericFeedback(dictionary.risks.form.riskDeleted);
      navigate(routes.risks);
    },
    onError: () => {
      genericError();
    },
  });

  const onDelete = useCallback(async (riskId: number) => {
    const userConfirmed = await asyncConfirmation({
      title: dictionary.risks.list.deleteConfirmation,
    });

    if(userConfirmed) {
      return deleteRisk.mutateAsync(riskId);
    }
    
  }, [dictionary, asyncConfirmation, deleteRisk]);

  const onSubmit = useCallback(async (risk) => {
    if (!risk) {
      return false;
    }
    return editRisk.mutateAsync(risk);
  }, [editRisk]);

  const allDataIsLoaded = [
    status,
    usersStatus,
    riskControlsStatus,
  ].every((requestStatus) => requestStatus === 'success');

  if (error || usersError || riskControlsError) {
    genericError();
  }

  if (!allDataIsLoaded) {
    return <BodyLoading height="40vh"/>;
  }

  const defaultValues = {
    ...risk,
    existing_risk_controls: riskControls.filter((riskControl: any) => find(riskControl.risks, { risk: risk.risk })),
    owner: users.find(({ id }: any) => risk.owner?.id === id),
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: 'calc(100vh - 100px)' }}>
        <Grid item>
          <Card sx={{ p: 3, position: 'relative' }}>
            <Box position="absolute" top={16} right={16}>
              <Tooltip title={dictionary.forms.copyUrlToShare}>
                <IconButton onClick={() => copyUrlToShare(dictionary.risks.form.riskUrlCopied)}>
                  <Share/>
                </IconButton>
              </Tooltip>
            </Box>
            <Typography variant="h4" sx={{ mb: 4 }}>
              {dictionary.risks.form.editRiskTitle}
            </Typography>
            <Box textAlign="right" mb={2}>
              <LoadingButton
                variant="outlined"
                size="large"
                color="primary"
                loading={deleteRisk.isLoading}
                disabled={deleteRisk.isLoading}
                onClick={() => onDelete(defaultValues.id)}
                endIcon={<Delete/>}
              >
                {dictionary.forms.delete}
              </LoadingButton>
            </Box>
            <RiskForm
              onSubmitRequest={onSubmit}
              onSubmitButtonText={dictionary.forms.save}
              defaultValues={defaultValues}
            />
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
