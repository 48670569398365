import { MaybeNull } from 'types';

export enum Dialog {
  InviteTeamMember = 'inviteTeamMember',
  EditCompanyName = 'editCompanyName',
  CreateDocument = 'createDocument',
  CreateSubDocument = 'createSubDocument',
};

export type ContextProps = {
  openDialog: (dialog: Dialog) => any;
  closeDialog: (dialog: Dialog) => any;
  asyncConfirmation: AsyncConfirmation;
};

export type AsyncDialogProps = {
  show?: boolean;
  title?: MaybeNull<React.ReactNode>;
  content?: MaybeNull<React.ReactNode>;
  confirmLabel?: MaybeNull<React.ReactNode>;
  cancelLabel?: MaybeNull<React.ReactNode>;
}

export type AsyncConfirmation = (props: AsyncDialogProps) => Promise<boolean>;
