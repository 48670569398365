import React, { Fragment, FC, useContext, useState, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DateRangePicker } from '@mui/lab';
import { format, addDays } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { addQuery } from 'helpers/query';
import { getAllRelatedTaskEvents } from 'helpers/task';
import { tasksClient } from 'clients/tasks/tasksClient';
import { useDropdownResults } from 'shared/hooks/useDropdownResults';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useUsers } from 'shared/hooks/useUsers';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useTasks } from 'shared/hooks/useTasks';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { TaskStatusSelect } from 'shared/components/form/TaskStatusSelect/TaskStatusSelect';


export const TasksActions: FC<any> = ({ row }) => {
  const queryClient = useQueryClient();
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const onDelete = useCallback(async () => {
    setIsDeleting(true);
    try {
      const userConfirmed = await asyncConfirmation({ title: dictionary.tasks.list.deletePrompt });
      if(userConfirmed) {
        await tasksClient.deleteTask(row.id);
        await queryClient.invalidateQueries(cacheKeys.tasks.getTasks);
        await queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
        genericFeedback(dictionary.tasks.form.taskDeleted);
      } else {
        setIsDeleting(false);
      }
    } catch(e) {
      genericError();
      console.error(e);
    }
    setIsDeleting(false);
  }, [row, queryClient, asyncConfirmation, genericError, genericFeedback, dictionary]);

  return (
    <Fragment>
      <IconButton onClick={() => navigate(routes.editTask(row.id))}>
        <Edit/>
      </IconButton>
      <IconButton onClick={onDelete}>
        {isDeleting ? <CircularProgress style={{ width: 22, height: 22 }}/> : <Delete/>}
      </IconButton>
    </Fragment>
  );
};

export const TaskEvents: FC<any> = ({ row }) => {
  const navigate = useNavigate();
  const { dropdownResults } = useDropdownResults();
  const relatedEvents = getAllRelatedTaskEvents(row, dropdownResults);

  const handleNavigateToEvent = useCallback((event: React.MouseEvent<HTMLSpanElement>, relatedEvent: any) => {
    event.stopPropagation();
    event.preventDefault();

    switch(relatedEvent.type) {
    case 'report':
      navigate(routes.fulfillReport(relatedEvent.id));
      break;
    case 'page':
      navigate(routes.editDocument(relatedEvent.id));
      break;
    case 'event':
      navigate(routes.fulfillIncident(relatedEvent.id));
      break;
    case 'risk_control':
      navigate(routes.editRiskControl(relatedEvent.id));
      break;
    default:
      break;
    }

  }, [navigate]);

  return (
    <>
      {relatedEvents.map((e : { id: number, title: string, type: string, category?: string, due_date?: string }) => (
        e && (
          <Grid key={e.title} container direction="column" columnSpacing={0} wrap="nowrap" sx={{ maxHeight: 52 }}>
            <Grid item sx={{ display: 'flex', flexDirection: 'column', marginLeft: 0, marginRight: 'auto' }}>
              <Chip
                sx={{ borderRadius: 2 }}
                size="small"
                label={(
                  e?.category || e.type).charAt(0).toUpperCase() + (e?.category || e.type)
                  .slice(1).replace('-', ' ')
                  .replace('_', ' ')}
              />
            </Grid>
            <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography onClick={(event) => handleNavigateToEvent(event, e)}>
                {e.title.length >= 20 ? e.title.substr(0, 20) + '...' : e.title}
              </Typography>
            </Grid>
          </Grid>
        )
      ))}
    </>
  );
};


const columns = ({ status }: any): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    width: 80,
    editable: false,
    sortable: true,
    disableColumnMenu: true,
  },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    width: 180,
    renderCell: ({ value, row }) => {
      let color: 'success' | 'warning' | 'error' = 'warning';
      if(value === 'completed') {
        color = 'success';
      } else if(row?.due_date) {
        const dueDate = new Date(row?.due_date);
        if(dueDate < addDays(new Date(), 7)) {
          color = 'error';
        }
      }
      return (
        <Chip
          variant="outlined"
          color={color}
          label={value.replace('-', ' ').replace('_', ' ')}
          sx={{ textTransform: 'capitalize', width: 100 }}
        />
      );
    },
  },
  {
    field: 'due_date',
    headerName: 'Due Date',
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => format(new Date(value as string), 'dd MMM yy'),
  },
  {
    field: 'title',
    headerName: 'Title',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
  },
  {
    field: 'event',
    headerName: 'Event',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    renderCell: TaskEvents,
  },
  {
    field: 'assigned_to',
    headerName: 'Asignee',
    flex: 1,
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    renderCell: ({ value }) => value && (
      <Grid container alignItems="center" spacing={1} wrap="nowrap">
        <Grid item>
          <Avatar sx={{ margin: 'auto', mr: 1, width: 30, height: 30, fontSize: 12, color: '#ececec' }}>
            {(`${value?.first_name || ''} ${value?.last_name || ''}`).split(' ').map((s: string) => s[0]).join('')}
          </Avatar>
        </Grid>
        <Grid item>
          {`${value?.first_name || ''} ${value?.last_name || ''}`}
        </Grid>
      </Grid>
    ),
  },
  {
    field: 'priority',
    headerName: 'Priority',
    editable: false,
    sortable: true,
    disableColumnMenu: true,
    align: 'right',
  },
  {
    field: 'edit',
    headerName: '',
    editable: false,
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: TasksActions,
  },
];



export const Tasks: FC<any> = () => {

  useScrollTop();
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = useUsers();
  const { isMobile } = useContext(LayoutContext);
  const [gteDate] = useQueryState('gteDate');
  const [lteDate] = useQueryState('lteDate');
  const [search, currSearch, setSearch] = useDebouncedQueryState('search');
  const [assignedTo, setAssignedTo] = useQueryState('assigned-to');
  const [status, setStatus] = useQueryState('status');

  const { tasks = [] } = useTasks({
    search: currSearch,
    status,
    assignedTo,
    gteDate,
    lteDate,
  });

  if(users.length === 0) {
    return null;
  }

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mb: 8 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">Tasks</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Search"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  fullWidth
                />
              </Grid>
              {!['require-action', 'immediate-action'].includes(status || '') && (
                <Grid item xs={12} sm={6}>
                  <DateRangePicker
                    inputFormat="dd.MM.yyyy"
                    mask="__.__.____"
                    onChange={(([newGteDate, newLteDate]: any) => {
                      try {
                        navigate({
                          search: addQuery(location.search, {
                            gteDate: newGteDate ? format(newGteDate, 'yyyy-MM-dd'): null,
                            lteDate: newLteDate ? format(newLteDate, 'yyyy-MM-dd'): null,
                          }),
                        });
                      } catch(e) {
                        console.error(e);
                      }
                    })}
                    value={[gteDate ? new Date(gteDate) : null, lteDate ? new Date(lteDate) : null]}
                    renderInput={(startProps, endProps) => (
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            {...startProps}
                            label="Due From"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            {...endProps}
                            label="Due To"
                          />
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={3}>
                <TaskStatusSelect
                  value={status}
                  onChange={(e: any) => setStatus(e.target.value !== 'all' ? e.target.value : undefined)}
                  defaultValue="all"
                  allowAll
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <UserAutocomplete
                  value={users.find(({ id }) => `${id}` === assignedTo)}
                  onChange={(value: any) => setAssignedTo(value?.id)}
                  label="Assignee"
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box p={2} component={Paper}>
            <DataGrid
              columns={columns({ status })}
              rows={tasks}
              autoHeight
              rowsPerPageOptions={[10, 20, 50, 100]}
              getRowClassName={() => 'row'}
              disableSelectionOnClick
              sx={{
                '& .row': {
                  cursor: 'pointer',
                },
                '& .row:hover': {
                  background: 'rgba(255, 255, 255, 0.1) !important',
                },
                '& .row:nth-of-type(even)': {
                  background: 'rgba(255, 255, 255, 0.05)',
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
