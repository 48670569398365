
export enum FileImage {
  Png = 'image/png',
  Jpeg = 'image/jpeg',
  Svg = 'image/svg+xml',
};

export enum FileDocument {
  Pdf = 'application/pdf',
  Doc = 'application/msword',
  Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  Odt = 'application/vnd.oasis.opendocument.text',
  Rtf = 'application/rtf',
  Xls = 'application/vnd.ms-excel',
  Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  Ods = 'application/vnd.oasis.opendocument.spreadsheet',
  Csv = 'text/csv',
  Ppt = 'application/vnd.ms-powerpoint',
  Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Odp = 'application/vnd.oasis.opendocument.presentation',
  Txt = 'text/plain',
}

export enum FileVideo {
  Mp4 = 'video/mp4',
  Mpeg = 'video/mpeg',
  Ogg = 'video/ogg',
  Webm = 'video/webm',
}

export enum FileType {
  Image = 'image',
  Video = 'video',
  File = 'file',
};
