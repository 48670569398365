import React, { FC, useContext, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  FormGroup,
  TextField,
  Typography,
  Box,
  Stack,
  Switch,
  Tooltip,
} from '@mui/material';
import {
  ExpandMore,
  Done,
} from '@mui/icons-material';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/lab';
import { format, formatISO } from 'date-fns';

import { LocalizationContext } from 'contexts';
import { isValidDate } from 'helpers/date';
import { sanitizeDescription } from 'helpers/html';
import { RichTextField } from 'shared/components/form/RichTextField/RichTextField';

import { QuestionForm } from './QuestionForm';

export const ReportQuestionList: FC<any> = ({
  expandAll,
  setExpandAll = () => {},
  report,
  isReadOnly,
  onAnswerChange = () => {},
}) => {

  const { dictionary } = useContext(LocalizationContext);
  const [expanded, setExpanded] = useState<number>();

  return (
    <>
      {report?.questions.map(({ id, title, description, answer_options, answer_type, answer, question_template }: any, i: number) => (
        <Grid item xs={12} key={i}>
          <Accordion
            square
            expanded={isReadOnly || expandAll || expanded === i}
            onChange={() => {
              setExpandAll(false);
              setExpanded(expanded === i ? undefined : i);
            }}
            sx={{
              ...expanded === i && {
                background: '#ececec',
              },
              '&:hover': {
                background: '#ececec',
              },
            }}
            className="print-report-container"
          >
            <AccordionSummary expandIcon={!isReadOnly && <ExpandMore className="no-print"/>}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Typography variant="h6">{i+1}</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    fontWeight={expanded === i ? 500 : 300}
                  >
                    {question_template?.title || title}
                  </Typography>
                </Grid>
                {answer && (
                  <Grid item>
                    <Done sx={{ verticalAlign: 'middle' }}/>
                  </Grid>
                )}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {(question_template?.description || description) && (
                <Typography variant="body1" sx={{ mt: -3, mb: isReadOnly ? 0 : 3 }} dangerouslySetInnerHTML={{ __html: sanitizeDescription(question_template?.description || description) }}/>
              )}
              {!isReadOnly && (
                <>
                  {((question_template?.answer_type || answer_type) === 'single_choice') && (
                    <RadioGroup
                      name={`question-${id}`}
                      defaultValue={answer?.[answer_type]}
                      onChange={(event) => onAnswerChange({
                        questionId: id,
                        answerId: answer?.id,
                        answerType: answer_type,
                        answerValue: event.target.value,
                      })}
                    >
                      <Grid container spacing={2} alignItems="center">
                        {((question_template?.answer_options || answer_options) as any || [])?.map((value: any, i: number) => (
                          <Grid item xs={12} sm="auto" key={i}>
                            <Tooltip title={value?.description ? (<div dangerouslySetInnerHTML={{ __html: sanitizeDescription(value.description) }}/>) : ''}>
                              <FormControlLabel
                                disabled={isReadOnly}
                                value={value?.value}
                                control={<Radio/>}
                                label={<Typography variant="body1" fontWeight={500}>{value?.value}</Typography>}
                              />
                            </Tooltip>
                          </Grid>
                        ))}
                      </Grid>
                    </RadioGroup>
                  )}
                  {((question_template?.answer_type || answer_type) === 'multiple_choice') && (
                    <QuestionForm
                      name="multiple-choice"
                      defaultValue={answer?.multiple_choice || []}
                      onSubmit={(value: any) => onAnswerChange({
                        questionId: id,
                        answerId: answer?.id,
                        answerType: answer_type,
                        answerValue: value,
                      })}
                    >
                      <FormGroup>
                        <Grid container spacing={2} alignItems="center">
                          {((question_template?.answer_options || answer_options) as any || [])?.map((value: any, i: number) => (
                            <Grid item xs={12} sm="auto" key={i}>
                              <Tooltip title={value?.description ? (<div dangerouslySetInnerHTML={{ __html: sanitizeDescription(value.description) }}/>) : ''}>
                                <FormControlLabel
                                  control={
                                    <Controller
                                      name="multiple-choice"
                                      render={(field) => (
                                        <Checkbox
                                          {...field}
                                          value={value?.value}
                                          disabled={isReadOnly}
                                          defaultChecked={answer?.multiple_choice?.includes(value?.value || value)}
                                          onChange={(event, checked) => {
                                            if (checked) {
                                              field.field.onChange([
                                                ...field.field.value,
                                                event.target.value,
                                              ]);
                                            } else {
                                              field.field.onChange(
                                                field.field.value.filter(
                                                  (value: any) => value !== event.target.value,
                                                ),
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  }
                                  label={<Typography variant="body1" fontWeight={500}>{value?.value}</Typography>}
                                />
                              </Tooltip>
                            </Grid>
                          ))}
                        </Grid>
                      </FormGroup>
                    </QuestionForm>
                  )}
                  {((question_template?.answer_type || answer_type) === 'long_text') && (
                    <QuestionForm
                      name="long_text"
                      defaultValue={answer?.long_text}
                      submitOnBlur
                      onSubmit={(value: any) => onAnswerChange({
                        questionId: id,
                        answerId: answer?.id,
                        answerType: answer_type,
                        answerValue: value,
                      })}
                    >
                      <Controller
                        name="long_text"
                        render={({ field }) => (
                          <RichTextField
                            {...field}
                            readOnly={isReadOnly}
                            variant="outlined"
                            sanitize={sanitizeDescription}
                            label={dictionary.reports.form.fieldText}
                            lines={4}
                          />
                        )}
                      />
                    </QuestionForm>
                  )}
                  {((question_template?.answer_type || answer_type) === 'text') && (
                    <TextField
                      disabled={isReadOnly}
                      variant="outlined"
                      label={dictionary.reports.form.fieldAnswer}
                      defaultValue={answer?.text}
                      onBlur={(event) => onAnswerChange({
                        questionId: id,
                        answerId: answer?.id,
                        answerType: answer_type,
                        answerValue: event.target.value,
                      })}
                    />
                  )}
                  {((question_template?.answer_type || answer_type) === 'short_text') && (
                    <Box>
                      <TextField
                        disabled={isReadOnly}
                        variant="outlined"
                        label={dictionary.reports.form.fieldAnswer}
                        style={{ maxWidth: 300 }}
                        defaultValue={answer?.short_text}
                        onBlur={(event) => onAnswerChange({
                          questionId: id,
                          answerId: answer?.id,
                          answerType: answer_type,
                          answerValue: event.target.value,
                        })}
                      />
                    </Box>
                  )}
                  {((question_template?.answer_type || answer_type) === 'date') && (
                    <Box>
                      <QuestionForm
                        name="date"
                        defaultValue={answer?.date || null}
                        submitOnBlur
                        onSubmit={(value: any) => isValidDate(value) && onAnswerChange({
                          questionId: id,
                          answerId: answer?.id,
                          answerType: answer_type,
                          answerValue: format(value, 'yyyy-MM-dd'),
                        })}
                      >
                        <Controller
                          name="date"
                          render={({ field }) => (
                            <DatePicker
                              {...field}
                              disabled={isReadOnly}
                              label={dictionary.reports.form.fieldDate}
                              renderInput={props => <TextField sx={{ maxWidth: 200, ml: 'auto' }} {...props}/>}
                            />
                          )}
                        />
                      </QuestionForm>
                    </Box>
                  )}
                  {((question_template?.answer_type || answer_type) === 'time') && (
                    <Box>
                      <QuestionForm
                        name="time"
                        defaultValue={answer?.time ? `2022-01-01T${answer?.time}` : null}
                        submitOnBlur
                        onSubmit={(value: any) => isValidDate(value) && onAnswerChange({
                          questionId: id,
                          answerId: answer?.id,
                          answerType: answer_type,
                          answerValue: format(value, 'HH:mm'),
                        })}
                      >
                        <Controller
                          name="time"
                          render={({ field }) => (
                            <TimePicker
                              {...field}
                              disabled={isReadOnly}
                              label={dictionary.reports.form.fieldTime}
                              renderInput={props => <TextField sx={{ maxWidth: 200, ml: 'auto' }} {...props}/>}
                            />
                          )}
                        />
                      </QuestionForm>
                    </Box>
                  )}
                  {((question_template?.answer_type || answer_type) === 'bool') && (
                    <Box>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>{dictionary.no}</Typography>
                        <Switch
                          disabled={isReadOnly}
                          checked={answer?.bool}
                          onChange={(event) => onAnswerChange({
                            questionId: id,
                            answerId: answer?.id,
                            answerType: answer_type,
                            answerValue: event.target.checked,
                          })}
                        />
                        <Typography>{dictionary.yes}</Typography>
                      </Stack>
                    </Box>
                  )}
                  {((question_template?.answer_type || answer_type) === 'datetime') && (
                    <Box>
                      <QuestionForm
                        name="datetime"
                        defaultValue={answer?.datetime || null}
                        submitOnBlur
                        onSubmit={(value: any) => isValidDate(value) && onAnswerChange({
                          questionId: id,
                          answerId: answer?.id,
                          answerType: answer_type,
                          answerValue: formatISO(value),
                        })}
                      >
                        <Controller
                          name="datetime"
                          render={({ field }) => (
                            <DateTimePicker
                              {...field}
                              disabled={isReadOnly}
                              renderInput={props => <TextField sx={{ maxWidth: 200, ml: 'auto' }} {...props}/>}
                              label={dictionary.reports.form.fieldDateTime}
                            />
                          )}
                        />
                      </QuestionForm>
                    </Box>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </>
  );
};
