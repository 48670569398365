import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';

const RiskControlsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getRiskControls = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/`,
      method: 'GET',
      params: {
        owner: params.owner,
        next_check_at__gte: params.gteDate,
        next_check_at__lte: params.lteDate,
        status: params.status,
        search: params.search,
        limit: params.limit || DEFAULT_QUERY_LIMIT,
      },

    },
  });
};

const getRiskControl = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/${id}/`,
      method: 'GET',
    },
  });
};

const createRiskControl = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/`,
      method: 'POST',
      data: data,
    },
  });
};

const checkRiskControl = (id: string | number): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/${id}/check/`,
      method: 'POST',
    },
  });
};

const editRiskControl = (id: string | number, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/${id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchRiskControl = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteRiskControl = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RiskControlsApiBaseUrl}/risk-controls/${id}/`,
      method: 'DELETE',
    },
  });
};

export const riskControlsClient = {
  createRiskControl,
  deleteRiskControl,
  editRiskControl,
  patchRiskControl,
  getRiskControls,
  getRiskControl,
  checkRiskControl,
};
