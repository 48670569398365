import { useQuery } from 'react-query';
import { cacheKeys } from 'config';
import { reportsClient } from 'clients/reports/reportsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useReportTemplates = (params: any = {}, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.reports.getReportTemplates, params],
    () => reportsClient.getReportTemplates(params),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    count: data?.count || 0,
    templates: data?.results || [],
  };
};
