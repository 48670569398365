import React from 'react';
import {
  Container,
  Grid,
  Paper,
} from '@mui/material';

import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { TeamHeader } from 'views/LoggedIn/Team/partials/TeamHeader';
import { TeamList } from 'views/LoggedIn/Team/TeamList';
import { CompanySection } from './partials/CompanySection';

export const Team = () => {

  const breadcrumbs = useBreadcrumbs('team');

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs list={breadcrumbs} />
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ height: '100%' }}>
            <CompanySection />
            <TeamHeader>
              <TeamList />
            </TeamHeader>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
