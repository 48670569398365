import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { documentsMediaClient } from 'clients/documentMedia/documentsMediaClient';

type HtmlEditorProps = {
  readonly?: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onSave?: () => void;
  pageId?: number | string;
};

export const HtmlEditor = ({ readonly = false, onChange = () => {}, onSave = () => {}, defaultValue = '', pageId = '' }: HtmlEditorProps) => {

  const editorRef = useRef<any>();

  return (
    <Editor
      onEditorChange={onChange}
      initialValue={defaultValue}
      disabled={readonly}
      init={{
        max_height: readonly ? undefined : 450,
        menubar: false,
        setup: (editor: any) => {
          editorRef.current = editor;
        },
        plugins: 'autoresize advlist autolink lists link image charmap preview anchor ' +
          'searchreplace visualblocks save ' +
          'insertdatetime media table' ,
        toolbar: readonly ? false : 'undo redo | formatselect searchreplace table | ' +
        'bold italic underline | alignleft aligncenter alignright | numlist bullist checklist | link image | customSave',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        file_picker_types: 'image',
        statusbar: false,
        file_picker_callback: (cb, value, meta) => {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');
      
          /*
            Note: In modern browsers input[type="file"] is functional without
            even adding it to the DOM, but that might not be the case in some older
            or quirky browsers like IE, so you might want to add it to the DOM
            just in case, and visually hide it. And do not forget do remove it
            once you do not need it anymore.
          */
      
          input.onchange = function () {
            // @ts-ignore
            var file = this.files[0];
      
            var reader = new FileReader();
            reader.onload = function () {
              /*
                Note: Now we need to register the blob in TinyMCEs image blob
                registry. In the next release this part hopefully won't be
                necessary, as we are looking to handle it internally.
              */
              var id = 'blobid' + (new Date()).getTime();
              var blobCache = editorRef.current.editorUpload.blobCache;
              // @ts-ignore
              var base64 = reader?.result?.split(',')?.[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              /* call the callback and populate the Title field with the file name */
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
      
          input.click();
        },
        images_upload_handler: async (blobInfo, success, failure) => {
          try {
            const result = await documentsMediaClient.createAttachment({ documentId: pageId, file: blobInfo });
            success(result.data.file);
          } catch (error) {
            console.error(error);
            failure('Failed to upload');
          }
        },
      }}
      tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
    />
  );
};
