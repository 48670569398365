import React, { FC, useContext } from 'react';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { ConfirmationPrompt } from 'shared/components/layout/ConfirmationPrompt/ConfirmationPrompt';

export const SharedLayoutComponent: FC = () => {

  const {
    showPopup,
    setShowPopup,
    onConfirmPopup,
    onCancelPopup,
    popupTitle,
    popupContent,
    popupCancelLabel,
    popupConfirmLabel,
    Snackbar,
  } = useContext(LayoutContext);
  return (
    <div>
      <ConfirmationPrompt
        open={showPopup}
        setOpen={setShowPopup}
        onConfirm={onConfirmPopup}
        onCancel={onCancelPopup}
        Title={popupTitle}
        Content={popupContent}
        confirmLabel={popupConfirmLabel}
        cancelLabel={popupCancelLabel}
      />
      <div>{Snackbar}</div>
    </div>
  );
};
