import React, { FC } from 'react';
import {
  Box,
  Chip,
  Grid,
} from '@mui/material';


type Props = React.HTMLAttributes<HTMLLIElement> & {
  item: any;
};

export const SelectOptionChip: FC<Props> = ({ item, ...props }) => {

  const label = item?.control_id || item?.category || item?.type;
  const title = item?.title;

  return (
    <Box component="li" {...props}>
      <Grid container spacing={1}>
        <Grid item>
          {title}
        </Grid>
        <Box ml="auto"/>
        <Grid item>
          <Chip
            size="small"
            label={(label.charAt(0).toUpperCase() + label.slice(1)).replace('-', ' ').replace('_', ' ')}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
