import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { tasksClient } from 'clients/tasks/tasksClient';

export type Params = {
  id: string;
};

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useTask = (params: Params, options: Partial<Options> = defaultOptions) => {
  const { data: { data: task } = {}, status, error } = useQuery(
    [cacheKeys.tasks.getTask, params],
    () => tasksClient.getTask(params.id),
    {
      enabled: options.enabled,
    },
  );

  return {
    status,
    error,
    task,
  };
};
