import React from 'react';
import { Typography, Link } from '@mui/material';

export const Footer = () => {

  return (
    <Typography color="secondary" align="center">
      <Link href="https://www.naala.io/terms/" target="_blank" rel="noreferrer noopener" color="inherit">Terms and Conditions</Link><br/>
      <Link href="https://www.naala.io/privacy-policy/" target="_blank" rel="noreferrer noopener" color="inherit">Privacy Policy</Link><br/>
      <Link href="https://www.naala.io/cookie-policy/" target="_blank" rel="noreferrer noopener" color="inherit">Cookie Policy</Link><br/>
    </Typography>
  );
};
