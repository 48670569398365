import * as locales from '@mui/material/locale';
import { createTheme, ThemeOptions, responsiveFontSizes } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { deepmerge } from '@mui/utils';
import { common, grey } from '@mui/material/colors';

import bg from 'shared/img/bg.svg';
import bgFlipped from 'shared/img/bg_flipped.svg';

declare module '@mui/material' {
  interface PaperPropsVariantOverrides {
    contained: true;
  }
  interface ButtonPropsVariantOverrides {
    dashed: true;
  }
}

export const primary = '#10285f';
export const primaryLight = '#f7dfff';
export const primaryDark = '#10285f';
export const secondary = '#10285f';
export const textColor = '#10285f';
const primaryLinearGradient = 'linear-gradient(45deg, #fafafa 0%, #ececec 100%)';

const primaryBackgroundCss = {
  backgroundColor: primaryDark,
  background: `url(${bg}), ${primaryLinearGradient}`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
};

const primaryBackgroundCssFlipped = {
  ...primaryBackgroundCss,
  background: `url(${bgFlipped}), ${primaryLinearGradient}`,
};

export const defaultTheme = responsiveFontSizes(createTheme({}));

export const baseTheme: ThemeOptions = {
  typography: {
    fontSize: 12,
    fontFamily: 'Poppins',
    fontWeightLight: 200,
    fontWeightRegular: 300,
    fontWeightBold: 500,
    ...['h1','h2'].reduce((prev, next) => {
      prev[next] = { fontWeight: 200, color: secondary };
      return prev;
    }, {} as any),
    ...['h3','h4','h5','h6'].reduce((prev, next) => {
      prev[next] = { fontWeight: 200, color: secondary };
      return prev;
    }, {} as any),
    ...['body1', 'body2', 'subtitle1', 'subtitle2', 'default', 'button', 'overline'].reduce((prev, next) => {
      prev[next] = { fontWeight: 300, color: textColor };
      return prev;
    }, {} as any),
  },
  palette: {
    mode: 'light',
    primary: {
      main: primary,
      light: primaryLight,
      dark: primaryDark,
      contrastText: primaryLight,
    },
    secondary: {
      main: secondary,
      light: secondary,
      dark: secondary,
      contrastText: primaryLight,
    },
    warning: {
      light: '#ffa900',
      main: '#ffa900',
      dark: '#ffa900',
      contrastText: '#000',
    },
    error: {
      light: '#FF2600',
      main: '#FF2600',
      dark: '#FF2600',
      contrastText: '#000',
    },
    background: {
      default: 'rgba(0,0,0,0)',
      paper: '#eeeeee',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: primaryBackgroundCss,
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: secondary,
          fill: secondary,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: primary,
          color: `${primaryLight} !important`,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          minHeight: 'calc(100vh - 65px)',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
          '& fieldset': {
            borderRadius: 0,
          },
          '& label.Mui-focused ': {
            color: `${secondary} !important`,
          },
          '& .Mui-focused fieldset': {
            borderColor: `${secondary} !important`,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 9,
        },
        thumb: {
          width: 16,
          height: 16,
        },
        switchBase: {
          padding: 11,
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-checked': {
            color: common.white,
          },
        },
        track: {
          backgroundColor: grey['400'],
          borderRadius: 10,
          opacity: 1,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            minHeight: 100,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          ...primaryBackgroundCssFlipped,
          border: 0,
          // background: primaryLinearGradient,
          // boxShadow: `0 0 30px 0 ${primaryLight}80`,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        variant: 'outlined',
        square: true,
      },
      styleOverrides: {
        root: {
          background: primaryLinearGradient,
          border: '1px solid #ececec',
        },
        elevation5: {
          boxShadow: '0 0 100px 0 rgba(0,0,0,0.2)',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: 0,
          background: primaryLinearGradient,
          color: secondary,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 0 30px 0 rgba(0,0,0,0.05)',
          borderRadius: 12,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: false,
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 0,
        },
        containedPrimary: {
          '& .MuiSvgIcon-root': {
            color: primaryLight,
            fill: primaryLight,
          },
        },
      },
      variants: [
        {
          props: { variant: 'dashed' },
          style: {
            border: '1px dashed #C4C4C4',
          },
        },
      ],
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(1),
          color: secondary,
          fontWeight: 500,
        },
        body: {
          paddingTop: defaultTheme.spacing(3),
          paddingBottom: defaultTheme.spacing(3),
        },
        root: {
          '&:first-child': {
            paddingLeft: defaultTheme.spacing(4),
          },
          '&:last-child': {
            paddingRight: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        columnHeaders: {
          borderBottom: 'none',
          borderRadius: defaultTheme.shape.borderRadius,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(4),
            paddingRight: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(4),
            paddingRight: defaultTheme.spacing(4),
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          [defaultTheme.breakpoints.up('sm')]: {
            paddingLeft: defaultTheme.spacing(3),
            paddingRight: defaultTheme.spacing(3),
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: primaryLinearGradient,
          color: primary,
          fontSize: 14,
          fontWeight: 300,
          padding: defaultTheme.spacing(2),
          boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          // background: primaryDark,
          padding: defaultTheme.spacing(1),
          paddingRight: defaultTheme.spacing(0.5),
          paddingLeft: defaultTheme.spacing(0.5),
          fontWeight: 500,
          '& svg': {
            filter: 'none',
            color: secondary,
            fill: secondary,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        outlinedInfo: {
          borderColor: `${primary}50`,
        },
        outlinedError: {
          borderColor: '#FF2600',
          color: primary,
          '& svg': {
            color: '#FF2600 !important',
            fill: '#FF2600 !important',
          },
        },
        outlinedWarning: {
          borderColor: '#ffa900',
          color: primary,
          '& svg': {
            color: '#ffa900 !important',
            fill: '#ffa900 !important',
          },
        },
      },
    },
  },
};

export const lightTheme = (locale = 'enUS') => responsiveFontSizes(createTheme(deepmerge(baseTheme, {}),
// @ts-ignore
  locales[locale]));

export const darkTheme = (locale = 'enUS') => responsiveFontSizes(createTheme(deepmerge(baseTheme, {}),
// @ts-ignore
  locales[locale]));

export const muiTheme = (mode: 'light' | 'dark', locale = 'enUS') => mode === 'dark' ? lightTheme(locale) : darkTheme(locale);
