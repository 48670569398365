import React, { FC, createContext, useContext, useState, useCallback, useMemo } from 'react';

import { LocalizationContext } from 'contexts';
import { useQueryState } from 'shared/hooks/useQueryState';
import { Authenticated } from 'shared/components/Authenticated/Authenticated';
import { AsyncConfirmationDialog } from 'shared/components/dialog/AsyncConfirmationDialog/AsyncConfirmationDialog';
import { InviteTeamMemberDialog } from 'shared/components/dialog/InviteTeamMember/InviteTeamMemberDialog';
import { CompanyNameEditDialog } from 'shared/components/dialog/CompanyNameEditDialog/CompanyNameEditDialog';

import { CreateDocumentDialog } from 'shared/components/dialog/CreateDocumentDialog/CreateDocumentDialog';
import { CreateSubDocumentDialog } from 'shared/components/dialog/CreateDocumentDialog/CreateSubDocumentDialog';

import { ContextProps, Dialog, AsyncDialogProps, AsyncConfirmation } from './types';

export const defaultContext: ContextProps = {
  openDialog: () => {},
  closeDialog: () => {},
  asyncConfirmation: () => Promise.resolve(false),
};

const defaultAsyncConfirmationDialog = {
  title: null,
  content: null,
  confirmLabel: null,
  cancelLabel: null,
  show: false,
};

export const DialogContext = createContext(defaultContext);
export const DialogContextProvider: FC = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [queryDialogs = [], setDialogs] = useQueryState<Array<Dialog>>('dialog');

  const dialogs: Array<Dialog> = useMemo(() => {
    return typeof queryDialogs === 'string' ? [queryDialogs] : queryDialogs;
  }, [queryDialogs]);

  const openDialogs: Record<string, boolean> = useMemo(() => {
    return Object.values(Dialog)
      .filter(k => typeof k === 'string')
      .reduce((prev, curr) => {
        return {
          ...prev,
          [curr]: dialogs.includes(curr as Dialog),
        };
      }, {});
  }, [dialogs]);

  const openDialog = useCallback((dialog: Dialog) => {
    setDialogs([...dialogs, dialog]);
  }, [dialogs, setDialogs]);

  const closeDialog = useCallback((dialog: Dialog) => {
    setDialogs(dialogs.filter(d => d !== dialog));
  }, [dialogs, setDialogs]);

  const [popup, setPopup] = useState<AsyncDialogProps>(defaultAsyncConfirmationDialog);
  const [onConfirmPopup, setOnConfirmPopup] = useState<any>(() => null);
  const [onCancelPopup, setOnCancelPopup] = useState<any>(() => null);
  const [onClosePopup, setOnClosePopup] = useState<any>(() => null);

  const asyncConfirmation: AsyncConfirmation = useCallback(({ title, content, confirmLabel, cancelLabel }) => {
    setPopup({
      show: true,
      title: title || dictionary.confirmation.defaultTitle,
      content: content || dictionary.confirmation.defaultContent,
      confirmLabel: confirmLabel || dictionary.confirmation.yes,
      cancelLabel: cancelLabel || dictionary.confirmation.no,
    });
    return new Promise((resolve) => {
      setOnConfirmPopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(true)]);
      setOnCancelPopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(false)]);
      setOnClosePopup(() => () => [setPopup(defaultAsyncConfirmationDialog), resolve(false)]);
    });
  }, [dictionary]);

  return (
    <DialogContext.Provider
      value={{
        openDialog,
        closeDialog,
        asyncConfirmation,
      }}
    >
      {children}

      <Authenticated>
        <InviteTeamMemberDialog open={openDialogs[Dialog.InviteTeamMember]} onClose={() => closeDialog(Dialog.InviteTeamMember)} />
        <CompanyNameEditDialog open={openDialogs[Dialog.EditCompanyName]} onClose={() => closeDialog(Dialog.EditCompanyName)} />
        <CreateDocumentDialog open={openDialogs[Dialog.CreateDocument]} onClose={() => closeDialog(Dialog.CreateDocument)} />
        <CreateSubDocumentDialog open={openDialogs[Dialog.CreateSubDocument]} onClose={() => closeDialog(Dialog.CreateSubDocument)} />
      </Authenticated>

      <AsyncConfirmationDialog
        open={popup.show}
        onClose={onClosePopup}
        onConfirm={onConfirmPopup}
        onCancel={onCancelPopup}
        title={popup.title}
        content={popup.content}
        confirmLabel={popup.confirmLabel}
        cancelLabel={popup.cancelLabel}
      />
    </DialogContext.Provider>
  );
};
