import React, { FC, useCallback, useContext } from 'react';
import {
  Box,
  Grid,
  Paper,
} from '@mui/material';
import { isArray, isEmpty } from 'lodash';

import { LocalizationContext } from 'contexts';
import { DashboardCardTitle } from './DashboardCardTitle';
import { InnerGrid } from './DashboardCardInnerGrid';


interface Props {
    dashboardElements: { title: string, elements: any[] }[];
}

export const DashboardListElement: FC<Props> = ({ dashboardElements }) => {
  const { dictionary } = useContext(LocalizationContext);
  const getNumberOfElements = useCallback((elements: any[]) => {
    if (isArray(elements?.[0])) {
      return elements?.[0].length + elements?.[1].length;
    } else if (!isEmpty(elements)) {
      return elements.length;
    }

    return 0;
  }, []);
  
  return (
    <Grid container direction="row" display="flex" alignItems="center" justifyContent="center" columnSpacing={2} rowSpacing={1}>
      {dashboardElements.map((element: {title: string, elements: any}, index) => (
        <Grid item key={element.title} xs={12} lg={4}>
          <Box height="100%" pt={3} pl={{ xs: 0, sm: 1 }} pr={{ xs: 0, sm: 1 }} component={Paper} sx={{ background: '#f7f7f7' }}>
            <DashboardCardTitle
              title={element.title}
              numberOfElements={getNumberOfElements(element.elements)}
            />
            <Grid
              container
              direction="column"
              wrap="nowrap"
              sx={{ height: '40vh', overflow: 'auto' }}
              pl={{ xs: 1, sm: 2 }}
              pr={{ xs: 1, sm: 2 }}
              mt={2}
            >
              {isArray(element.elements?.[0]) ?
                <>
                  <InnerGrid
                    innerGridTitle={dictionary.dashboard.tasksHighPriority}
                    elements={element.elements?.[0]}
                    color="error"
                    mainTitle={element.title}
                  />
                  <InnerGrid
                    innerGridTitle={dictionary.dashboard.tasksRequiringAction}
                    elements={element.elements?.[1]}
                    color="warning.main"
                    mainTitle={element.title}
                  />
                </>
                :
                <InnerGrid elements={element.elements} mainTitle={element.title}/>
              }
            </Grid>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
