import React, { FC } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';

import { Dropdown, DropdownProps } from './Dropdown';


export type ButtonDropdownProps = Omit<DropdownProps, 'children'> & {
  buttonProps?: IconButtonProps;
}

export const IconButtonDropdown: FC<ButtonDropdownProps> = ({ children, content, placement, buttonProps }) => (
  <Dropdown
    content={content}
    placement={placement}
  >
    {({ ref, handleToggleDropdownMenu }) => (
      <IconButton
        {...buttonProps}
        ref={ref}
        aria-label="options menu"
        aria-haspopup="menu"
        onClick={handleToggleDropdownMenu}
      >
        {children}
      </IconButton>
    )}
  </Dropdown>
);
