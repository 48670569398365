import React, { FC, useContext, useCallback, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { cacheKeys, routes } from 'config';
import { LocalizationContext, LayoutContext } from 'contexts';
import { getDocumentCategoryLabel } from 'helpers/documents';
import { documentsClient } from 'clients/documents/documentsClient';
import { DocumentType } from 'clients/documents/documentsClient.types';

export type Form = {
  title: string;
}

type Props = {
  open: boolean;
  onClose: () => void;
}

export const CreateDocumentDialog: FC<Props> = ({ open, onClose }) => {

  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const { handleSubmit, control, formState: { isSubmitting, errors }, reset } = useForm();
  const navigate = useNavigate();

  const createDocument = useMutation(documentsClient.createDocument, {
    mutationKey: cacheKeys.documents.createDocument,
    onSuccess: (data: any) => {
      genericFeedback(dictionary.documents.create.documentCreated);
      queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      onClose();
      navigate(routes.editDocument(data.data.id));
    },
    onError: () => {
      genericError();
    },
  });

  useEffect(() => {
    reset({
      title: '',
    });
  }, [open, reset]);

  const documentType = localStorage.getItem('createDocumentType');

  const onSubmit = useCallback(async (data: Form) => createDocument.mutateAsync({
    title: data.title,
    category: documentType,
  }), [createDocument, documentType]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {dictionary.documents.create.createDocumentTitle(getDocumentCategoryLabel(dictionary, documentType as DocumentType))}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" mb="30px">{dictionary.documents.create.createDocumentDescription(getDocumentCategoryLabel(dictionary, documentType as DocumentType))}</Typography>
          <Controller
            name="title"
            control={control}
            rules={{
              required: dictionary.forms.validations.required,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={dictionary.forms.titleLabel}
                autoFocus
                size="small"
                error={!!errors.title}
                helperText={errors.title && errors.title.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">{dictionary.forms.save}</LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
