import React, { FC, useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useRiskControls } from 'shared/hooks/useRiskControls';

export const RiskControlAutocomplete: FC<any> = ({ label, TextFieldProps, ...props }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { riskControls } = useRiskControls();

  return (
    <Autocomplete
      options={riskControls}
      renderInput={props => (
        <TextField
          {...props}
          label={label || dictionary.riskControls.form.fieldRisks}
          InputLabelProps={{ shrink: true }}
          {...TextFieldProps}
        />
      )}
      getOptionLabel={(riskControl: any) => riskControl.control?.title}
      {...props}
      onChange={(e, value) => props.onChange(value)}
    />
  );
};
