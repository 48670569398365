import React, { useCallback, useContext } from 'react';
import { useMatch } from 'react-router';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Avatar, Box, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { documentCommentsClient } from 'clients/documentComments/documentCommentsClient';
import { useMe } from 'shared/hooks/useMe';

export const AddCommentForm = () => {
  const { user } = useMe();
  const { dictionary } = useContext(LocalizationContext);
  const queryClient = useQueryClient();
  const { genericError } = useContext(LayoutContext);
  const matchingRoute = useMatch(routes.viewDocument());

  const defaultValues = {
    text: '',
    page: matchingRoute?.params?.documentId,
  };

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit, control, watch, reset, formState: { isSubmitting, errors } } = methods;

  const createComment = useMutation(documentCommentsClient.createComment, {
    mutationKey: cacheKeys.documentComments.createComment,
    onSuccess: (data: any) => {
      queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
      queryClient.invalidateQueries(cacheKeys.documents.getDocument);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      queryClient.invalidateQueries([cacheKeys.documents.getDocument, {
        id: matchingRoute?.params?.documentId,
      }]);
      reset(defaultValues);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (data: any) => createComment.mutateAsync({
    text: data.text,
    page: data.page,
  }), [createComment]);

  const text = watch('text');

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Avatar
          sx={{
            marginRight: '10px',
            width: 20,
            height: 20,
            fontSize: 12,
            fontWeight: 500,
            '.MuiAvatar-fallback': { color: '#ececec', fill: '#ececec' },
          }}
          alt={user?.displayName}
          src={user?.profilePicture}
        />
        <Typography variant="body2" fontWeight={500}>{user?.displayName.trim() || ''}</Typography>
      </Box>
      <Box mt="20px">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="text"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Add Comment"
                  size="small"
                  error={!!errors.text}
                  helperText={errors.text && errors.text.message}
                  multiline
                />
              )}
            />
            <LoadingButton
              variant="text"
              type="submit"
              size="small"
              loading={isSubmitting} 
              disabled={!(text || '').trim()}
              sx={{ mt: '5px' }}
            >
              {dictionary.save}
            </LoadingButton>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};
