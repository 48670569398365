import React, { useState, useCallback, forwardRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { Box, FormControl, InputLabel, FormHelperText } from '@mui/material';

import { MaybeNull } from 'types';
import { sanitizeHtml } from 'helpers/html';

import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['link'],
  [{ 'color': [] }, { 'background': [] }],
  ['clean'],
];

type Props = {
  id?: string;
  label?: string;
  value?: MaybeNull<string>;
  defaultValue?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  helperText?: string;
  error?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
  color?: 'primary' | 'secondary';
  sanitize?: (html: string) => string;
  toolbar?: any[];
  lines?: number;
  readOnly?: boolean;
}

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
var AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
var BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
var ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
var DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);
var FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
var SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);
var AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
var BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
var ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
var DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
var FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
var SizeStyle = Quill.import('attributors/style/size');
Quill.register(SizeStyle, true);

export const RichTextField = forwardRef<{}, Props>(({
  id,
  label,
  value,
  helperText,
  error,
  variant = 'outlined',
  color = 'primary',
  lines = 2,
  onChange,
  onBlur,
  sanitize = sanitizeHtml,
  toolbar = toolbarOptions,
  children,
  ...props
}, ref) => {

  const [focused, setFocused] = useState(false);

  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const onBlurCallback = useCallback(() => {
    setFocused(false);
    
    if (value) {
      if (onBlur) {
        onBlur(sanitize(value));
      } else if(onChange) {
        onChange(sanitize(value));
      }
    }
  }, [onBlur, onChange, sanitize, value]);

  return (
    <FormControl fullWidth variant={variant}>
      {label && (
        <InputLabel
          focused={focused}
          htmlFor={id}
          error={error}
          variant="standard"
          color={color}
        />
      )}
      <Box
        sx={theme => ({
          position: 'relative',
          backgroundColor: 'transparent',
          '& .quill': {
            display: 'flex',
            flexDirection: 'column',
            color: 'inherit',
          },
          '& .ql-editor': {
            fontSize: '0.875rem',
            lineHeight: '1.43',
            minHeight: (lines * 1.43 * theme.typography.fontSize) + 24,
          },
          '& .ql-toolbar.ql-snow': {
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
            borderColor: theme => theme.palette.mode === 'light' ? 'rgba(0,0,0,0.23)' : theme.palette.common.black,
          },
          '& .ql-container.ql-snow': {
            border: 'none',
          },
          '& .ql-snow .ql-picker, & .ql-snow .ql-stroke, & .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill': {
            color: 'inherit',
            stroke: theme.palette.mode === 'light' ? theme.palette.common.black : theme.palette.common.white,
          },
          [`& .ql-snow.ql-toolbar button:hover .ql-stroke,
          & .ql-snow .ql-toolbar button:hover .ql-stroke,
          & .ql-snow.ql-toolbar button:focus .ql-stroke,
          & .ql-snow .ql-toolbar button:focus .ql-stroke,
          & .ql-snow.ql-toolbar button.ql-active .ql-stroke,
          & .ql-snow .ql-toolbar button.ql-active .ql-stroke,
          & .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
          & .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
          & .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
          & .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
          & .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
          & .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
          & .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
          & .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
          & .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
          & .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
          & .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
          & .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
          & .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
          & .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
          & .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
          & .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
          & .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
          & .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
          & .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
          & .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
          & .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
          & .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter`]: {
            stroke: theme.palette.primary.main,
          },
          [`& .ql-snow.ql-toolbar button:hover,
          & .ql-snow .ql-toolbar button:hover,
          & .ql-snow.ql-toolbar button:focus,
          & .ql-snow .ql-toolbar button:focus,
          & .ql-snow.ql-toolbar button.ql-active,
          & .ql-snow .ql-toolbar button.ql-active,
          & .ql-snow.ql-toolbar .ql-picker-label:hover,
          & .ql-snow .ql-toolbar .ql-picker-label:hover,
          & .ql-snow.ql-toolbar .ql-picker-label.ql-active,
          & .ql-snow .ql-toolbar .ql-picker-label.ql-active,
          & .ql-snow.ql-toolbar .ql-picker-item:hover,
          & .ql-snow .ql-toolbar .ql-picker-item:hover,
          & .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
          & .ql-snow .ql-toolbar .ql-picker-item.ql-selected`]: {
            color: theme.palette.primary.main,
          },
          '& .ql-snow .ql-tooltip, & .ql-snow .ql-picker-options': {
            zIndex: 1,
          },
          '& .ql-toolbar.ql-snow .ql-header.ql-picker:hover .ql-picker-label, & .ql-toolbar.ql-snow .ql-header.ql-picker.ql-expanded .ql-picker-label': {
            borderColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.23)' : theme.palette.common.black,
            color: 'inherit',
          },
          '& .ql-snow .ql-picker-options': {
            backgroundColor: theme.palette.background.paper,
            border: 'none',
          },
          '& .ql-snow .ql-header.ql-picker .ql-picker-options': {
            padding: `${theme.spacing(1)}px 0`,
          },
          '& .ql-snow .ql-header.ql-picker .ql-picker-options .ql-picker-item': {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
          },
          '& .ql-snow .ql-picker-options .ql-picker-item:hover': {
            backgroundColor: theme.palette.action.hover,
            color: 'inherit',
          },
        })}
      >
        <ReactQuill
          {...props}
          theme="snow"
          id={id}
          value={value || ''}
          modules={{
            toolbar,
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlurCallback}
        />
        {label && (
          <Box
            component="fieldset"
            sx={theme => ({
              textAlign: 'left',
              position: 'absolute',
              bottom: 0,
              right: 0,
              top: -5,
              left: 0,
              margin: 0,
              padding: '0 8px',
              pointerEvents: 'none',
              borderStyle: 'solid',
              fontSize: '1rem',
              overflow: 'hidden',
              minWidth: '0%',
              ...(focused ? {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
              } : {
                borderColor: theme.palette.mode === 'light' ? 'rgba(0,0,0,0.23)' : theme.palette.common.black,
                borderWidth: 1,
              }),
            })}
            aria-hidden
          >
            <Box
              component="legend"
              sx={{
                float: 'unset',
                display: 'block',
                width: 'auto',
                padding: 0,
                height: 11,
                fontSize: '0.75em',
                visibility: 'hidden',
                maxWidth: variant === 'outlined' ? '0.01px' : '100%',
              }}
            >
              <Box
                component="span"
                sx={{
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  display: 'inline-block',
                }}
              >
                {label}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {helperText && (
        <FormHelperText
          error={error}
          variant={variant}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
});
