import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';

const ControlsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getControls = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${ControlsApiBaseUrl}/controls/`,
      method: 'GET',
      params: {
        search: params.search,
        limit: params.limit || DEFAULT_QUERY_LIMIT,
      },
    },
  });
};

const getControl = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${ControlsApiBaseUrl}/controls/${id}/`,
      method: 'GET',
    },
  });
};

const createControl = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${ControlsApiBaseUrl}/controls/`,
      method: 'POST',
      data: data,
    },
  });
};

const editControl = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${ControlsApiBaseUrl}/controls/${data.id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchControl = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${ControlsApiBaseUrl}/controls/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteControl = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${ControlsApiBaseUrl}/controls/${id}/`,
      method: 'DELETE',
    },
  });
};

export const controlsClient = {
  createControl,
  deleteControl,
  editControl,
  patchControl,
  getControls,
  getControl,
};
