import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';
import { mapComment } from './documentCommentsClient.formatter';


const DocumentsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getComments = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${params.page}/comment/`,
      method: 'GET',
      params: {
        limit: params.limit || DEFAULT_QUERY_LIMIT,
      },
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapComment),
    },
  }));;
};

const getComment = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${params.page}/comment/${params.commentId}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: mapComment(data.data),
  }));;
};

const createComment = (data: any): Promise<AxiosResponse> => {
  const { page, ...payload } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${page}/comment/`,
      method: 'POST',
      data: payload,
    },
  });
};

const editComment = (data: any): Promise<AxiosResponse> => {
  const { page, commentId, ...payload } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${page}/comment/${commentId}/`,
      method: 'PUT',
      data: payload,
    },
  });
};

const patchComment = (data: any): Promise<AxiosResponse> => {
  const { page, commentId, ...payload } = data;
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${page}/comment/${commentId}/`,
      method: 'PATCH',
      data: payload,
    },
  });
};

const deleteComment = (data: any ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${data.page}/comment/${data.commentId}/`,
      method: 'DELETE',
    },
  });
};

export const documentCommentsClient = {
  createComment,
  deleteComment,
  editComment,
  patchComment,
  getComments,
  getComment,
};
