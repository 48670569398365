import React, { Fragment, FC, ReactElement, useContext } from 'react';
import {
  Box,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';

export type Filters = {
  search?: string;
}

type Props = {
  children(props: {
    filters: Filters,
  }): ReactElement;
}

export const CompaniesFilters: FC<Props> = ({ children }) => {

  const { dictionary } = useContext(LocalizationContext);

  const [newSearch, currSearch, setSearch] = useDebouncedQueryState('search');

  return (
    <Fragment>
      <Grid item xs={12} sm={4} lg={3} >
        <Box component={Paper} p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">{dictionary.filters}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                size="small"
                style={{ minWidth: 200 }}
                label={dictionary.search}
                value={newSearch || null}
                onChange={({ target: { value } }) => setSearch(value.trim() === '' ? undefined : value)}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8} lg={9}>
        {children({
          filters: {
            search: currSearch,
          },
        })}
      </Grid>
    </Fragment>
  );
};
