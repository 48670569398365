import React, { FC, createContext, useState, useEffect, useCallback } from 'react';
import enLocale from 'date-fns/locale/en-GB';
import deLocale from 'date-fns/locale/de';

import { dictionary as en } from './languages/en';
import { dictionary as de } from './languages/de';
import { ContextProps, SupportedLanguage } from './types';

//@ts-ignore
const browserLocale = window?.navigator?.userLanguage || window?.navigator?.language;

const defaultContext: ContextProps = {
  language: browserLocale && browserLocale.slice(0,2) === 'de' ? 'de' : 'en',
  dictionary: en,
  setLanguage: () => null,
  locale: enLocale,
  localizePrice: () => '',
};


const getLanguageFromLocalStorage = (): SupportedLanguage => {
  const lang = localStorage.getItem('nl-language') as SupportedLanguage || defaultContext.language;
  if(['de', 'en'].includes(lang)) {
    return lang;
  }
  return 'en';
};


export const LocalizationContext = createContext(defaultContext);


export const LocalizationContextProvider: FC = ({ children }) => {

  const [language, setLanguage] = useState<SupportedLanguage>(getLanguageFromLocalStorage());
  const dictionaries = { en, de };
  const dictionary = dictionaries[language as SupportedLanguage];

  const localizePrice = useCallback((price: number | string) => {
    if(!price) {
      return price;
    }
    const stringPrice = typeof price === 'string' ? parseFloat(price) : price;
    switch(language) {
    case 'de':
      return stringPrice.toFixed(2).replace('.', ',');
    default:
      return stringPrice.toFixed(2);
    }
  }, [language]);


  useEffect(() => {
    localStorage.setItem('nl-language',language);
  },[language]);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        dictionary,
        setLanguage,
        localizePrice,
        locale: language === 'en' ? enLocale : deLocale,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};

