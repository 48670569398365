import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { risksClient } from 'clients/risks/risksClient';

type Options = {
    enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useRisks = (params?: any, options: Partial<Options> = defaultOptions) => {

  const { data: { data: { results: risks = [], count = undefined } = {} } = {}, status, error } = useQuery(
    [cacheKeys.risks.getRisks, params],
    () => risksClient.getRisks(params),
    { enabled: options.enabled },
  );

  return {
    status,
    error,
    risks,
    count,
  };
};
