import React, { FC, Fragment, useContext } from 'react';
import { Button, Card, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { LocalizationContext } from 'contexts';

export const ConfirmationPrompt: FC<any> = ({
  entityName,
  open = false,
  setOpen = () => null,
  onConfirm = () => null,
  onCancel = () => null,
  Title = <Fragment>Are you sure you want to remove this {entityName}?</Fragment>,
  Content = <Fragment>This action is irreversible.</Fragment>,
  confirmLabel,
  cancelLabel,
}) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Dialog open={open} onClose={() => [setOpen(false), onCancel()]}>
      <Card>
        <DialogContent>
          <Typography align="center" gutterBottom>
            {Title}
          </Typography>
          <Typography align="center" gutterBottom color="error">
            {Content}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button type="button" variant="contained" color="primary" onClick={() => [setOpen(false), onCancel()]}>{cancelLabel || dictionary.no}</Button>
            </Grid>
            <Grid item>
              <Button type="button" variant="contained" color="secondary" onClick={() => [onConfirm()]}>{confirmLabel || dictionary.yes}</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

