import { getFormDataFromObject } from 'helpers/form';
import { mapCompanyData } from 'clients/companies/companyClient.formatter';
import {
  User,
  UserApi,
  UserCrud,
  UserSignupWithEmailAndPassword,
  UserSignupWithEmailAndPasswordApi,
  SimpleUser,
  SimpleUserApi,
  UserCrudApi,
  UserInvite,
  UserInviteApi,
  UserType,
} from './userClient.types';

export const mapSimpleUserData = (data: SimpleUserApi): SimpleUser => ({
  id: data.id,
  displayName: `${data.first_name} ${data.last_name}`,
  firstName: data.first_name,
  lastName: data.last_name,
  email: data.email,
  jobTitle: data.job_title,
  type: data.type || UserType.Admin,
  profilePicture: data.profile_picture || undefined,
});

export const mapUserData = (data: UserApi): User => ({
  id: data.id,
  firebaseUid: data.firebase_uid,
  displayName: `${data.first_name} ${data.last_name}`,
  firstName: data.first_name,
  lastName: data.last_name,
  email: data.email,
  phone: data.phone,
  company: data.company ? mapCompanyData(data.company) : null,
  jobTitle: data.job_title,
  type: data.type || UserType.Admin,
  profilePicture: data.profile_picture || undefined,
});

export const mapSignUpData = (data: UserSignupWithEmailAndPassword): UserSignupWithEmailAndPasswordApi => ({
  company: data.company || undefined,
  email: data.email,
  first_name: data.firstName,
  job_title: data.jobTitle || undefined,
  last_name: data.lastName,
  password: data.password,
  repeated_password: data.repeatPassword,
  user_invitation_id: data.userInvitationId || undefined,
});

export const mapUserCrudData = (data: UserCrud): FormData => {
  const apiData: UserCrudApi = {
    first_name: data.firstName,
    last_name: data.lastName,
    email: data.email,
    job_title: data.jobTitle || undefined,
    company_id: data.company?.id,
    type: data.type,
    profile_picture: typeof data.profilePicture === 'string' ? undefined : data.profilePicture,
  };

  const formData = getFormDataFromObject(apiData);
  
  return formData;
};

export const mapInviteData = (data: UserInviteApi): UserInvite => ({
  id: data.id,
  email: data.email,
  company: data.company ? mapCompanyData(data.company) : undefined,
});

export const mapMinimalUserData = (data: any) => ({
  ...data,
  displayName: `${data.first_name} ${data.last_name}`.trim(),
});
