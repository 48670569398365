import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { riskControlsClient } from 'clients/riskControls/riskControlsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useRiskControls = (params?: any, options: Partial<Options> = defaultOptions) => {

  const { data: { data: { results: riskControls = [], count = undefined } = {} } = {}, status: queryStatus, error } = useQuery(
    [cacheKeys.riskControls.getRiskControls, params],
    () => riskControlsClient.getRiskControls(params),
    { enabled: options.enabled },
  );

  return {
    riskControls,
    count,
    status: queryStatus,
    error,
  };
};
