import { mapMinimalUserData } from 'clients/users/userClient.formatter';

export const mapActivityLogs = (data: any) => ({
  ...data,
  user: data.user ? mapMinimalUserData(data.user) : null,
  created_at: new Date(data.created_at),
});

export const mapReportData = (data: any) => ({
  ...data,
  activity_logs: data.activity_logs?.map(mapActivityLogs) || [],
});
