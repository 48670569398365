import React, { FC, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

import { LocalizationContext } from 'contexts';

type Props = {
    isOpen: boolean;
    onClick: () => void;
};

export const AddRiskControlTab: FC<Props> = ({ isOpen, onClick }) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <Box
      sx={{
        border: 'solid 1px rgba(0, 0, 0, 0.23)',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        borderBottom: isOpen ? 'none' : undefined,
        background: isOpen ? 'white' : undefined,
        height: '100%',
        padding: 2,
      }}
      onClick={onClick}
    >
      <AddCircleOutline/>
      <Typography ml={1}>{dictionary.risks.form.fieldAddNewRiskControl}</Typography>
      {isOpen && (
        <Box 
          sx={{
            position: 'absolute',
            width: 'calc(100% + 2px)',
            height: '18px',
            left: '-1px',
            bottom: '-17px',
            background: 'white',
            border: 'solid 1px rgba(0, 0, 0, 0.23)',
            borderTop: 'none', 
            borderBottom: 'none', 
          }}
        />
      )}
    </Box>
  );
};
