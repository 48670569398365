import React, { FC, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { LocalizationContext } from 'contexts';
import { emailRegex, passwordRegex } from 'helpers/validation';
import { UserSignupWithEmailAndPassword } from 'clients/users/userClient.types';

interface Props {
    defaultValues?: Partial<UserSignupWithEmailAndPassword>;
    onSubmitRequest: (values: UserSignupWithEmailAndPassword) => void;
    onSubmitButtonText: string;
    disabledFields?: Array<keyof UserSignupWithEmailAndPassword>;
}

const DEFAULT_VALUES: Partial<UserSignupWithEmailAndPassword> = {
  firstName: '',
  lastName: '',
  company: null,
  jobTitle: '',
  email: '',
  userInvitationId: null,
};

export const UserSignupForm: FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText, disabledFields }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { handleSubmit, control, formState: { isSubmitting, errors }, watch } = useForm<UserSignupWithEmailAndPassword>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const password = watch('password');

  const isFieldEnabled = useCallback((field: keyof UserSignupWithEmailAndPassword) => {
    if (!disabledFields) {
      return true;
    }
    return disabledFields.indexOf(field) === -1;
  }, [disabledFields]);

  const onSubmit = useCallback((data: UserSignupWithEmailAndPassword) => {
    return onSubmitRequest(data);
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} style={{ maxWidth: 500 }}>
        {isFieldEnabled('firstName') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.user.fieldFirstName}
                  name="firstName"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('lastName') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.user.fieldLastName}
                  name="lastName"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('company') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="company"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.fieldCompany}
                  name="company"
                  error={!!errors.company}
                  helperText={errors.company?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('jobTitle') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="jobTitle"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.user.fieldJobTitle}
                  name="jobTitle"
                  error={!!errors.jobTitle}
                  helperText={errors.jobTitle?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('email') && (
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                pattern: {
                  value: emailRegex,
                  message: dictionary.forms.validations.invalidEmail,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.fieldEmail}
                  name="email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('password') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                minLength: {
                  value: 6,
                  message: dictionary.forms.validations.minLength(6),
                },
                maxLength: {
                  value: 20,
                  message: dictionary.forms.validations.maxLength(20),
                },
                pattern: {
                  value: passwordRegex,
                  message: dictionary.forms.validations.invalidPassword,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.fieldPassword}
                  name="password"
                  type="password"
                  error={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('repeatPassword') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="repeatPassword"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                validate: repeatPassword => repeatPassword === password || dictionary.passwordsDoNotMatch,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  label={dictionary.forms.signup.fieldRepeatPassword}
                  name="repeatPassword"
                  type="password"
                  error={!!errors.repeatPassword}
                  helperText={errors.repeatPassword?.message}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitting}
            loadingPosition="end"
          >
            {onSubmitButtonText}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
