
export const cacheKeys = {
  companies: {
    getCompanies: 'getCompanies',
    getCompany: 'getCompany',
    createCompany: 'createCompany',
    editCompany: 'editCompany',
    deleteCompany: 'deleteCompany',
  },
  users: {
    createUser: 'createUser',
    deleteUser: 'deleteUser',
    editUser: 'editUser',
    getInvitation: 'getInvitation',
    getMe: 'getMe',
    getUser: 'getUser',
    getUsers: 'getUsers',
    inviteUser: 'inviteUser',
  },
  tasks: {
    getTask: 'getTask',
    createTask: 'createTask',
    editTask: 'editTask',
    getTasks: 'getTasks',
    getDropdownResults: 'getDropdownResults',
  },
  events: {
    getEvent: 'getEvent',
    editEvent: 'editEvent',
    createEvent: 'createEvent',
    getEvents: 'getEvents',
  },
  risks: {
    getRisk: 'getRisk',
    editRisk: 'editRisk',
    deleteRisk: 'deleteRisk',
    createRisk: 'createRisk',
    getRisks: 'getRisks',
  },
  controls: {
    getControl: 'getControl',
    createControl: 'createControl',
    editControl: 'editControl',
    deleteControl: 'deleteControl',
    getControls: 'getControls',
  },
  riskControls: {
    createRiskControl: 'createRiskControl',
    editRiskControl: 'editRiskControl',
    getRiskControl: 'getRiskControl',
    getRiskControls: 'getRiskControls',
  },
  reports: {
    createReport: 'createReport',
    editReport: 'editReport',
    getReports: 'getReports',
    getReport: 'getReport',
    getReportTemplates: 'getReportTemplates',
    getReportTemplate: 'getReportTemplate',
    getQuestion: 'getQuestion',
    createAnswer: 'createAnswer',
    getAnswer: 'getAnswer',
    editAnswer: 'editAnswer',
    patchAnswer: 'patchAnswer',
    deleteAnswer: 'deleteAnswer',
  },
  documents: {
    getDocument: 'getDocument',
    editDocument: 'editDocument',
    createDocument: 'createDocument',
    deleteDocument: 'deleteDocument',
    getDocuments: 'getDocuments',
  },
  documentComments: {
    getComment: 'getComment',
    editComment: 'editComment',
    createComment: 'createComment',
    getComments: 'getComments',
  },
  dashboard: {
    getDashboardMetadata: 'getDashboardMetadata',
  },
};

export const routes = {
  tasks: '/tasks',
  createTask: '/tasks/new',
  editTask: (id = ':id') => `/tasks/edit/${id}/`,
  faq: '/faq',
  tasksStatus: (status = ':status') => `/tasks/${status}/`,
  monitoring: '/monitoring',
  reports: '/reports',
  fulfillReport: (reportId: string | number = ':reportId') => `/report/${reportId}`,
  createReport: '/create-report',
  previewReportTemplate: (templateId: string | number = ':templateId') => `/preview-report/${templateId}`,
  riskControls: '/risk-controls',
  createRiskControl: '/risk-controls/new',
  editRiskControl: (riskControlId = ':riskControlId') => `/risk-controls/edit/${riskControlId}/`,
  risks: '/risks',
  createRisk: '/risks/new',
  editRisk: (riskId = ':riskId') => `/risks/edit/${riskId}/`,
  controls: '/controls',
  createControl: '/controls/new',
  editControl: (id = ':id') => `/controls/edit/${id}/`,
  incidents: '/incidents',
  fulfillIncident: (incidentId: string | number = ':incidentId') => `/incident/${incidentId}`,
  createIncident: '/create-incident',
  previewIncidentTemplate: (templateId: string | number = ':templateId') => `/preview-incident/${templateId}`,
  documents: '/documents',
  editDocument: (documentId: string | number = ':documentId') => `/documents/edit/${documentId}/`,
  viewDocument: (documentId: string | number = ':documentId') => `/documents/view/${documentId}/`,
  companies: '/companies',
  createCompany: '/create/company',
  createUser: '/create/user',
  editCompany: (companyId: string | number = ':companyId') => `/edit/company/${companyId}`,
  editUser: (userId: string | number = ':userId') => `/edit/user/${userId}`,
  home: '/',
  impersonateUser: '/impersonate',
  invite: '/invitation',
  login: '/login',
  myAccount: '/my-account',
  resetPassword: '/reset-password',
  signup: '/signup',
  nlSignup: '/nl-signup',
  team: '/team',
  users: '/users',
  welcome: '/welcome',
};


export type Routes = keyof typeof routes;

export const routeBreadcrumbs: Record<Routes, Routes[]> = {
  fulfillReport: ['home', 'reports'],
  createReport: ['home', 'reports'],
  previewReportTemplate: ['home', 'reports', 'previewReportTemplate'],
  risks: ['home', 'risks'],
  createRisk: ['risks', 'createRisk'],
  editRisk: ['risks', 'editRisk'],
  controls: ['home', 'controls'],
  createControl: ['controls', 'createControl'],
  editControl: ['controls', 'editControl'],
  riskControls: ['home', 'riskControls'],
  createRiskControl: ['home', 'riskControls'],
  editRiskControl: ['home', 'riskControls', 'editRiskControl'],
  createTask: ['tasks', 'createTask'],
  editTask: ['tasks', 'editTask'],
  tasksStatus: ['home', 'tasks', 'tasksStatus'],
  incidents: ['home', 'incidents'],
  fulfillIncident: ['home', 'incidents'],
  createIncident: ['home', 'incidents'],
  previewIncidentTemplate: ['home', 'incidents', 'previewIncidentTemplate'],
  documents: ['home', 'documents'],
  editDocument: ['home', 'documents', 'editDocument'],
  viewDocument: ['home', 'documents', 'viewDocument'],
  monitoring: ['home', 'monitoring'],
  faq: ['home', 'faq'],
  tasks: ['home', 'tasks'],
  reports: ['home', 'reports'],
  companies: ['home', 'companies'],
  createCompany: ['home', 'companies', 'createCompany'],
  createUser: ['home', 'users', 'createUser'],
  editCompany: ['home', 'companies', 'editCompany'],
  editUser: ['home', 'users', 'editUser'],
  home: ['home'],
  impersonateUser: ['impersonateUser'],
  invite: ['invite'],
  login: ['login'],
  myAccount: ['home', 'myAccount'],
  resetPassword: ['resetPassword'],
  signup: ['signup'],
  nlSignup: ['signup'],
  team: ['home', 'team'],
  users: ['home', 'users'],
  welcome: ['welcome'],
};

export const featureFlags = {
  microsoftSSO: false,
  googleSSO: false,
  signup: false,
  translations: false,
  team: true,
};
