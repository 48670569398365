import { AxiosResponse } from 'axios';
import { request } from 'clients/baseClient';

const DashboardApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getDashboardMetadata = (params: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DashboardApiBaseUrl}/dashboard/metadata/`,
      method: 'GET',
      params,
    },
  });
};

export const dashboardClient = {
  getDashboardMetadata,
};