import { formatISO } from 'date-fns';
import { Dictionary } from 'contexts/LocalizationContext/types';

export const formatToApi = (date: Date) => formatISO(date);

export const isValidDate = (date: Date) => date && !isNaN(date.getTime());

export const getFormattedDate = (date: Date, dictionary: Dictionary) => {
  const months = [
    dictionary.dashboard.months.january,
    dictionary.dashboard.months.february,
    dictionary.dashboard.months.march,
    dictionary.dashboard.months.april,
    dictionary.dashboard.months.may,
    dictionary.dashboard.months.june,
    dictionary.dashboard.months.july,
    dictionary.dashboard.months.august,
    dictionary.dashboard.months.september,
    dictionary.dashboard.months.october,
    dictionary.dashboard.months.november,
    dictionary.dashboard.months.december,
  ];
  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getFullYear();

  return dictionary.dashboard.updatedAt(day, month, year);
};
