import { Dictionary } from 'contexts/LocalizationContext/types';
import { DocumentType } from 'clients/documents/documentsClient.types';

export const getDocumentCategoryLabel = (dictionary: Dictionary, value: DocumentType) => {
  const MAP = {
    [DocumentType.Policy]: dictionary.documents.type.policy,
    [DocumentType.Report]: dictionary.documents.type.report,
    [DocumentType.Document]: dictionary.documents.type.document,
  };
  
  return MAP[value];
};
