import { Country, Language } from 'types/Country';
import { Dictionary } from 'contexts/LocalizationContext/types';

export const countries = Object.values(Country);
export const languages = Object.values(Language);
export const countryToFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char: string) =>
        String.fromCodePoint(char.charCodeAt(0) + 127397),
      )
    : isoCode;
};

export const getCountryLabel = (dictionary: Dictionary, country: Country) => {
  // @ts-ignore
  return dictionary.countries[country];
};

export const getLanguageLabel = (dictionary: Dictionary, language: Language) => {
  const labels = {
    [Language.English]: dictionary.languages.english,
    [Language.French]: dictionary.languages.french,
    [Language.German]: dictionary.languages.german,
  };

  return labels[language];
};
