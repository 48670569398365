import React, { FC } from 'react';
import { Grid } from '@mui/material';

import { useCompanies } from 'shared/hooks/useCompanies';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { CompanyCard } from 'views/LoggedIn/Companies/components/CompanyCard/CompanyCard';
import { Filters } from 'views/LoggedIn/Companies/partials/CompaniesFilters';

interface Props {
  filters: Filters;
}

export const CompaniesList: FC<Props> = ({ filters }) => {

  const { companies, status } = useCompanies({
    ...filters,
  });

  if (status === 'loading') {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Grid container spacing={2}>
      {companies.map(company => (
        <Grid key={company.id} item xs={12} sm={6} lg={4}>
          <CompanyCard {...company} />
        </Grid>
      ))}
    </Grid>
  );
};
