import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';
import { mapEventData } from './eventsClient.formatter';

const EventsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getEvents = (params: any): Promise<AxiosResponse> => {

  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/`,
      method: 'GET',
      params: {
        entry_date__gte: params?.gteDate,
        entry_date__lte: params?.lteDate,
        status: params?.status,
        classification: params?.classification,
        search: params?.search,
        limit: params?.limit || DEFAULT_QUERY_LIMIT,
      },
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapEventData),
    },
  }));
};

const getEvent = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/${id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: mapEventData(data.data),
  }));;
};

const createEvent = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/`,
      method: 'POST',
      data: data,
    },
  });
};

const editEvent = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/${data.id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchEvent = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteEvent = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/${id}/`,
      method: 'DELETE',
    },
  });
};

const getIncidentsFile = (): Promise<AxiosResponse<Blob>> => {
  return request({
    options: {
      url: `${EventsApiBaseUrl}/events/events_export/`,
      method: 'GET',
      responseType: 'blob',
    },
  });
};

export const eventsClient = {
  createEvent,
  deleteEvent,
  editEvent,
  patchEvent,
  getEvents,
  getEvent,
  getIncidentsFile,
};
