export enum RiskControlStatus {
  Effective = 'effective',
  NonEffective = 'non_effective',
  RequiresAction = 'requires_action',
  RequireAction = 'require_action',
};

export enum RiskControlInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Semester = 'semester',
  Year = 'year',
};
