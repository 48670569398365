import React, { FC, useContext, useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useForm, Controller } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';


import { cacheKeys } from 'config';
import { LocalizationContext, LayoutContext } from 'contexts';
import { isEmailValid } from 'helpers/validation';
import { usersClient } from 'clients/users/usersClient';

export type Form = {
  emails: string[];
}

type Props = {
  open: boolean;
  onClose: () => void;
}

export const InviteTeamMemberDialog: FC<Props> = ({ open, onClose }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const { handleSubmit, control, formState: { isSubmitting, errors }, reset } = useForm();

  const inviteMember = useMutation(usersClient.inviteUser, {
    mutationKey: cacheKeys.users.inviteUser,
    onSuccess: (result, request) => {
      genericFeedback(dictionary.invite.dialog.invitationSentInfo(request.email));
    },
    onError: (result, request) => {
      genericError();
    },
  });

  useEffect(() => {
    reset({
      emails: [],
    });
  }, [open, reset]);

  const handleInputChange = useCallback((event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {    
    if (newInputValue.endsWith(',') || newInputValue.endsWith(' ')) {
      // @ts-ignore
      event.target.blur();
      // @ts-ignore
      event.target.focus();
    }
  }, []);

  const onSubmit = useCallback(async (data: Form) => {
    await Promise.all(data.emails.map(email => inviteMember.mutateAsync({ email })));
    onClose();
  }, [inviteMember, onClose]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          {dictionary.invite.dialog.title}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Controller
            name="emails"
            control={control}
            rules={{
              required: dictionary.forms.validations.required,
              validate: {
                allEmailsValid: (value: string[]) => {
                  let isValid = true;
                  value.forEach(v => {
                    if (!isEmailValid(v)) {
                      isValid = false;
                    }
                  });
                  return isValid || dictionary.forms.validations.memberInvitationAllEmailsValid;
                },
              },
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                freeSolo
                autoSelect
                onInputChange={handleInputChange}
                onBlur={() => {}}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((email, index) => (
                    <Chip
                      deleteIcon={<Close />}
                      label={email}
                      size="small"
                      color={isEmailValid(email) ? 'default' : 'error'}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(props) =>
                  <TextField
                    {...props}
                    autoFocus
                    size="small"
                    placeholder={dictionary.invite.dialog.placeholderEmail}
                    error={!!errors.emails}
                    helperText={errors.emails && errors.emails.message}
                  />
                }
                onChange={(e, value) => field.onChange(value)}
                options={[]}
                defaultValue={[]}
              />
            )}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button type="button" variant="text" onClick={onClose}>{dictionary.dialogs.buttonCancel}</Button>
          <LoadingButton type="submit" loading={isSubmitting} variant="contained">{dictionary.invite.dialog.buttonInvite}</LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
