import { useCallback, useContext } from 'react';
import { trackLogIn } from 'tracking/tracking';
import { LocalizationContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';


export const useSignin = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { login: userLogin, ssoLogin, logout } = useContext(UserContext);

  const signIn = useCallback(async (email, password) => {
    try {
      const result = await userLogin(email, password);
      trackLogIn();
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }, [userLogin]);

  const signInWithSSO = useCallback(async (provider: string) => {
    const result = await ssoLogin(provider);
    
    if(result.success !== true) {
      return Promise.reject({ type: 'error', message: result.message });
    } else {
      try {
        await usersClient.signupWithSSO();
        trackLogIn();
      } catch (error: any) {
        logout();
        if (error.response?.status === 400) {
          return Promise.reject({
            type: 'error',
            message: dictionary.userWithThisEmailAlreadyExists,
          });
        } else {
          return Promise.reject({
            type: 'error',
            message: dictionary.somethingWentWrong,
          });
        }
      }
    }
  }, [ssoLogin, logout, dictionary]);

  return {
    signIn,
    signInWithSSO,
  };
};
