import React, { FC, useContext } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { LayoutContext, LocalizationContext } from 'contexts';
import { defineTypographyColor } from 'helpers/helpers';
import { getEventClassficationLabel } from 'helpers/event';
import { getRiskIndexLabel } from 'helpers/risk';
import { EventClassification } from 'clients/events/eventsClient.types';

export const GridMultipleRows: FC<any> = ({ subElements }: any) => {

  const { dictionary } = useContext(LocalizationContext);
  const { isMobile } = useContext(LayoutContext);

  return (
    <Grid container rowGap={6}>
      {subElements.map((subElement : {title: any, count:number}, index: number) => (
        <>
          <Grid item xs={6} key={subElement.title}>
            <Grid container direction="column" display="flex" alignItems="center" justifyContent="center" spacing={1}>
              <Grid item>
                <Typography
                  variant={isMobile ? 'h6' : 'h4'}
                  fontWeight={400}
                  color={defineTypographyColor(subElement.title)}
                >
                  {subElement.count}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={isMobile ? 'subtitle2' : 'body1'}>
                  {Object.values(EventClassification).includes(subElement.title as unknown as EventClassification) ?
                    getEventClassficationLabel(dictionary, subElement.title) :
                    getRiskIndexLabel(dictionary, subElement.title)
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!(index % 2) && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginRight:'-1px', height: '40px', marginTop: 'auto', marginBottom: 'auto' }}
            />
          )}
        </>
      ))}
    </Grid>
  );
};