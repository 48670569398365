import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, Container, Grid, TextField } from '@mui/material';

import { cacheKeys } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import { useDocument } from 'shared/hooks/useDocument';
import { useDocuments } from 'shared/hooks/useDocuments';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { usePrompt } from 'shared/hooks/useBlocker';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { HtmlEditor } from 'shared/components/form/HtmlEditor/HtmlEditor';
import { DocumentAutocomplete } from 'shared/components/form/DocumentAutocomplete/DocumentAutoComplete';


export const DocumentEdit = () => {
  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const { documentId = null }: any = useParams();
  const methods = useForm();

  const { handleSubmit, control, setValue, watch, formState: { isDirty, errors }, reset } = methods;

  const { document, error, status } = useDocument({ id: documentId });
  const { flatDocuments } = useDocuments();

  const breadcrumbs = useBreadcrumbs('editDocument', {
    text: {
      editDocument: [document?.title],
    },
    routes: {
      editDocument: [document?.id],
    },
  });

  const htmlContent = watch('html_content');

  useEffect(() => {
    if (document) {
      reset({
        ...document,
      });
    }
  }, [reset, document]);

  const editDocument = useMutation(documentsClient.patchDocument, {
    mutationKey: cacheKeys.documents.editDocument,
    onSuccess: (response) => {
      genericFeedback(dictionary.documents.edit.documentSaved);
      queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      queryClient.invalidateQueries([cacheKeys.documents.getDocument, {
        id: documentId,
      }]);
      queryClient.setQueryData([cacheKeys.documents.getDocument, {
        id: documentId,
      }], response);    
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(async (data: any) => {
    const { sub_pages, ...payload } = data;
    return editDocument.mutateAsync({
      ...payload,
    });
  }, [editDocument]);

  const hasChanged = useMemo(() => {
    return isDirty || htmlContent !== document?.html_content;
  } , [document, htmlContent, isDirty]);

  usePrompt( dictionary.documents.edit.unsavedChanges, hasChanged);

  if (error) {
    genericError();
    return null;
  }

  if (status !== 'success') {
    return <BodyLoading height="40vh"/>;
  }

  return (
    <Box bgcolor="#E5E5E5">
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center">
            <Grid item xs={12} maxWidth="md">
              <Box mt={4} mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Breadcrumbs list={breadcrumbs} />
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  {dictionary.save}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Container maxWidth="md" sx={{ p: 6, pl: '40px !important', pr: '40px !important', bgcolor: 'white' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    required: dictionary.forms.validations.required,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.title}
                      sx={{
                        border: 'none',
                        '.MuiInputBase-input': {
                          color: '#10285f',
                          fontWeight: '500',
                          fontSize: '20px',
                          ml: '-14px',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                          ml: '-14px',
                        },
                      }}
                      placeholder={dictionary.documents.form.titlePlaceholder}
                      helperText={errors.title && errors.title.message}
                      multiline
                    />
                  )}
                />
                <DocumentAutocomplete
                  defaultValue={flatDocuments?.find(({ id }: any) => (id === document.parent))}
                  onChange={(value: any) => setValue('parent', value?.id)}
                  excludeId={document.id}
                  label={dictionary.documents.edit.changeParent}
                />
              </Grid>
              <Grid item xs={12}>
                <HtmlEditor
                  onChange={(value: string) => {setValue('html_content', value);}}
                  defaultValue={document.html_content}
                  onSave={handleSubmit(onSubmit)}
                  pageId={documentId}
                />
              </Grid>
            </Grid>
          </Container>
        </form>
      </FormProvider>
    </Box>
  );
};
