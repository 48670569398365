import React, { FC, useContext } from 'react';
import {
  Box,
  Chip,
  Grid,
} from '@mui/material';

import { LocalizationContext } from 'contexts';
import { SimpleUser, User, UserType } from 'clients/users/userClient.types';

type Props = SimpleUser | User;

export const UserSelectOption: FC<Props> = ({ displayName, type, jobTitle }) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <li style={{ width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item>
          {displayName}
        </Grid>
        <Box ml="auto"/>
        {(type === UserType.Admin || jobTitle) && (
          <Grid item>
            <Chip
              size="small"
              label={type === UserType.Admin ? dictionary.user.adminLabel : jobTitle}
            />
          </Grid>
        )}
      </Grid>
    </li>
  );
};
