import { useCallback, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQueryClient, useMutation } from 'react-query';
import { Avatar, Box, Container, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { DialogContext, LayoutContext, LocalizationContext } from 'contexts';
import { documentsClient } from 'clients/documents/documentsClient';
import { useDocument } from 'shared/hooks/useDocument';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { HtmlEditor } from 'shared/components/form/HtmlEditor/HtmlEditor';

import { DocumentViewToolbar } from './DocumentViewToolbar';
import { Comments } from './partials/Comments';


export const DocumentView = () => {
  const { genericError, genericFeedback } = useContext(LayoutContext);
  const queryClient = useQueryClient();
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const [ showComments, setShowComments ] = useState(false);
  const { documentId = null }: any = useParams();
  const { document: doc, error, status } = useDocument({ id: documentId });
  const navigate = useNavigate();

  const deleteDocument = useMutation(documentsClient.deleteDocument, {
    mutationKey: cacheKeys.documents.deleteDocument,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.documents.getDocuments);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
      queryClient.removeQueries([cacheKeys.documents.getDocument, {
        id: documentId,
      }]);

      genericFeedback(dictionary.documents.edit.documentDeleted);
      navigate(routes.documents);
    },
    onError: () => {
      genericError();
    },
  });

  const onDelete = useCallback(async () => {
    const userConfirmed = await asyncConfirmation({
      title: dictionary.documents.edit.deleteConfirmation,
    });

    if (userConfirmed) {
      return deleteDocument.mutateAsync(documentId);
    }
    
  }, [asyncConfirmation, dictionary, deleteDocument, documentId]);

  const breadcrumbs = useBreadcrumbs('viewDocument', {
    text: {
      viewDocument: [doc?.title],
    },
    routes: {
      viewDocument: [doc?.id],
    },
  });

  const onEditPageClick = useCallback(() => {
    navigate(routes.editDocument(documentId));
  }, [documentId, navigate]);

  if (error) {
    genericError();
    navigate(routes.documents);
    return null;
  }

  if (status !== 'success') {
    return <BodyLoading height="40vh"/>;
  }

  window.onbeforeprint = () => {
    document.title = doc.title + doc.created_at.toISOString().split('T')[0];
  };

  window.onafterprint = () => {
    document.title = 'Naala - App';
  };

  return (
    <Box bgcolor="#E5E5E5" className="print-document-page">
      <Grid container justifyContent="center" className="no-print">
        <Grid item xs={12} maxWidth="md">
          <Box mt={4} mb={2}>
            <Breadcrumbs list={breadcrumbs} />
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12} maxWidth="md">
          <Box mb={2}>
            <DocumentViewToolbar
              onCommentsClick={() => { setShowComments(!showComments); }}
              onEditPageClick={onEditPageClick}
              showComments={showComments}
              onDelete={onDelete}
              onDownload={window.print}
            />
          </Box>
        </Grid>
      </Grid>
      <Container
        maxWidth="md"
        sx={{ p: 6, pl: '40px !important', pr: '40px !important', bgcolor: 'white', position: 'relative' }}
        className="print-document-container"
      >
        {showComments && (
          <Box position="absolute" top={0} right={-250}>
            <Comments comments={doc.comments}/>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '20px',
              }}
            >
              {doc.title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Avatar
                  sx={{
                    margin: 'auto',
                    width: 30,
                    height: 30,
                    fontSize: 12,
                    fontWeight: 500,
                    '.MuiAvatar-fallback': { color: '#ececec', fill: '#ececec' },
                  }}
                  alt={doc.created_by?.displayName}
                  src={doc.created_by?.profile_picture}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">{dictionary.documents.list.createdBy(doc.created_by?.displayName?.trim() || '')}</Typography>
                <Typography variant="body2">{dictionary.documents.list.lastUpdated(format(doc.updated_at, 'MMM dd, yyyy'))}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <HtmlEditor
              defaultValue={doc.html_content}
              readonly
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
