import { Dictionary } from 'contexts/LocalizationContext/types';
import { DashboardElementType } from 'clients/dashboard/dashboardClient.types';

export const createScoreCardSubElements = (scorecardElements: any) => {
  return Object.entries(scorecardElements)
    .filter(([key]) => key !== 'all')
    .map(([title, count]) => ({ title, count }));
};

export const getDashboardElementTypes = (dictionary: Dictionary) => ({
  [DashboardElementType.Tasks]: dictionary.dashboard.tasks,
  [DashboardElementType.Reports]: dictionary.dashboard.reports,
  [DashboardElementType.Documents]: dictionary.dashboard.documents,
  [DashboardElementType.Corrections]: dictionary.dashboard.corrections,
  [DashboardElementType.Incidents]: dictionary.dashboard.incidents,
  [DashboardElementType.Risks]: dictionary.dashboard.risks,
  [DashboardElementType.RiskControls]: dictionary.dashboard.riskControls,
});

export const getDashboardElementType = (dictionary: Dictionary, type: DashboardElementType) => getDashboardElementTypes(dictionary)[type];