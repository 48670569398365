import React, { FC } from 'react';
import { Autocomplete, AutocompleteValue, TextField } from '@mui/material';

export const EventAutocomplete: FC<any> = ({ label, TextFieldProps, ...props }) => {

  const handleSelectEvent = (value: AutocompleteValue<unknown, undefined, undefined, undefined>) => {
    props.onChange(value);
    props.selectEvent(value);
  };

  return (
    <Autocomplete
      renderInput={props => <TextField {...props} label={label || 'Event'} InputLabelProps={{ shrink: true }} {...TextFieldProps} />}
      getOptionLabel={(e: any) => e.title}
      disablePortal
      ListboxProps={{ style: { maxHeight: '20vh' } }}
      {...props}
      onChange={(e, value) => handleSelectEvent(value)}
    />
  );
};
