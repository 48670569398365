import React, { FC, ReactNode, createContext, useState, useCallback, useMemo, useEffect, useContext } from 'react';
import { Alert, Snackbar as MuiSnackbar, useMediaQuery, useTheme } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import copy from 'copy-text-to-clipboard';

import { UserContext } from 'contexts/UserContext/UserContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { AskForUserConfirmation, ContextProps, Mode } from './types';


const defaultContext: ContextProps = {
  snackbarState: {
    open: false,
    message: '',
    severity: 'info',
    autoHideDuration: 5000,
  },
  setSnackbarState: () => null,
  closeSnackbar: () => null,
  changesSaved: () => null,
  genericError: () => null,
  genericFeedback: () => null,
  validationError: () => null,
  Snackbar: null,
  showFilters: false,
  setShowFilters: () => null,
  mode: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  setMode: () => null,
  toggleMode: () => null,
  ToggleModeIcon: Brightness4,
  adminView: false,
  showPopup: false,
  setShowPopup: () => null,
  askForUserConfirmation: (props) => Promise.resolve(false),
  isMobile: false,
  copyUrlToShare: () => null,
};


export const LayoutContext = createContext(defaultContext);

export const LayoutContextProvider: FC = ({ children }) => {

  const { isAdmin } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const [snackbarState, setSnackbarState] = useState(defaultContext.snackbarState);
  const [showFilters, setShowFilters] = useState(defaultContext.showFilters);
  const [mode, setMode] = useState<Mode>('light');
  const [showPopup, setShowPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState<ReactNode | undefined>(undefined);
  const [popupContent, setPopupContent] = useState<ReactNode | undefined>(undefined);
  const [popupConfirmLabel, setPopupConfirmLabel] = useState<ReactNode | undefined>(undefined);
  const [popupCancelLabel, setPopupCancelLabel] = useState<ReactNode | undefined>(undefined);
  const [onConfirmPopup, setOnConfirmPopup] = useState<any>(() => null);
  const [onCancelPopup, setOnCancelPopup] = useState<any>(() => null);
  const [onClosePopup, setOnClosePopup] = useState<any>(() => null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


  const askForUserConfirmation: AskForUserConfirmation = useCallback(({ title, content, confirmLabel, cancelLabel }) => {
    setPopupTitle(title);
    setPopupContent(content);
    setPopupConfirmLabel(confirmLabel);
    setPopupCancelLabel(cancelLabel);
    setShowPopup(true);
    return new Promise((resolve) => {
      setOnConfirmPopup(() => () => [setShowPopup(false), resolve(true)]);
      setOnCancelPopup(() => () => [setShowPopup(false), resolve(false)]);
      setOnClosePopup(() => () => [setShowPopup(false), resolve(false)]);
    });
  }, []);

  const closeSnackbar = useCallback(() => {
    setSnackbarState({
      ...snackbarState,
      open: false,
    });
  }, [snackbarState]);

  const changesSaved = useCallback(() => {
    setSnackbarState({
      open: true,
      message: 'Changes saved successfully!',
      severity: 'success',
      autoHideDuration: 2000,
    });
  }, []);

  const validationError = useCallback((message: string = dictionary.oneOrMoreFieldsAreIncorrect) => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'error',
      autoHideDuration: 3000,
    });
  }, [dictionary]);

  const genericError = useCallback(() => {
    setSnackbarState({
      open: true,
      message: dictionary.somethingWentWrong,
      severity: 'error',
      autoHideDuration: 2000,
    });
  }, [dictionary]);

  const genericFeedback = useCallback((message: string = '', duration = 2000) => {
    setSnackbarState({
      open: true,
      message: message,
      severity: 'success',
      autoHideDuration: duration,
    });
  }, []);

  const toggleMode = useCallback(() => {
    setMode(mode === 'dark' ? 'light' : 'dark');
  }, [mode]);

  const copyUrlToShare = useCallback((message) => {
    const copied = copy(document?.location?.href);

    if (copied) {
      genericFeedback(message || dictionary.urlCopied);
    } else {
      genericError();
    }
  }, [genericError, genericFeedback, dictionary]);

  const Snackbar = useMemo(() => (
    <MuiSnackbar
      open={snackbarState.open}
      autoHideDuration={snackbarState.autoHideDuration}
      onClose={closeSnackbar}
    >
      <Alert onClose={closeSnackbar} severity={snackbarState.severity}>
        {snackbarState.message}
      </Alert>
    </MuiSnackbar>
  ), [snackbarState, closeSnackbar]);

  const ToggleModeIcon = mode === 'dark' ? Brightness7 : Brightness4;


  useEffect(() => {
    localStorage.setItem('mode', mode);
  }, [mode]);

  return (
    <LayoutContext.Provider
      value={{
        snackbarState,
        setSnackbarState,
        closeSnackbar,
        changesSaved,
        genericError,
        genericFeedback,
        validationError,
        showFilters,
        setShowFilters,
        Snackbar,
        mode,
        setMode,
        toggleMode,
        ToggleModeIcon,
        adminView: isAdmin,
        showPopup,
        setShowPopup,
        onConfirmPopup,
        onCancelPopup,
        onClosePopup,
        popupTitle,
        popupContent,
        popupCancelLabel,
        popupConfirmLabel,
        askForUserConfirmation,
        isMobile,
        copyUrlToShare,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
