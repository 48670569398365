import React, { FC, useCallback, useContext } from 'react';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { Autocomplete, Box, Grid, InputAdornment, TextField } from '@mui/material';
import { Business, Person, Flag, Save, Translate, SupervisorAccount } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { Country, Language } from 'types/Country';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { countries, languages, getLanguageLabel, countryToFlag, getCountryLabel } from 'helpers/country';
import { CompanyCrud } from 'clients/companies/companyClient.types';
import { SimpleUser, UserType } from 'clients/users/userClient.types';
import { useUsers } from 'shared/hooks/useUsers';
import { UserSelectOption } from 'shared/components/form/UserSelectOption/UserSelectOption';

interface Props {
    defaultValues?: Partial<CompanyCrud>;
    onSubmitRequest: (values: CompanyCrud) => void;
    onSubmitButtonText: string;
}

const DEFAULT_VALUES: Partial<CompanyCrud> = {
  name: '',
  country: null,
  preferredLanguage: null,
  primaryContact: null,
  accountManager: null,
};

export const CompanyForm: FC<Props> = ({ defaultValues = DEFAULT_VALUES, onSubmitRequest, onSubmitButtonText }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { users: admins } = useUsers({
    type: UserType.Admin,
  });
  const { users } = useUsers({
    company: defaultValues.id,
  });
  const { handleSubmit, control, formState: { isSubmitting, errors }, watch } = useForm<Partial<CompanyCrud>>({
    defaultValues,
  });

  const country = watch('country');

  const onSubmit = useCallback((data: CompanyCrud) => {
    return onSubmitRequest(data);
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="name"
            control={control}
            rules={{ required: dictionary.forms.validations.required }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant="outlined"
                label={dictionary.forms.company.fieldName}
                name="name"
                error={!!errors.name}
                helperText={errors.name?.message}
                InputProps={{
                  startAdornment: <InputAdornment position="start"><Business/></InputAdornment>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="accountManager"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                renderInput={(props: any) =>
                  <TextField
                    {...props}
                    label={dictionary.forms.company.fieldAccountManager}
                    error={!!errors.accountManager}
                    helperText={errors.accountManager && (errors.accountManager as FieldError).message}
                    InputProps={{
                      ...props?.InputProps,
                      startAdornment: <InputAdornment position="start"><Box ml={1} component={SupervisorAccount}/></InputAdornment>,
                    }}
                  />
                }
                renderOption={(props, option) => <UserSelectOption {...option}/>}
                onChange={(e, value) => field.onChange(value)}
                getOptionLabel={(option: SimpleUser) => option.displayName}
                options={admins}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="primaryContact"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                renderInput={(props: any) =>
                  <TextField
                    {...props}
                    label={dictionary.forms.company.fieldPrimaryContact}
                    error={!!errors.primaryContact}
                    helperText={errors.primaryContact && (errors.primaryContact as FieldError).message}
                    InputProps={{
                      ...props?.InputProps,
                      startAdornment: <InputAdornment position="start"><Box ml={1} component={Person}/></InputAdornment>,
                    }}
                  />
                }
                renderOption={(props, option) => <UserSelectOption {...option}/>}
                onChange={(e, value) => field.onChange(value)}
                getOptionLabel={option => option.displayName}
                options={users}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                renderInput={(props: any) =>
                  <TextField
                    {...props}
                    label={dictionary.forms.fieldCountry}
                    error={!!errors.country}
                    helperText={errors.country?.message}
                    InputProps={{
                      ...props?.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box ml={1}>
                            {country ? countryToFlag(country) : <Flag />}
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                renderOption={(props, option) => (
                  <span>
                    <Box component="span" mr={1}>
                      {countryToFlag(option)}
                    </Box>
                    {getCountryLabel(dictionary, option)}
                  </span>
                )}
                getOptionLabel={(option: Country) => getCountryLabel(dictionary, option)}
                onChange={(e, value) => field.onChange(value)}
                options={countries}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="preferredLanguage"
            control={control}
            render={({ field }) => (
              <Autocomplete
                {...field}
                renderInput={(props: any) =>
                  <TextField
                    {...props}
                    label={dictionary.forms.company.fieldPreferredLanguage}
                    error={!!errors.preferredLanguage}
                    helperText={errors.preferredLanguage?.message}
                    InputProps={{
                      ...props?.InputProps,
                      startAdornment: <InputAdornment position="start"><Box ml={1} component={Translate}/></InputAdornment>,
                    }}
                  />
                }
                onChange={(e, value) => field.onChange(value)}
                getOptionLabel={(option: Language) => getLanguageLabel(dictionary, option)}
                options={languages}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Box ml="auto"/>
            <Grid item>
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitting}
                loadingPosition="end"
                endIcon={<Save/>}
              >
                {onSubmitButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
