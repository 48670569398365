import React, { FC, Fragment, useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Grid, Box, Paper, Typography, Button } from '@mui/material';

import { routes, cacheKeys } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { companyClient } from 'clients/companies/companyClient';
import { useCompany } from 'shared/hooks/useCompany';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { Footer } from 'shared/components/layout/Footer/Footer';
import { CompanyForm } from './partials/CompanyForm';
import { AddCompanyUser } from './partials/AddCompanyUser';


export const EditCompany: FC = () => {

  const { companyId = '' } = useParams();
  const navigate = useNavigate();
  const { genericError, changesSaved, askForUserConfirmation } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const { company, status, refetch } = useCompany({
    id: companyId,
  });
  const breadcrumbs = useBreadcrumbs('editCompany', {
    text: {
      editCompany: [company?.name],
    },
    routes: {
      editCompany: [company?.id],
    },
  });

  const queryClient = useQueryClient();
  const editCompany = useMutation(companyClient.editCompany, {
    mutationKey: cacheKeys.companies.editCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.companies.getCompanies);
      refetch();
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const deleteCompany = useMutation(companyClient.deleteCompany, {
    mutationKey: cacheKeys.companies.deleteCompany,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.companies.getCompanies);
      queryClient.removeQueries([cacheKeys.companies.getCompany, {
        id: companyId,
      }]);
      changesSaved();
      navigate(routes.companies);
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback(data => {
    if (!company) {
      return false;
    }
    return editCompany.mutate({
      ...data,
      id: company.id,
    });
  }, [company, editCompany]);

  const onDelete = useCallback(async () => {
    const userConfirmed = await askForUserConfirmation({ title: dictionary.company.edit.deleteConfirmation });
    if (!company || !userConfirmed) {
      return false;
    }
    return deleteCompany.mutate({
      id: company.id,
    });
  }, [company, deleteCompany, askForUserConfirmation, dictionary]);


  return (
    <Fragment>
      <Container maxWidth="xl">
        <Box my={2}>
          {status !== 'success' || !company ? (
            <BodyLoading height="100vh"/>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box mt={4} mb={2}>
                  <Breadcrumbs list={breadcrumbs} />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} lg={8}>
                    <Box mt={10}/>
                    <Typography component="h1" variant="h2">
                      {company.name}
                    </Typography>
                    <Box mt={2} p={4} component={Paper}>
                      <CompanyForm
                        onSubmitRequest={onSubmit}
                        onSubmitButtonText={dictionary.forms.saveChanges}
                        defaultValues={{
                          id: company.id,
                          name: company.name,
                          accountManager: company.accountManager || undefined,
                          primaryContact: company.primaryContact,
                          preferredLanguage: company.preferredLanguage,
                          country: company.country,
                        }}
                      />
                    </Box>
                    <Box mt={1}/>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <Button
                          size="large"
                          variant="outlined"
                          onClick={onDelete}
                        >
                          {dictionary.company.edit.buttonDelete}
                        </Button>
                      </Grid>
                      <Box ml="auto"/>
                      <Grid item>
                        <AddCompanyUser company={company} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
      <Footer/>
    </Fragment>
  );
};
