import React, { FC, useCallback, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Box,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Group, Mail, Person, Phone, Save, Work } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { UserContext, LocalizationContext } from 'contexts';
import { emailRegex, phoneRegex } from 'helpers/validation';
import { User, UserCrud, UserType } from 'clients/users/userClient.types';

import { PictureUploader } from 'views/LoggedIn/User/partials/PictureUploader';
interface Props {
    defaultValues?: Partial<UserCrud>;
    onSubmitRequest: (values: UserCrud) => void;
    onSubmitButtonText: string;
    disabledFields?: Array<keyof UserCrud>;
}

const DEFAULT_VALUES = {
  type: UserType.Customer,
  name: '',
  surname: '',
  company: null,
  position: '',
  email: '',
  phone: null,
};

export const UserForm: FC<Props> = ({ defaultValues = {}, onSubmitRequest, onSubmitButtonText, disabledFields }) => {

  const { isAdmin } = useContext(UserContext);
  const { dictionary } = useContext(LocalizationContext);
  const { handleSubmit, control, formState: { isSubmitting, errors }, watch, setValue } = useForm<UserCrud | User>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const isFieldEnabled = useCallback((field: keyof UserCrud) => {
    if (!disabledFields) {
      return true;
    }
    return disabledFields.indexOf(field) === -1;
  }, [disabledFields]);

  const type = watch('type');
  const firstName = watch('firstName');
  const lastName = watch('lastName');

  const onSubmit = useCallback((data: UserCrud) => {

    return onSubmitRequest(data);
  }, [onSubmitRequest]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        {isFieldEnabled('profilePicture') && (
          <Grid item xs={isFieldEnabled('type') ? 6 : 12}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item>
                <Controller
                  name="profilePicture"
                  control={control}
                  render={({ field }) => (
                    <PictureUploader
                      {...field}
                      label={dictionary.forms.user.fieldPicture}
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3">
                  {firstName} {lastName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isAdmin && isFieldEnabled('type') && (
          <Grid item xs={isFieldEnabled('profilePicture') ? 6 : 12} style={{ textAlign: 'right' }}>
            <Box mr={1}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={type === UserType.Admin}
                    onChange={(e, isAdmin) => {
                      setValue('type', isAdmin ? UserType.Admin : UserType.Customer);
                    }}
                  />
                }
                labelPlacement="start"
                label={dictionary.forms.user.fieldAdmin}
              />
            </Box>
          </Grid>
        )}
        {isFieldEnabled('firstName') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="firstName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.user.fieldFirstName}
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Person/></InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('lastName') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.user.fieldLastName}
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Group/></InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('jobTitle') && (
          <Grid item xs={12} sm={isAdmin ? 6 : 12}>
            <Controller
              name="jobTitle"
              control={control}
              rules={{ required: dictionary.forms.validations.required }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.user.fieldJobTitle}
                  error={!!errors.jobTitle}
                  helperText={errors.jobTitle?.message}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Work/></InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('email') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: dictionary.forms.validations.required,
                pattern: {
                  value: emailRegex,
                  message: dictionary.forms.validations.invalidEmail,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.fieldEmail}
                  error={!!errors.email}
                  helperText={defaultValues.email ? dictionary.forms.user.fieldEmailDisabledHelper : errors.email?.message}
                  disabled={!!defaultValues.email}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Mail/></InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {isFieldEnabled('phone') && (
          <Grid item xs={12} sm={6}>
            <Controller
              name="phone"
              control={control}
              rules={{
                pattern: {
                  value: phoneRegex,
                  message: dictionary.forms.validations.invalidPhone,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={dictionary.forms.fieldPhone}
                  error={!!errors.phone}
                  helperText={errors.phone?.message}
                  InputProps={{
                    startAdornment: <InputAdornment position="start"><Phone/></InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Box ml="auto"/>
            <Grid item>
              <LoadingButton
                size="large"
                type="submit"
                variant="outlined"
                color="secondary"
                loading={isSubmitting}
                loadingPosition="end"
                endIcon={<Save/>}
              >
                {onSubmitButtonText}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
