import { getFileType } from 'helpers/file';

export const mapMediaCreateCrudData = (data: any): FormData => {
  const formData = new FormData();
  const file = data.file.blob();
  formData.append('file', file);
  formData.append('name', file.name);
  
  const fileType = getFileType(file.type);
  if (fileType) {
    formData.append('type', fileType);
  } else {
    throw new Error('Invalid file format');
  }
    
  return formData;
};
