import { mapActivityLogs } from 'clients/reports/reportsClient.formatter';
import { mapMinimalUserData } from 'clients/users/userClient.formatter';


export const mapEventData = (data: any) => ({
  ...data,
  updated_at: new Date(data.updated_at),
  updated_by: data.updated_by ? mapMinimalUserData(data.updated_by) : null,
  created_by: data.created_by ? mapMinimalUserData(data.created_by) : null,
  activity_logs: data.activity_logs?.map(mapActivityLogs) || [],
});
