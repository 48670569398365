import { useCallback, useContext, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { Controller } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  LinearProgress,
  Paper,
  IconButton,
  Tooltip,
  MenuItem,
} from '@mui/material';
import {
  UnfoldLess,
  UnfoldMore,
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { format } from 'date-fns';

import { cacheKeys, routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { isValidDate } from 'helpers/date';
import { getReportStatusLabels, isAnswerEmpty } from 'helpers/report';
import { sanitizeDescription } from 'helpers/html';
import { reportQuestionsClient } from 'clients/reportQuestions/reportQuestionsClient';
import { ReportStatus } from 'clients/reports/reportsClient.types';
import { reportsClient } from 'clients/reports/reportsClient';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { useReport } from 'shared/hooks/useReport';
import { useUsers } from 'shared/hooks/useUsers';
import { DownloadButton } from 'shared/components/form/DownloadButton/DownloadButton';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';
import { ActivityLog } from 'shared/components/layout/ActivityLog/ActivityLog';

import { ReportQuestionList } from './ReportQuestionList';
import { QuestionForm } from './QuestionForm';


export const ReportFulfillmentForm = () => {

  useScrollTop();
  const navigate = useNavigate();
  const { users } = useUsers();
  const { dictionary } = useContext(LocalizationContext);
  const { genericError } = useContext(LayoutContext);
  const [expandAll, setExpandAll] = useState(true);
  const { reportId = null }: any = useParams();

  const { report, error, status } = useReport({ id: reportId });

  const queryClient = useQueryClient();
  const createAnswer = useMutation(reportQuestionsClient.createAnswer, {
    mutationKey: cacheKeys.reports.createAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.reports.getReport);
      queryClient.invalidateQueries(cacheKeys.reports.getReports);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const patchAnswer = useMutation(reportQuestionsClient.patchAnswer, {
    mutationKey: cacheKeys.reports.patchAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.reports.getReport);
      queryClient.invalidateQueries(cacheKeys.reports.getReports);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const deleteAnswer = useMutation(reportQuestionsClient.deleteAnswer, {
    mutationKey: cacheKeys.reports.deleteAnswer,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.reports.getReport);
      queryClient.invalidateQueries(cacheKeys.reports.getReports);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const patchReport = useMutation(reportsClient.patchReport, {
    mutationKey: cacheKeys.reports.editReport,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.reports.getReport);
      queryClient.invalidateQueries(cacheKeys.reports.getReports);
      queryClient.invalidateQueries(cacheKeys.tasks.getDropdownResults);
      queryClient.invalidateQueries(cacheKeys.dashboard.getDashboardMetadata);
    },
    onError: () => {
      genericError();
    },
  });

  const onReportFieldChange = useCallback((fieldName: string, value: any) => {
    const updatedReport = {
      id: report.id,
      [fieldName]: value,
    };
    return patchReport.mutateAsync(updatedReport);
  },[patchReport, report]);

  const onAnswerChange = useCallback((data: any) => {
    const payload = {
      reportId: report.id,
      questionId: data.questionId,
      answerId: data.answerId,
      data: {
        [data.answerType]: data.answerValue,
      },
    };

    if (isAnswerEmpty(data)) {
      return deleteAnswer.mutateAsync(payload);
    }

    if (payload.answerId) {
      return patchAnswer.mutateAsync(payload);
    }
    
    return createAnswer.mutateAsync(payload);
  }, [createAnswer, deleteAnswer, patchAnswer, report]);

  const onDownload = useCallback( (event: any) => {
    event.stopPropagation();
    window.print();
  }, []);

  if(status === 'loading' || !users?.length) {
    return <BodyLoading height="40vh"/>;
  }

  window.onbeforeprint = () => {
    document.title = report.title + report?.start_date.split('T')[0];
  };

  window.onafterprint = () => {
    document.title = 'Naala - App';
  };

  if(error) {
    navigate(routes.reports);
    return null;
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ p: 2, textTransform: 'capitalize' }} align="center">{report?.title}</Typography>
      <LinearProgress variant="determinate" value={report?.meta?.progress?.percentage || 0} sx={{ m: 'auto', maxWidth: 400, mb: 4 }}/>
      {report?.description && (<Typography variant="body1" sx={{ mt: 2, mb: 3 }} dangerouslySetInnerHTML={{ __html: sanitizeDescription(report.description) }}/>)}
      <ActivityLog activityLogs={report.activity_logs}/>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }} className="print-report-container">
          <Grid container spacing={2} wrap="nowrap">
            <Grid item xs={12} sm={4}>
              <UserAutocomplete
                label={dictionary.reports.form.fieldReporter}
                TextFieldProps={{ size: 'small' }}
                defaultValue={users.find(({ id }: any) => report?.created_by?.id === id)}
                onChange={(selectedUser: any) => selectedUser && onReportFieldChange('created_by', selectedUser?.id)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <QuestionForm
                name="start_date"
                defaultValue={report?.start_date}
                onSubmit={(value: any) => isValidDate(value) && onReportFieldChange('start_date', format(value, 'yyyy-MM-dd'))}
              >
                <Controller
                  name="start_date"
                  rules={{ required: dictionary.reports.form.reportDateRequired }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      inputFormat="dd.MM.yyyy"
                      mask="__.__.____"
                      label={dictionary.reports.form.fieldReportDate}
                      renderInput={(props) => <TextField size="small" {...props}/>}
                    />
                  )}
                />
              </QuestionForm>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label={dictionary.reports.form.fieldStatus}
                size="small"
                defaultValue={report?.status}
                onChange={(event: any) => onReportFieldChange('status', event.target.value)}
                select
              >
                <MenuItem value={ReportStatus.Draft}>{getReportStatusLabels(dictionary)[ReportStatus.Draft]}</MenuItem>
                <MenuItem value={ReportStatus.InReview}>{getReportStatusLabels(dictionary)[ReportStatus.InReview]}</MenuItem>
                <MenuItem value={ReportStatus.Approved}>{getReportStatusLabels(dictionary)[ReportStatus.Approved]}</MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              <DownloadButton onClick={onDownload} tooltipTitle={dictionary.reports.form.downloadReprot}/>
            </Grid>
            <Grid item>
              <Tooltip title={expandAll ? dictionary.reports.form.colapseAll : dictionary.reports.form.expandAll}>
                <IconButton onClick={() => setExpandAll(prevState => !prevState)} sx={{ mr: -1 }}>
                  {expandAll ? <UnfoldLess/> : <UnfoldMore/>}
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <ReportQuestionList report={report} expandAll={expandAll} setExpandAll={setExpandAll} onAnswerChange={onAnswerChange}/>
      <Box textAlign="center" mt={4} mb={20}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={() => navigate(routes.reports)}
          className="no-print"
        >
          {dictionary.reports.form.backToReportsButton}
        </Button>
      </Box>
    </Container>
  );
};
