import { Country } from 'types/Country';

export const dictionary = {
  home: 'Home',
  loading: 'Loading',
  results: 'Results',
  myProfile: 'My Profile',
  myAccount: 'My Account',
  subscriptions: 'Subscriptions',
  login: 'Log In',
  logOut: 'Log Out',
  signUp: 'Signup',
  fullName: 'Full Name',
  name: 'Name',
  surname: 'Surname',
  email: 'Email',
  phone: 'Phone',
  password: 'Password',
  thePasswordMustBeAtLeast8CharactersLong: 'The password must be at least 8 characters long',
  repeatPassword: 'Repeat Password',
  userWithThisEmailAlreadyExists: 'User with this email already exists',
  accountAlreadyExists: 'An account is already registered with this email.',
  please: 'Please',
  orUseAnotherEmail: 'or use another email.',
  passwordsDoNotMatch: 'Passwords do not match',
  signUpWithGoogle: 'Signup with Google',
  signUpWithMicrosoft: 'Signup with Microsoft',
  loginWithGoogle: 'Login with Google',
  loginWithMicrosoft: 'Login with Microsoft',

  auth: {
    or: 'or',
    dontHaveAccount: 'Don\'t have an account?',
    alreadyHaveAccount: 'Already have an account?',
    buttonSignIn: 'Sign in',
    buttonCreateAccount: 'Create account',

    validation: {
      emailOrPasswordWrong: 'Email or password wrong',
    },
  },

  impersonate: {
    noToken: 'Token is not present',
    invalidToken: 'Token is invalid or expired',
  },

  somethingWentWrong: 'Something went wrong!',
  oneOrMoreFieldsAreIncorrect: 'One or more fields are incorrect.',
  edit: 'Edit',
  open: 'Open',
  delete: 'Delete',
  save: 'Save',
  view: 'View',
  date: 'Date',
  dueDate: 'Due Date',
  dates: 'Dates',
  time: 'Time',
  duration: 'Duration',
  comingSoon: 'Coming Soon',
  toggleLighDarkMode: 'Toggle Light/Dark Mode',
  urlCopied: 'The url has been copied',

  hour: 'Hour',
  hours: 'Hours',
  minutes: 'Minutes',

  resetPassword: 'Reset Password',
  reset: 'Reset',
  passwordResetEmailSent: 'Password Reset Email Sent',

  notFound: 'Not Found',

  progress: 'Progress',
  complete: 'Complete',
  downloads: 'Downloads',

  join: 'Join',

  darkMode: 'Dark Mode',
  lightMode: 'Light Mode',

  switchToDarkMode: 'Switch to Dark Mode',
  switchToLightMode: 'Switch to Light Mode',

  month: 'Month',
  week: 'Week',
  day: 'Day',
  agenda: 'Agenda',
  today: 'Today',

  toDo: 'To Do',
  inProgress: 'In Progress',
  completed: 'Completed',
  deleted: 'Deleted',

  draft: 'Draft',
  in_review: 'In Review',
  approved: 'Approved',

  new: 'New',
  correctionPlanned: 'Correction Planned',
  correctionInProgress: 'Correction In Progress',
  planned: 'Planned',
  closed: 'Closed',

  threeHoursAgo: '3 hours ago',
  twoDaysAgo: '2 days ago',
  oneWeekAgo: '1 week ago',

  download: 'Download',

  search: 'Search',
  notifications: 'Notifications',
  seeNotifications: 'See Notifications',

  hello: 'Hello',
  assignee: 'Assignee',
  status: 'Status',

  cancel: 'Cancel',
  all: 'All',
  submit: 'Submit',

  description: 'Description',
  attachments: 'Attachments',

  position: 'Position',

  resetFilters: 'Reset Filters',
  type: 'Type',

  filtersViewBoard: 'Board',
  filtersViewList: 'List',

  frequency: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    semesterly: 'Semesterly',
    yearly: 'Yearly',
  },

  no: 'No',
  yes: 'Yes',

  none: 'None',

  noResults: 'There are no results matching your selection',

  confirmation: {
    defaultTitle: 'Are you sure you want to do this?',
    defaultContent: 'This action is irreversible.',
    no: 'No',
    yes: 'Yes',
  },

  speedDial: {
    task: 'Task',
    incident: 'Incident',
    report: 'Report',
    risk: 'Risk',
    control: 'Control',
    riskControl: 'Risk Control',
    project: 'Project',
    company: 'Company',
    user: 'User',
  },

  welcome: {
    title: 'Thank you for signing up.',
    buttonStart: 'Get started',
  },

  company: {
    accountManagerLabel: 'Account Manager',
    primaryContactLabel: 'Primary Contact',
    selectCompanyLabel: 'View Projects',
    title: 'Company',

    create: {
      title: 'Create Company',
      buttonCreate: 'Create Company',
    },

    edit: {
      addUserTitle: (company: string) => `Add user to ${company}`,
      buttonEdit: 'Edit Company',
      buttonDelete: 'Delete Company',
      buttonAddUser: 'Add User',
      deleteConfirmation: 'Are you sure you want to delete this Company?',
      editTheCompanyName: 'Edit the company name',
      placeholderCompanyName: 'Company name',
      feedbackCompanyNameUpdated: 'The company name has been updated successfully.',
    },
  },

  companies: {
    title: 'Companies',
  },

  user: {
    title: 'User',
    typeCustomerLabel: 'Customer',
    typeAdminLabel: 'Admin',

    create: {
      title: 'Create User',
      buttonCreate: 'Create User',
    },

    edit: {
      buttonEdit: 'Edit User',
      buttonDelete: 'Delete User',
      deleteConfirmation: 'Are you sure you want to delete this User?',
      deleteOwnAccountConfirmation: 'Are you sure you want to delete your account?',
    },

    companyLabel: 'Company',
    jobTitleLabel: 'Job Title',
    adminLabel: 'Admin',
  },

  users: {
    title: 'Users',
  },

  activityLog : {
    label: 'Activity log:',
    approved : 'Approved',
    assignedTo : 'Reassigned',
    created : 'Created',
    inReview : 'In Review',
    updated : 'Updated',
    correctionPlanned: 'Correction Planned',
    correctionInProgress: 'Correction In Progress',
    closed: 'Closed',
  },
  tasks: {
    form: {
      fieldTitle: 'Title',
      fieldDescription: 'Description',
      fieldDueDate: 'Due Date',
      fieldAssignee: 'Assignee',
      fieldPriority: 'Priority:',
      fieldEvent: 'Event',
      fieldReporter: 'Reporter',
      fieldRequired: 'This field is required',
      taskSaved: 'The task has been saved successfully',
      taskCreated: 'The task has been created successfully',
      taskDeleted: 'The task has been deleted successfully',
      createTaskTitle: 'Create Task',
      editTaskTitle: 'Edit Task',
    },
    list: {
      deletePrompt: 'Are you sure you want to delete this task?',
    },
  },
  reports: {
    form: {
      reportDateRequired: 'The report date is required.',
      fieldReporter: 'Reporter',
      fieldReportDate: 'Report Date',
      fieldStatus: 'Status',
      colapseAll: 'Collapse All',
      expandAll: 'Expand All',
      createButton: 'Create',
      backToReportsButton: 'Back to Reports',
      alertInfo: 'In order to fill in the report, you have to create it first.',
      createReport: 'Create Report',
      createReportSubtitle: 'In order to create report pick a date and an available report template.',
      fieldText: 'Text',
      fieldTime: 'Time',
      fieldDate: 'Date',
      fieldDateTime: 'Date & Time',
      fieldAnswer: 'Answer',
      backButton: 'Back',
      nextButton: 'Next',
      reportSaved: 'The report has been saved successfully',
      reportCreated: 'The report has been created successfully',
      reportDeleted: 'The report has been deleted successfully',
      downloadReprot: 'Download Report',
    },
    list: {
      fulfill: 'Fulfill',
      fulfilledReports: 'Reports',
      availableReports: 'Available Reports',
      deleteConfirmation: 'Are you sure you want to delete this report?',
      questionCount: (total: number) => `(${total}) Questions`,
      metaInfo: (answered: number, total: number, date: string, name: string) => `${answered}/${total} Questions - Last updated on the ${date} by ${name}`,
    },
  },

  incidents: {
    openStatus: 'Open',
    onHoldStatus: 'On Hold',
    closedStatus: 'Closed',
    incidentClassification: 'Incident',
    complaintClassification: 'Complaint',
    nonconformityClassification: 'Non Conformity',
    feedbackClassification: 'Feedback',
    reportClassification: 'Report',

    form: {
      fieldTitle: 'Title',
      fieldStatus: 'Status',
      fieldClassification: 'Classsification',
      fieldReporter: 'Reporter',
      fieldIncidentDate: 'Incident Date',
      incidentDateRequired: 'The incident date is required.',
      titleRequired: 'The incident title is required.',
      alertInfo: 'In order to fill in the incident, you have to create it first.',
      backToIncidentsButton: 'Back to Incidents',
      editTheIncidentTitle: 'Edit the incident title',
      saveTheIncidentTitle: 'Save the incident title',
      backButton: 'Back',
      nextButton: 'Next',
      createButton: 'Create',
      createIncident: 'Create Incident Report',
      createIncidentSubtitle: 'In order to create incident report pick a date and fill the incident title.',
      incidentDeleted: 'The incident has been deleted successfully.',
      incidentSaved: 'The incident has been saved successfully',
      incidentCreated: 'The incident has been created successfully',
    },
    list: {
      fulfill: 'Fulfill',
      incidentsTitle: 'Incidents',
      availableIncidents: 'Available Incidents',
      deleteConfirmation: 'Are you sure you want to delete this incident?',
      tooltipTitle: 'Download Incidents',
    },
  },
  
  risks: {
    indexes: {
      index1_6: 'Index 1 - 6',
      index7_12: 'Index 7 - 12',
      index13_19: 'Index 13 - 19',
      index20_25: 'Index 20 - 25',
    },
    form: {
      fieldHazard: 'Hazard',
      fieldRisk: 'Risk',
      fieldSituation: 'Situation',
      fieldHarm: 'Harm',
      fieldRiskEvaluationProbability: 'Risk Evaluation Probability',
      fieldRiskEvaluationSeverityScore: 'Risk Evaluation Severity Score',
      fieldRiskEvaluationResultingRiskIndex: 'Risk Evaluation Resulting Risk Index',
      fieldResidualRiskEvaluationProbability: 'Residual Risk Evaluation Probability',
      fieldResidualRiskSeverityScore: 'Residual Risk Severity Score',
      fieldResidualRiskResultingRiskIndex: 'Residual Risk Resulting Risk Index',
      fieldOwner: 'Owner',
      fieldRiskControls: 'Select Risk Controls',
      fieldAddNewRiskControl: 'Add new Risk Control',
      createRiskTitle: 'Create Risk',
      editRiskTitle: 'Edit Risk',
      riskDeleted: 'The risk has been deleted successfully.',
      riskSaved: 'The risk has been saved successfully',
      riskCreated: 'The risk has been created successfully',
      riskUrlCopied: 'Risk url copied to clipboard',
    },
    list: {
      title: 'Risks',
      fieldSearch: 'Search',
      deleteConfirmation: 'Are you sure you want to delete this risk?',
      riskEvaluationProbability: 'Risk Evaluation Probability:',
      riskEvaluationSeverityScore: 'Risk Evaluation Severity Score:',
      riskEvaluationResultingRiskIndex: 'Risk Evaluation Resulting Risk Index:',
      residualRiskEvaluationProbability: 'Residual Risk Evaluation Probability:',
      residualRiskSeverityScore: 'Residual Risk Severity Score:',
      residualRiskResultingRiskIndex: 'Residual Risk Resulting Risk Index:',
      controls: 'Controls:',
      hazardLabel: 'Hazard:',
      harmLabel: 'Harm:',
      export: 'Export Risks',
    },
  },

  controls: {
    form: {
      controlUrlCopied: 'Control url copied to clipboard',
      fieldControlId: 'Control Id',
      fieldTitle: 'Title',
      fieldDesign: 'Design',
      fieldSource: 'Source',
      createControlTitle: 'Create Control',
      editControlTitle: 'Edit Control',
      controlSaved: 'The Control has been saved successfully',
      controlDeleted: 'The Control has been deleted successfully',
      controlCreated: 'The Control has been created successfully',
    },
    list: {
      title: 'Controls',
      fieldSearch: 'Search',
      deleteConfirmation: 'Are you sure you want to delete this control?',
      idHeader: 'ID',
      titleHeader: 'Title',
      designHeader: 'Design',
      sourceHeader: 'Source',
    },
  },

  riskControls: {
    status: {
      effective: 'Effective',
      nonEffective: 'Non Effective',
      requiresAction: 'Requires Action',
      requireAction: 'Require Action',
    },
    form: {
      createFormTitle: 'Create Risk Control',
      editFormTitle: 'Edit Risk Control',
      copyUrlToShare: 'Copy Url to Share',
      riskControlUrlCopied: 'Risk Control url copied to clipboard',
      fieldIsRequired: 'This field is required',
      fieldRisks: 'Risks',
      fieldControl: 'Control',
      fieldOwner: 'Owner',
      fieldFrequency: 'Frequency',
      fieldLastCheckedDate: 'Last Checked Date',
      controlSourceLabel: 'Open control source',
      saveButtonLabel: 'Save',
      addButtonLabel: 'Add',
      createButtonLabel: 'Create',
      riskControlCreated: 'The Risk Control has been created successfully',
      riskControlSaved: 'The Risk Control has been saved successfully',
      riskControlDeleted: 'The Risk Control has been deleted successfully',
      riskControlChecked: 'The Risk Control has been updated successfully',
    },
    list: {
      title: 'Risk Controls',
      deletePrompt: 'Are you sure you want to delete this risk control?',
      clickToOpen: 'Click to open',
      deleteTooltip: 'Delete',
      editTooltip: 'Edit',
      markAsCheckedTooltip: 'Mark as checked',
      fieldSource: 'Source',
      fieldDesign: 'Design',
      fieldId: 'ID',
      fieldStatus: 'Status',
      fieldSearch: 'Search',
      fieldDueFrom: 'Due From',
      fieldDueTo: 'Due To',
      fieldInterval: 'Interval',
      fieldLastCheck: 'Last Check',
      fieldNextCheck: 'Next Check',
      fieldOwner: 'Owner',
      fieldControl: 'Control',
      risksTooltipTitle: 'Risks:',
      controlTooltipTitle: 'Control:',

    },
  },

  documents: {
    type: {
      policy: 'Policy',
      document: 'Organisational Objective',
      report: 'Report Template',
    },
    list: {
      title: 'Documents',
      updatedOn: (time: string) => `Updated ${time}`,
      lastUpdated: (time: string) => `Last updated: ${time}`,
      createdBy: (name: string) => `Created by: ${name}`,
    },
    form: {
      titlePlaceholder: 'Document title',
    },
    edit: {
      documentSaved: 'The document has been saved successfully',
      viewComments: 'View Comments',
      hideComments: 'Hide Comments',
      deleteDocument: 'Delete',
      editPage: 'Edit Page',
      documentDeleted: 'The document has been deleted successfully',
      deleteConfirmation: 'Are you sure you want to delete this document?',
      unsavedChanges: 'You have unsaved changes, are you sure you want to leave?',
      changeParent: 'Parent Document',
      downloadDocument: 'Download Document',
    },
    create: {
      createButton: 'Create',
      buttonAddSubDocument: 'Add sub-document',
      documentCreated: 'The document has been created successfully',
      createDocumentDescription: (documentType: string = 'document') => `In order to create a new ${documentType?.toLowerCase()} fill the title and click on the "create" button.`,
      createDocumentTitle: (documentType: string = 'document') => `Create ${documentType}`,
    },
  },

  dashboard: {
    corrections: 'Corrections',
    incidents: 'Events',
    riskControls: 'Risk Controls',
    risks: 'Risks',
    tasks: 'Tasks',
    tasksHighPriority: 'High Priority',
    tasksRequiringAction: 'Requiring Action',
    documents: 'Documents',
    reports: 'Reports',
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    updatedAt: (day: number, month: string, year: number) => `Updated ${month} ${day}, ${year}`,
  },

  team: {
    title: 'Team members',
    detailsLabel: 'Details',
    jobTitleLabel: 'Job title',
    openQueriesLabel: 'Open queries',
    notificationsLabel: 'Email notifications',
    unknownLabel: 'Unknown job title',
    buttonInvite: 'Invite team members',
  },

  invite: {
    dialog: {
      title: 'Invite team members',
      placeholderEmail: 'Email, comma separated',
      buttonInvite: 'Send invite',
      invitationSentInfo: (email: string) => `Invitation to ${email} sent`,
    },

    title: 'Team invitation',
    subtitle: (company?: string) => company ? `You've been invited to join ${company}'s team` : 'You\'ve been invited to join a team',
    buttonAcceptInvitation: 'Create account',
  },

  menu: {
    home: 'Home',
    tasks: 'Tasks',
    allTasks: 'All Tasks',
    monitoring: 'Monitoring',
    reports: 'Reports',
    risks: 'Risks',
    controls: 'Controls',
    incidents: 'Incidents',
    documents: 'Documents',
    help: 'Help',
    faq: 'FAQ',
    dashboard: 'Dashboard',
    managementSystem: 'Management System',
    companies: 'Companies',
    users: 'Users',
    team: 'Team',
  },

  filters: {
    search: 'Search',
    label: 'Filters',
    fieldStatus: 'Status',
    fieldDateFrom: 'Date from',
    fieldDateTo: 'Date to',
    buttonSort: 'Sort',
    buttonFilter: 'Filter',

    transaction: {
      fieldScope: 'Scope',
    },
  },

  dialogs: {
    buttonCancel: 'Cancel',
  },

  forms: {
    typeLabel: 'Type',
    titleLabel: 'Title',
    fieldEmail: 'Email',
    fieldPassword: 'Password',
    fieldPhone: 'Phone',
    fieldCountry: 'Country',
    fieldCompany: 'Company',
    fieldTitle: 'Title',
    fieldSubtitle: 'Subtitle',
    fieldDescription: 'Description',
    copyUrlToShare: 'Copy Url to Share',

    signup: {
      fieldRepeatPassword: 'Repeat Password',
    },

    company: {
      fieldName: 'Name',
      fieldAccountManager: 'Account Manager',
      fieldPrimaryContact: 'Primary Contact',
      fieldPreferredLanguage: 'Preferred Language',
    },

    user: {
      fieldFirstName: 'Name',
      fieldLastName: 'Surname',
      fieldType: 'Type',
      fieldJobTitle: 'Job title',
      fieldAdmin: 'Admin',
      fieldPicture: 'Upload picture',

      fieldEmailDisabledHelper: 'Please contact support to change the email.',
    },

    validations: {
      required: 'This Field is Required',
      invalidDate: 'Date is Invalid',
      invalidEmail: 'Email is Invalid',
      invalidPhone: 'Phone number is Invalid',
      invalidPassword: 'Password is Invalid. At least 1 number is required.',
      invalidFileSize: (max: string) => `File is too big. Maximum is ${max}`,
      invalidFileType: 'File type is not valid',
      minLength: (length: number | string) => `Need to have at least ${length} characters`,
      maxLength: (length: number | string) => `Can't have more than ${length} characters`,

      memberInvitationAllEmailsValid: 'One or more emails is invalid.',
      companyContactRequired: 'Company requires an account manager and primary contact',
    },
    delete: 'Delete',
    save: 'Save',
    attach: 'Attach',
    upload: 'Upload',
    saveChanges: 'Save changes',
  },

  routes: {
    tasks: 'Tasks',
    faq: 'FAQ',
    tasksStatus: 'Status',
    monitoring: 'Monitoring',
    reports: 'Reports',
    risks: 'Risks',
    createRisk: 'Create Risk',
    editRisk: 'Edit Risk',
    controls: 'Controls',
    createControl: 'Create Control',
    editControl: 'Edit Control',
    riskControls: 'Risk Controls',
    createRiskControl: 'Create Risk Control',
    editRiskControl: 'Edit Risk Control',
    incidents: 'Incidents',
    fulfillIncident: 'Fulfill Incident',
    createIncident: 'Create Incident',
    previewIncidentTemplate: 'Preview Incident Report',
    documents: 'Documents',
    editDocument: (documentTitle: string) => documentTitle,
    viewDocument: (documentTitle: string) => documentTitle,
    help: 'Help',
    dashboard: 'Dashboard',
    managementSystem: 'Management System',
    companies: 'Companies',
    createCompany: 'Create Company',
    createUser: 'Create User',
    createTask: 'Create Task',
    editTask: 'Edit Task',
    editCompany: (company: string) => `Edit ${company}`,
    editUser: (user: string) => `Edit ${user}`,
    fulfillReport: 'Fulfill Report',
    createReport: 'Create Report',
    previewReportTemplate: 'Preview Report',
    home: 'Home',
    impersonateUser: 'Impersonate',
    invite: 'Invitation',
    login: 'Login',
    myAccount: 'My Account',
    profile: 'Profile',
    resetPassword: 'Reset Password',
    signup: 'Signup',
    nlSignup: 'Signup',
    users: 'Users',
    welcome: 'Welcome',
    team: 'Team',
  },

  languages: {
    english: 'English',
    french: 'French',
    german: 'German',
  },

  countries: {
    [Country.Andorra]: 'Andorra',
    [Country.UnitedArabEmirates]: 'United Arab Emirates',
    [Country.Afghanistan]: 'Afghanistan',
    [Country.AntiguaAndBarbuda]: 'Antigua and Barbuda',
    [Country.Anguilla]: 'Anguilla',
    [Country.Albania]: 'Albania',
    [Country.Armenia]: 'Armenia',
    [Country.Angola]: 'Angola',
    [Country.Antarctica]: 'Antarctica',
    [Country.Argentina]: 'Argentina',
    [Country.AmericanSamoa]: 'American Samoa',
    [Country.Austria]: 'Austria',
    [Country.Australia]: 'Australia',
    [Country.Aruba]: 'Aruba',
    [Country.AlandIslands]: 'Alland Islands',
    [Country.Azerbaijan]: 'Azerbaijan',
    [Country.BosniaAndHerzegovina]: 'Bosnia and Herzegovina',
    [Country.Barbados]: 'Barbados',
    [Country.Bangladesh]: 'Bangladesh',
    [Country.Belgium]: 'Belgium',
    [Country.BurkinaFaso]: 'Burkina Faso',
    [Country.Bulgaria]: 'Bulgaria',
    [Country.Bahrain]: 'Bahrain',
    [Country.Burundi]: 'Burundi',
    [Country.Benin]: 'Benin',
    [Country.BonaireSintEustatiusSaba]: 'Bonaire, Sint Eustatius and Saba',
    [Country.SaintBarthelemy]: 'Saint Barthelemy',
    [Country.Bermuda]: 'Bermuda',
    [Country.BruneiDarussalam]: 'Brunei Darussalam',
    [Country.Bolivia]: 'Bolivia',
    [Country.Brazil]: 'Brazil',
    [Country.Bahamas]: 'Bahamas',
    [Country.Bhutan]: 'Bhutan',
    [Country.BouvetIsland]: 'Bouvet Island',
    [Country.Botswana]: 'Botswana',
    [Country.Belarus]: 'Belarus',
    [Country.Belize]: 'Belize',
    [Country.Canada]: 'Canada',
    [Country.CocosKeelingIslands]: 'Cocos (Keeling) Islands',
    [Country.CentralAfricanRepublic]: 'Central African Republic',
    [Country.Congo]: 'Congo',
    [Country.CongoDemocraticRepublic]: 'Congo, Democratic Republic of the',
    [Country.Switzerland]: 'Switzerland',
    [Country.CoteDIvoire]: 'Cote d\'Ivoire',
    [Country.CookIslands]: 'Cook Islands',
    [Country.Chile]: 'Chile',
    [Country.Cameroon]: 'Cameroon',
    [Country.China]: 'China',
    [Country.Colombia]: 'Colombia',
    [Country.CostaRica]: 'Costa Rica',
    [Country.Cuba]: 'Cuba',
    [Country.CapeVerde]: 'Cape Verde',
    [Country.Curacao]: 'Curacao',
    [Country.ChristmasIsland]: 'Christmas Island',
    [Country.Cyprus]: 'Cyprus',
    [Country.CzechRepublic]: 'Czech Republic',
    [Country.Germany]: 'Germany',
    [Country.Djibouti]: 'Djibouti',
    [Country.Denmark]: 'Denmark',
    [Country.Dominica]: 'Dominica',
    [Country.DominicanRepublic]: 'Dominican Republic',
    [Country.Algeria]: 'Algeria',
    [Country.Ecuador]: 'Ecuador',
    [Country.Estonia]: 'Estonia',
    [Country.Egypt]: 'Egypt',
    [Country.WesternSahara]: 'Western Sahara',
    [Country.Eritrea]: 'Eritrea',
    [Country.Spain]: 'Spain',
    [Country.Ethiopia]: 'Ethiopia',
    [Country.Finland]: 'Finland',
    [Country.Fiji]: 'Fiji',
    [Country.FalklandIslands]: 'Falkland Islands (Malvinas)',
    [Country.Micronesia]: 'Micronesia, Federated States of',
    [Country.FaroeIslands]: 'Faroe Islands',
    [Country.France]: 'France',
    [Country.Gabon]: 'Gabon',
    [Country.UnitedKingdom]: 'United Kingdom',
    [Country.Grenada]: 'Grenada',
    [Country.Georgia]: 'Georgia',
    [Country.FrenchGuiana]: 'French Guiana',
    [Country.Guernsey]: 'Guernsey',
    [Country.Ghana]: 'Ghana',
    [Country.Gibraltar]: 'Gibraltar',
    [Country.Greenland]: 'Greenland',
    [Country.Gambia]: 'Gambia',
    [Country.Guinea]: 'Guinea',
    [Country.Guadeloupe]: 'Guadeloupe',
    [Country.EquatorialGuinea]: 'Equatorial Guinea',
    [Country.Greece]: 'Greece',
    [Country.SouthGeorgiaAndSandwichIsl]: 'South Georgia and the South Sandwich Islands',
    [Country.Guatemala]: 'Guatemala',
    [Country.Guam]: 'Guam',
    [Country.GuineaBissau]: 'Guinea-Bissau',
    [Country.Guyana]: 'Guyana',
    [Country.HongKong]: 'Hong Kong',
    [Country.HeardIslandMcdonaldIslands]: 'Heard Island and McDonald Islands',
    [Country.Honduras]: 'Honduras',
    [Country.Croatia]: 'Croatia',
    [Country.Haiti]: 'Haiti',
    [Country.Hungary]: 'Hungary',
    [Country.Indonesia]: 'Indonesia',
    [Country.Ireland]: 'Ireland',
    [Country.Israel]: 'Israel',
    [Country.IsleOfMan]: 'Isle of Man',
    [Country.India]: 'India',
    [Country.BritishIndianOceanTerritory]: 'British Indian Ocean Territory',
    [Country.Iraq]: 'Iraq',
    [Country.Iran]: 'Iran, Islamic Republic of',
    [Country.Iceland]: 'Iceland',
    [Country.Italy]: 'Italy',
    [Country.Jersey]: 'Jersey',
    [Country.Jamaica]: 'Jamaica',
    [Country.Jordan]: 'Jordan',
    [Country.Japan]: 'Japan',
    [Country.Kenya]: 'Kenya',
    [Country.Kyrgyzstan]: 'Kyrgyzstan',
    [Country.Cambodia]: 'Cambodia',
    [Country.Kiribati]: 'Kiribati',
    [Country.Comoros]: 'Comoros',
    [Country.SaintKittsAndNevis]: 'Saint Kitts and Nevis',
    [Country.KoreaDemocraticPeoplesRepublic]: 'Korea, Democratic People\'s Republic of',
    [Country.Korea]: 'Korea, Republic of',
    [Country.Kuwait]: 'Kuwait',
    [Country.CaymanIslands]: 'Cayman Islands',
    [Country.Kazakhstan]: 'Kazakhstan',
    [Country.LaoPeoplesDemocraticRepublic]: 'Lao People\'s Democratic Republic',
    [Country.Lebanon]: 'Lebanon',
    [Country.SaintLucia]: 'Saint Lucia',
    [Country.Liechtenstein]: 'Liechtenstein',
    [Country.SriLanka]: 'Sri Lanka',
    [Country.Liberia]: 'Liberia',
    [Country.Lesotho]: 'Lesotho',
    [Country.Lithuania]: 'Lithuania',
    [Country.Luxembourg]: 'Luxembourg',
    [Country.Latvia]: 'Latvia',
    [Country.LibyanArabJamahiriya]: 'Libya',
    [Country.Morocco]: 'Morocco',
    [Country.Monaco]: 'Monaco',
    [Country.Moldova]: 'Moldova, Republic of',
    [Country.Montenegro]: 'Montenegro',
    [Country.SaintMartin]: 'Saint Martin (French part)',
    [Country.Madagascar]: 'Madagascar',
    [Country.MarshallIslands]: 'Marshall Islands',
    [Country.Macedonia]: 'Macedonia, the Former Yugoslav Republic of',
    [Country.Mali]: 'Mali',
    [Country.Myanmar]: 'Myanmar',
    [Country.Mongolia]: 'Mongolia',
    [Country.Macao]: 'Macao',
    [Country.NorthernMarianaIslands]: 'Northern Mariana Islands',
    [Country.Martinique]: 'Martinique',
    [Country.Mauritania]: 'Mauritania',
    [Country.Montserrat]: 'Montserrat',
    [Country.Malta]: 'Malta',
    [Country.Mauritius]: 'Mauritius',
    [Country.Maldives]: 'Maldives',
    [Country.Malawi]: 'Malawi',
    [Country.Mexico]: 'Mexico',
    [Country.Malaysia]: 'Malaysia',
    [Country.Mozambique]: 'Mozambique',
    [Country.Namibia]: 'Namibia',
    [Country.NewCaledonia]: 'New Caledonia',
    [Country.Niger]: 'Niger',
    [Country.NorfolkIsland]: 'Norfolk Island',
    [Country.Nigeria]: 'Nigeria',
    [Country.Nicaragua]: 'Nicaragua',
    [Country.Netherlands]: 'Netherlands',
    [Country.Norway]: 'Norway',
    [Country.Nepal]: 'Nepal',
    [Country.Nauru]: 'Nauru',
    [Country.Niue]: 'Niue',
    [Country.NewZealand]: 'New Zealand',
    [Country.Oman]: 'Oman',
    [Country.Panama]: 'Panama',
    [Country.Peru]: 'Peru',
    [Country.FrenchPolynesia]: 'French Polynesia',
    [Country.PapuaNewGuinea]: 'Papua New Guinea',
    [Country.Philippines]: 'Philippines',
    [Country.Pakistan]: 'Pakistan',
    [Country.Poland]: 'Poland',
    [Country.SaintPierreAndMiquelon]: 'Saint Pierre and Miquelon',
    [Country.Pitcairn]: 'Pitcairn',
    [Country.PuertoRico]: 'Puerto Rico',
    [Country.PalestinianTerritory]: 'Palestine, State of',
    [Country.Portugal]: 'Portugal',
    [Country.Palau]: 'Palau',
    [Country.Paraguay]: 'Paraguay',
    [Country.Qatar]: 'Qatar',
    [Country.Reunion]: 'Reunion',
    [Country.Romania]: 'Romania',
    [Country.Serbia]: 'Serbia',
    [Country.RussianFederation]: 'Russian Federation',
    [Country.Rwanda]: 'Rwanda',
    [Country.SaudiArabia]: 'Saudi Arabia',
    [Country.SolomonIslands]: 'Solomon Islands',
    [Country.Seychelles]: 'Seychelles',
    [Country.Sudan]: 'Sudan',
    [Country.Sweden]: 'Sweden',
    [Country.Singapore]: 'Singapore',
    [Country.SaintHelena]: 'Saint Helena',
    [Country.Slovenia]: 'Slovenia',
    [Country.SvalbardAndJanMayen]: 'Svalbard and Jan Mayen',
    [Country.Slovakia]: 'Slovakia',
    [Country.SierraLeone]: 'Sierra Leone',
    [Country.SanMarino]: 'San Marino',
    [Country.Senegal]: 'Senegal',
    [Country.Somalia]: 'Somalia',
    [Country.Suriname]: 'Suriname',
    [Country.SouthSudan]: 'South Sudan',
    [Country.SaoTomeAndPrincipe]: 'Sao Tome and Principe',
    [Country.ElSalvador]: 'El Salvador',
    [Country.SintMaarten]: 'Sint Maarten (Dutch part)',
    [Country.SyrianArabRepublic]: 'Syrian Arab Republic',
    [Country.Swaziland]: 'Swaziland',
    [Country.TurksAndCaicosIslands]: 'Turks and Caicos Islands',
    [Country.Chad]: 'Chad',
    [Country.FrenchSouthernTerritories]: 'French Southern Territories',
    [Country.Togo]: 'Togo',
    [Country.Thailand]: 'Thailand',
    [Country.Tajikistan]: 'Tajikistan',
    [Country.Tokelau]: 'Tokelau',
    [Country.TimorLeste]: 'Timor-Leste',
    [Country.Turkmenistan]: 'Turkmenistan',
    [Country.Tunisia]: 'Tunisia',
    [Country.Tonga]: 'Tonga',
    [Country.Turkey]: 'Turkey',
    [Country.TrinidadAndTobago]: 'Trinidad and Tobago',
    [Country.Tuvalu]: 'Tuvalu',
    [Country.Taiwan]: 'Taiwan, Province of China',
    [Country.Tanzania]: 'United Republic of Tanzania',
    [Country.Ukraine]: 'Ukraine',
    [Country.Uganda]: 'Uganda',
    [Country.UnitedStates]: 'United States',
    [Country.UnitedStatesOutlyingIslands]: 'United States Minor Outlying Islands',
    [Country.Uruguay]: 'Uruguay',
    [Country.Uzbekistan]: 'Uzbekistan',
    [Country.HolySeeVaticanCityState]: 'Holy See (Vatican City State)',
    [Country.SaintVincentAndGrenadines]: 'Saint Vincent and the Grenadines',
    [Country.Venezuela]: 'Venezuela',
    [Country.VirginIslandsBritish]: 'British Virgin Islands',
    [Country.VirginIslandsUS]: 'US Virgin Islands',
    [Country.Vietnam]: 'Vietnam',
    [Country.Vanuatu]: 'Vanuatu',
    [Country.WallisAndFutuna]: 'Wallis and Futuna',
    [Country.Samoa]: 'Samoa',
    [Country.Kosovo]: 'Kosovo',
    [Country.Yemen]: 'Yemen',
    [Country.Mayotte]: 'Mayotte',
    [Country.SouthAfrica]: 'South Africa',
    [Country.Zambia]: 'Zambia',
    [Country.Zimbabwe]: 'Zimbabwe',
  },
};
