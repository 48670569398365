import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';
import { mapDocument } from './documentsClient.formatter';

const DocumentsApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getDocuments = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/`,
      method: 'GET',
      params: {
        category: params.category,
        search: params.search,
        limit: params.limit || DEFAULT_QUERY_LIMIT,
      },
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map((document: any) => mapDocument(document)),
    },
  }));
};

const getDocument = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<any>) => ({
    ...data,
    data: mapDocument(data.data),
  }));
};

const createDocument = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/`,
      method: 'POST',
      data: data,
    },
  });
};

const editDocument = (id: string | number, data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchDocument = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteDocument = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${DocumentsApiBaseUrl}/page/${id}/`,
      method: 'DELETE',
    },
  });
};

export const documentsClient = {
  createDocument,
  deleteDocument,
  editDocument,
  patchDocument,
  getDocuments,
  getDocument,
};
