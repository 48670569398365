import React, { FC, useContext } from 'react';
import { Chip, Divider, Grid, Typography } from '@mui/material';
import { LayoutContext, LocalizationContext } from 'contexts';
import { defineChipColor } from 'helpers/helpers';
import { getRiskControlStatusLabel } from 'helpers/riskControl';
import { getEventStatusLabel } from 'helpers/event';
import { RiskControlStatus } from 'clients/riskControls/riskControlsClient.types';

export const GridOneRow: FC<{ subElements: any, title: string }> = ({ subElements, title }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { isMobile } = useContext(LayoutContext);

  return (
    <Grid container direction={isMobile ? 'column' : 'row'}>
      {subElements.map((subElement : { title: any, count: number }, index: number) => (
        <>
          <Grid item xs={4} key={subElement.title}>
            <Grid container direction="column" display="flex" alignItems="center" justifyContent="center" rowSpacing={ { xs: 0, sm: 2 }}>
              <Grid item>
                <Typography variant={isMobile ? 'h6' : 'h4'} fontWeight={400}>{subElement.count}</Typography>
              </Grid>
              <Grid item>
                <Typography variant={isMobile ? 'subtitle2' : 'body1'}>{title}</Typography>
              </Grid>
              <Grid item>
                <Chip
                  size="small"
                  sx={{ p: 0 }}
                  variant="outlined"
                  color={defineChipColor(subElement.title)}
                  label={
                    <Typography variant="caption">
                      {
                        Object.values(RiskControlStatus).includes(subElement.title as unknown as RiskControlStatus) ?
                          getRiskControlStatusLabel(dictionary, subElement.title) :
                          getEventStatusLabel(dictionary, subElement.title)
                      }
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {(index + 1 !== subElements.length) && !isMobile && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginRight:'-1px', height: '40px', marginTop: 'auto', marginBottom: 'auto' }}
            />
          )}
        </>
      ))}
    </Grid>
  );
};
