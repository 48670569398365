import React, { useContext } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { RiskControlStatus } from 'clients/riskControls/riskControlsClient.types';

export const RiskControlStatusSelect = ({ onChange, allowAll, ...props }: any) => {

  const { dictionary } = useContext(LocalizationContext);

  return (
    <TextField
      label="Status"
      {...props}
      onChange={e => onChange({ ...e, target: { ...e.target, value: e.target.value === 'all' ? undefined : e.target.value } })}
      select
    >
      {allowAll && <MenuItem value="all">{dictionary.all}</MenuItem>}
      <MenuItem key={RiskControlStatus.Effective} value={RiskControlStatus.Effective}>{dictionary.riskControls.status.effective}</MenuItem>
      <MenuItem key={RiskControlStatus.NonEffective} value={RiskControlStatus.NonEffective}>{dictionary.riskControls.status.nonEffective}</MenuItem>
      <MenuItem key={RiskControlStatus.RequiresAction} value={RiskControlStatus.RequiresAction}>{dictionary.riskControls.status.requiresAction}</MenuItem>
    </TextField>
  );
};
