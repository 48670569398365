import { AxiosResponse } from 'axios';
import { request } from 'clients/baseClient';
import { mapCompanyData, mapCompanyCrudData } from './companyClient.formatter';
import { Company, CompanyApi, CompanyCreate, CompanyCrud, CompanyEdit, GetCompaniesParams } from './companyClient.types';

const companiesApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getCompanies = (params: GetCompaniesParams): Promise<AxiosResponse<Company[]>> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/`,
      method: 'GET',
      params: {
        search: params.search,
      },
    },
  }).then((data: AxiosResponse<CompanyApi[]>) => ({
    ...data,
    data: data.data.map(mapCompanyData),
  }));
};

const getCompany = (params: { id: number | string }): Promise<AxiosResponse<Company>> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/${params.id}/`,
      method: 'GET',
    },
  }).then((data: AxiosResponse<CompanyApi>) => ({
    ...data,
    data: mapCompanyData(data.data),
  }));
};

const createCompany = (data: CompanyCreate): Promise<AxiosResponse<Company>> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/`,
      method: 'POST',
      data: mapCompanyCrudData(data),
    },
  });
};

const editCompany = (data: CompanyEdit): Promise<AxiosResponse<Company>> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/${data.id}/`,
      method: 'PUT',
      data: mapCompanyCrudData(data),
    },
  });
};

const patchCompany = (data: CompanyCrud): Promise<AxiosResponse<CompanyEdit>> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/${data.id}/`,
      method: 'PATCH',
      data: mapCompanyCrudData(data),
    },
  });
};

const deleteCompany = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${companiesApiBaseUrl}/companies/${params.id}/`,
      method: 'DELETE',
    },
  });
};

export const companyClient = {
  createCompany,
  deleteCompany,
  editCompany,
  patchCompany,
  getCompanies,
  getCompany,
};
