import React, { FC, useContext, useCallback, useMemo } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import {
  TableRow,
  TableCell,
  Typography,
  MenuList,
  MenuItem,
  Alert,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { cacheKeys } from 'config';
import { LocalizationContext, LayoutContext, DialogContext, UserContext } from 'contexts';
import { usersClient } from 'clients/users/usersClient';
import { User } from 'clients/users/userClient.types';
import { useMe } from 'shared/hooks/useMe';
import { IconButtonDropdown } from 'shared/components/interactive/ButtonDropdown/IconButtonDropdown';

export const UserRow: FC<User> = (user) => {
  
  const { dictionary } = useContext(LocalizationContext);
  const { asyncConfirmation } = useContext(DialogContext);
  const { genericError, changesSaved } = useContext(LayoutContext);
  const { user: me } = useMe();
  const { logout } = useContext(UserContext);
  
  const isOwnAccount = useMemo(() => {
    return me?.id === user.id;
  }, [user, me]);
  const queryClient = useQueryClient();

  const deleteUser = useMutation(usersClient.deleteUser, {
    mutationKey: cacheKeys.users.deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      queryClient.removeQueries([cacheKeys.users.getUser, {
        id: user.id,
      }]);
      changesSaved();

      if (isOwnAccount) {
        logout();
      }
    },
    onError: () => {
      genericError();
    },
  });

  const onDelete = useCallback(async () => {
    const userConfirmed = await asyncConfirmation({
      title: isOwnAccount ? dictionary.user.edit.deleteOwnAccountConfirmation : dictionary.user.edit.deleteConfirmation,
      content: isOwnAccount ? <Alert severity="error">{dictionary.confirmation.defaultContent}</Alert> : null,
    });

    if (!user || !userConfirmed) {
      return false;
    }
    return deleteUser.mutate({
      id: user.id,
    });
  }, [user, deleteUser, asyncConfirmation, dictionary, isOwnAccount]);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Typography variant="subtitle1">{user.displayName}</Typography>
        <Typography color="primary" variant="subtitle2">{user.email}</Typography>
      </TableCell>
      <TableCell>{user.jobTitle || dictionary.team.unknownLabel}</TableCell>
      <TableCell align="right">
        <IconButtonDropdown
          placement="bottom-start"
          buttonProps={{
            color: 'secondary',
          }}
          content={({ closeDropdown }) => (
            <MenuList>
              <MenuItem
                onClick={() => {
                  onDelete();
                  closeDropdown();
                }}
              >
                {dictionary.user.edit.buttonDelete}
              </MenuItem>
            </MenuList>
          )}
        >
          <MoreVert />
        </IconButtonDropdown>
      </TableCell>
    </TableRow>
  );
};
