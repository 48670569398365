import { AxiosResponse } from 'axios';

import { DEFAULT_QUERY_LIMIT } from 'helpers/query';
import { request } from 'clients/baseClient';

const RisksApiBaseUrl = process.env.REACT_APP_NAALA_API_URL;

const getRisks = (params: any = {}): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/`,
      method: 'GET',
      params: {
        search: params.search,
        residual_risk_index__lte: params.residual_risk_index__lte,
        residual_risk_index__gte: params.residual_risk_index__gte,
        limit: params.limit || DEFAULT_QUERY_LIMIT,
      },
    },
  });
};

const getRisk = (id: number | string): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/${id}/`,
      method: 'GET',
    },
  });
};

const getRisksFile = (): Promise<AxiosResponse<Blob>> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/risk_export/`,
      method: 'GET',
      responseType: 'blob',
    },
  });
};

const createRisk = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/`,
      method: 'POST',
      data: data,
    },
  });
};

const editRisk = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/${data.id}/`,
      method: 'PUT',
      data: data,
    },
  });
};

const patchRisk = (data: any): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/${data.id}/`,
      method: 'PATCH',
      data: data,
    },
  });
};

const deleteRisk = (id: number | string ): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${RisksApiBaseUrl}/risks/${id}/`,
      method: 'DELETE',
    },
  });
};

export const risksClient = {
  createRisk,
  deleteRisk,
  editRisk,
  patchRisk,
  getRisks,
  getRisk,
  getRisksFile,
};
