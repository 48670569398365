import React, { FC, useContext } from 'react';

import { BorderColorOutlined, ChatOutlined, Delete } from '@mui/icons-material';
import { Grid, IconButton, Paper, Box, Tooltip } from '@mui/material';
import { LocalizationContext } from 'contexts';
import { DownloadButton } from 'shared/components/form/DownloadButton/DownloadButton';


export const DocumentViewToolbar: FC<any> = ({ onCommentsClick, showComments, onEditPageClick, onDelete, onDownload }) => {
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Paper sx={{ paddingX: '20px', paddingY: '10px' }} className="no-print">
      <Grid container>
        <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center">
          <Box px="10px">
            <Tooltip title={dictionary.documents.edit.editPage}>
              <IconButton onClick={onEditPageClick}>
                <BorderColorOutlined fontSize="medium"/>
              </IconButton>
            </Tooltip>
          </Box>
          <Box px="10px" sx={{ borderLeft: '1px solid #C5CAD7', opacity: showComments ? 1 : 0.3 }}>
            <Tooltip title={showComments ? dictionary.documents.edit.hideComments : dictionary.documents.edit.viewComments }>
              <IconButton onClick={onCommentsClick}>
                <ChatOutlined fontSize="medium"/>
              </IconButton>
            </Tooltip>
          </Box>
          <Box px="10px" sx={{ borderLeft: '1px solid #C5CAD7' }}>
            <Tooltip title={dictionary.documents.edit.deleteDocument}>
              <IconButton onClick={onDelete}>
                <Delete fontSize="medium"/>
              </IconButton>
            </Tooltip>
          </Box>
          <Box px="10px" sx={{ borderLeft: '1px solid #C5CAD7' }}>
            <DownloadButton onClick={onDownload} tooltipTitle={dictionary.documents.edit.downloadDocument} />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
