export enum ReportStatus {
  Draft = 'draft',
  InReview = 'in_review',
  Approved = 'approved',
};

export enum ActivityLogAction {
  Created = 'created',
  Updated = 'updated',
  AssignedTo = 'assigned_to',
  InReview = 'in_review',
  Approved = 'approved'
};