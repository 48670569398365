import React, { Fragment, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Grid, Button, Typography } from '@mui/material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';

import { Footer } from 'shared/components/layout/Footer/Footer';


export const Welcome = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Fragment>
      <Container maxWidth="xl">
        <Box mt={10} height="60vh">
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Typography component="h1" variant="h2">
              {dictionary.welcome.title}
            </Typography>
            <Box mt={4}/>
            <Button size="large" variant="outlined" onClick={() => navigate(routes.home)}>
              {dictionary.welcome.buttonStart}
            </Button>
          </Grid>
        </Box>
      </Container>
      <Footer/>
    </Fragment>
  );
};
