import { Dictionary } from 'contexts/LocalizationContext/types';
import { ActivityLogAction } from 'clients/reports/reportsClient.types';
import { EventActivityLogAction } from 'clients/events/eventsClient.types';


export const getActionLabel = (dictionary: Dictionary) => ({
  [ActivityLogAction.Approved]: dictionary.activityLog.approved,
  [ActivityLogAction.AssignedTo]: dictionary.activityLog.assignedTo,
  [ActivityLogAction.Created]: dictionary.activityLog.created,
  [ActivityLogAction.InReview]: dictionary.activityLog.inReview,
  [ActivityLogAction.Updated]: dictionary.activityLog.updated,
  [EventActivityLogAction.CorrectionPlanned]: dictionary.activityLog.correctionPlanned,
  [EventActivityLogAction.CorrectionInProgress]: dictionary.activityLog.correctionInProgress,
  [EventActivityLogAction.Closed]: dictionary.activityLog.closed,
});
