import React, { FC, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import { CompanyContext, LocalizationContext } from 'contexts';
import { useUsers } from 'shared/hooks/useUsers';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { UserRow } from 'views/LoggedIn/Team/components/UserRow/UserRow';

export const TeamList: FC<any> = () => {

  const { dictionary } = useContext(LocalizationContext);
  const { company } = useContext(CompanyContext);

  const { users, status } = useUsers({
    company: company?.id,
  });

  if (status === 'loading' || !users) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="team table">
        <TableHead>
          <TableRow>
            <TableCell>{dictionary.team.detailsLabel}</TableCell>
            <TableCell>{dictionary.team.jobTitleLabel}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map(user => (
            <UserRow
              key={user.id}
              {...user}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
