import { useQuery } from 'react-query';

import { cacheKeys } from 'config';
import { eventsClient } from 'clients/events/eventsClient';

type Options = {
  enabled: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
};

export const useEvents = (params?: any, options: Partial<Options> = defaultOptions) => {

  const { data: { data: { results: events = [], count = undefined } = {} } = {}, status: queryStatus, error } = useQuery(
    [cacheKeys.events.getEvents, params],
    () => eventsClient.getEvents(params),
    { enabled: options.enabled },
  );

  return {
    events,
    count,
    status: queryStatus,
    error,
  };
};
