import React, { FC, Fragment, useCallback, useContext } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Box, Paper, Typography } from '@mui/material';

import { routes, cacheKeys } from 'config';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { usersClient } from 'clients/users/usersClient';
import { UserCrud } from 'clients/users/userClient.types';
import { useBreadcrumbs } from 'shared/hooks/useBreadcrumbs';
import { Breadcrumbs } from 'shared/components/layout/Breadcrumbs/Breadcrumbs';
import { Footer } from 'shared/components/layout/Footer/Footer';
import { UserForm } from './partials/UserForm';

export const CreateUser: FC = () => {

  const navigate = useNavigate();
  const { genericError, changesSaved } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);
  const breadcrumbs = useBreadcrumbs('createUser');

  const queryClient = useQueryClient();
  const createUser = useMutation(usersClient.createUser, {
    mutationKey: cacheKeys.users.createUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(cacheKeys.users.getUsers);
      navigate(routes.editUser(data.data.id));
      changesSaved();
    },
    onError: () => {
      genericError();
    },
  });

  const onSubmit = useCallback((data: UserCrud) => {
    return createUser.mutate(data);
  }, [createUser]);

  return (
    <Fragment>
      <Container maxWidth="xl">
        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={4} mb={2}>
                <Breadcrumbs list={breadcrumbs} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={8}>
                  <Typography component="h1" variant="h2">
                    {dictionary.user.create.title}
                  </Typography>
                  <Box mt={2} p={4} component={Paper}>
                    <UserForm
                      onSubmitRequest={onSubmit}
                      onSubmitButtonText={dictionary.user.create.buttonCreate}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer/>
    </Fragment>
  );
};
