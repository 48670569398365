import React, { useCallback, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Paper,
} from '@mui/material';
import { DateRangePicker } from '@mui/lab';
import { format } from 'date-fns';

import { LayoutContext, LocalizationContext } from 'contexts';
import { addQuery } from 'helpers/query';
import { eventsClient } from 'clients/events/eventsClient';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useQueryState } from 'shared/hooks/useQueryState';
import { IncidentStatusSelect } from 'shared/components/form/IncidentStatusSelect/IncidentStatusSelect';
import { EventClassificationSelect } from 'shared/components/form/EventClassificationSelect/EventClassificationSelect';

import { DownloadButton } from 'shared/components/form/DownloadButton/DownloadButton';
import { IncidentList } from './IncidentList';


export const Incidents = () => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const location = useLocation();
  const { isMobile, genericError } = useContext(LayoutContext);
  const [search, , setSearch] = useDebouncedQueryState('search');
  const [gteDate] = useQueryState('gteDate');
  const [lteDate] = useQueryState('lteDate');
  const [status, setStatus] = useQueryState('status');
  const [classification, setClassification] = useQueryState('classification');

  const exportIncidents = useMutation(eventsClient.getIncidentsFile, {
    onSuccess: (result) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const fileURL = URL.createObjectURL(result.data);
      a.href = fileURL;
      a.download = 'incidents_overview_export' + new Date().toISOString().split('T')[0];
      a.click();
      window.URL.revokeObjectURL(fileURL);
    },
    onError: () => {
      genericError();
    },
  });

  const onDownload = useCallback(async (event: any) => {
    event.stopPropagation();
    await exportIncidents.mutate();
  }, [exportIncidents]);

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">{dictionary.incidents.list.incidentsTitle}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={dictionary.filters.search}
                  size="small"
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateRangePicker
                  inputFormat="dd.MM.yyyy"
                  onChange={(([newGteDate, newLteDate]: any) => {
                    try {
                      navigate({
                        search: addQuery(location.search, {
                          gteDate: newGteDate ? format(newGteDate, 'yyyy-MM-dd'): null,
                          lteDate: newLteDate ? format(newLteDate, 'yyyy-MM-dd'): null,
                        }),
                      });
                    } catch(e) {
                      console.error(e);
                    }
                  })}
                  value={[gteDate ? new Date(gteDate) : null, lteDate ? new Date(lteDate) : null]}
                  renderInput={(startProps, endProps) => (
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <TextField {...startProps} size="small" label={dictionary.filters.fieldDateFrom}/>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField {...endProps} size="small" label={dictionary.filters.fieldDateTo}/>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <IncidentStatusSelect
                  label={dictionary.incidents.form.fieldStatus}
                  onChange={(e: any) => setStatus(e.target.value)}
                  size="small"
                  value={status}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <EventClassificationSelect
                  label={dictionary.incidents.form.fieldClassification}
                  onChange={(e: any) => setClassification(e.target.value)}
                  size="small"
                  value={classification}
                />
              </Grid>
              <Grid item xs={12} sm="auto">
                <DownloadButton tooltipTitle={dictionary.incidents.list.tooltipTitle} onClick={onDownload}/>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <IncidentList />
      </Grid>
    </Container>
  );
};
