import React, { FC, useMemo } from 'react';
import { Avatar, Badge } from '@mui/material';
import { Image, Edit } from '@mui/icons-material';

import { imageMimes } from 'helpers/file';
import { FileUploader } from 'shared/components/form/FileUploader/FileUploader';

interface Props {
  label: string;
  value?: string | File;
  onChange: (file: File) => void;
}

export const PictureUploader: FC<Props> = ({ value, label, onChange }) => {

  const photoUrl = useMemo(() => {
    if (!value) {
      return undefined;
    }

    return typeof value === 'string' ? value : URL.createObjectURL(value);
  }, [value]);

  return (
    <FileUploader
      name="button-picture"
      label={label}
      inputProps={{
        accept: [...imageMimes].join(','),
        multiple: false,
      }}
      buttonRender={(
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={(
            <Avatar
              sx={theme => ({
                width: theme.spacing(3),
                height: theme.spacing(3),
                border: `2px solid ${theme.palette.background.paper}`,
                padding: '3px',
              })}
            >
              <Edit style={{ width: '100%', height: '100%', color: '#ececec', fill: '#ececec' }}/>
            </Avatar>
          )}
        >
          <Avatar
            alt="Profile picture"
            src={photoUrl}
            sx={theme => ({
              width: theme.spacing(8),
              height: theme.spacing(8),
              border: '1px solid transparent',
              cursor: 'pointer',
              '&:hover': {
                borderColor: theme.palette.getContrastText(theme.palette.background.paper),
              },
            })}
          >
            <Image sx={{ color: '#ececec', fill: '#ececec' }}/>
          </Avatar>
        </Badge>
      )}
      onChange={files => onChange(files[0])}
    />
  );
};
