import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { useUsers } from 'shared/hooks/useUsers';

export const UserAutocomplete: FC<any> = ({ label, TextFieldProps, ...props }) => {

  const { users } = useUsers({ limit: 1000 });

  return (
    <Autocomplete
      options={users}
      renderInput={props => <TextField {...props} label={label || 'User'} InputLabelProps={{ shrink: true }} {...TextFieldProps} />}
      getOptionLabel={(u: any) => `${u.firstName || ''} ${u.lastName || ''}`}
      {...props}
      onChange={(e, value) => props.onChange(value)}
    />
  );
};
