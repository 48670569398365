import { useContext, useMemo } from 'react';
import {
  Assignment,
  AssignmentLate,
  Campaign,
  Equalizer, HelpOutline, Home,
  InsertDriveFile,
  People,
  Policy,
  Warning,
} from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts';

export const useNavSections = () => {

  const { dictionary } = useContext(LocalizationContext);

  const sections = useMemo(() => [
    { title: dictionary.menu.home, link: routes.home, Icon: Home, enabled: true, showInNav: true, showInHome: false },
    { title: dictionary.menu.tasks, link: routes.tasks, Icon: Assignment, enabled: true, showInNav: true, showInHome: true },
    { title: dictionary.menu.reports, link: routes.reports, Icon: Equalizer, enabled: true, showInNav: true, showInHome: true },
    { title: dictionary.menu.risks, link: routes.risks, Icon: AssignmentLate, enabled: true, showInNav: true, showInHome: true },
    { title: dictionary.menu.controls, link: routes.controls, Icon: Policy, enabled: false, showInNav: true, showInHome: true },
    { title: dictionary.menu.incidents, link: routes.incidents, Icon: Campaign, enabled: true, showInNav: true, showInHome: true },
    { title: dictionary.routes.riskControls, link: routes.riskControls, Icon: Warning, enabled: true, showInNav: true, showInHome: true },
    { title: dictionary.menu.documents, link: routes.documents, Icon: InsertDriveFile, enabled: true, showInNav: true, showInHome: true },
    { title: dictionary.menu.team, link: '/team', Icon: People, enabled: true, showInNav: true, showInHome: false },
    { title: dictionary.menu.faq, link: routes.faq, Icon: HelpOutline, enabled: true, showInNav: true, showInHome: false },
  ].filter(({ enabled }) => enabled), [dictionary]);

  const navSections = useMemo(() => sections.filter(({ showInNav }) => showInNav), [sections]);
  const homeSections = useMemo(() => sections.filter(({ showInHome }) => showInHome), [sections]);

  return {
    sections,
    navSections,
    homeSections,
  };
};
