import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  TextField,
  Paper,
} from '@mui/material';
import { DateRangePicker } from '@mui/lab';
import { format } from 'date-fns';

import { LayoutContext, LocalizationContext } from 'contexts';
import { addQuery } from 'helpers/query';
import { useDebouncedQueryState } from 'shared/hooks/useDebouncedQueryState';
import { useQueryState } from 'shared/hooks/useQueryState';
import { useUsers } from 'shared/hooks/useUsers';
import { UserAutocomplete } from 'shared/components/form/UserAutocomplete/UserAutocomplete';

import { ReportList } from './ReportList';


export const Reports = () => {

  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const location = useLocation();
  const { isMobile } = useContext(LayoutContext);
  const { users } = useUsers();
  const [search, , setSearch] = useDebouncedQueryState('search');
  const [gteDate] = useQueryState('gteDate');
  const [lteDate] = useQueryState('lteDate');
  const [reporter, setReporter] = useQueryState('reporter');

  if(!users?.length) {
    return null;
  }

  return (
    <Container maxWidth="xl" sx={{ p: isMobile ? 2 : 4, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" align="center">{dictionary.reports.list.fulfilledReports}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Grid container alignItems="center" wrap={!isMobile ? 'nowrap' : undefined} spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label={dictionary.filters.search}
                  size="small"
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateRangePicker
                  inputFormat="dd.MM.yyyy"
                  onChange={(([newGteDate, newLteDate]: any) => {
                    try {
                      navigate({
                        search: addQuery(location.search, {
                          gteDate: newGteDate ? format(newGteDate, 'yyyy-MM-dd'): null,
                          lteDate: newLteDate ? format(newLteDate, 'yyyy-MM-dd'): null,
                        }),
                      });
                    } catch(e) {
                      console.error(e);
                    }
                  })}
                  value={[gteDate ? new Date(gteDate) : null, lteDate ? new Date(lteDate) : null]}
                  renderInput={(startProps, endProps) => (
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <TextField {...startProps} size="small" label={dictionary.filters.fieldDateFrom}/>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField {...endProps} size="small" label={dictionary.filters.fieldDateTo}/>
                      </Grid>
                    </Grid>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <UserAutocomplete
                  label={dictionary.reports.form.fieldReporter}
                  TextFieldProps={{ size: 'small' }}
                  value={users.find((user) => user.id.toString() === reporter)}
                  onChange={(value: any) => setReporter(value?.id)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <ReportList />
      </Grid>
    </Container>
  );
};
