import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Paper } from '@mui/material';
import { DashboardCardTitle } from './DashboardCardTitle';
import { GridMultipleRows } from './DashboardBoxGrids/GridMultipleRows';
import { GridOneRow } from './DashboardBoxGrids/GridOneRow';

export interface DashboardBoxProps {
  title: string;
  link: string;
  pointer?: boolean;
  subElements: [];
  allItemsCount: number;
  subElementsMultipleRows?: boolean;
}

export const DashboardBox: FC<DashboardBoxProps> = ({
  title,
  subElements,
  allItemsCount,
  link,
  subElementsMultipleRows= true,
  pointer = false,
}) => {

  const navigate = useNavigate();

  const defaultSx = {
    background: '#f7f7f7',
    height: '300px',
    cursor: 'pointer',
    pl: 2,
    pr: 2,
    borderRadius: 0,
    '&:hover': {
      background: '#fff',
      '&::before': { borderColor: 'transparent transparent transparent #fff' },
      '&::after': { borderColor: 'transparent transparent transparent #fff' },
    },
  };

  return (
    <Grid item xs={12} md={6} lg={subElementsMultipleRows ? 2.7 : 3.3}>
      <Paper
        sx={ pointer ? {
          position: 'relative',
          '&::before': {
            display: { xs: 'none', md: 'block' },
            content: '""',
            position: 'absolute',
            right: '-30px',
            bottom: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '150px 0 150px 30px',
            borderColor: 'transparent transparent transparent #f7f7f7',
            zIndex: 5,
          },
          '&::after': {
            content: '""',
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            right: '-31px',
            bottom: 0,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '150px 0 150px 30px',
            borderColor: 'transparent transparent transparent #eeee',
            zIndex: 2,
          },
          ...defaultSx,
        }
          : defaultSx}
        onClick={() => navigate(link)}
      >
        <Box height="100%" pt={3}>
          <DashboardCardTitle title={title} numberOfElements={allItemsCount} />
          <Box height="90%" display="flex" justifyContent="center" alignItems="center">
            {subElementsMultipleRows ?
              <GridMultipleRows subElements={subElements} /> : <GridOneRow subElements={subElements} title={title} />
            }
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
};