import React, { FC, useContext } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { LocalizationContext } from 'contexts';
import { useControls } from 'shared/hooks/useControls';

export const ControlAutocomplete: FC<any> = ({ label, TextFieldProps, ...props }) => {

  const { dictionary } = useContext(LocalizationContext);
  const { controls } = useControls();

  return (
    <Autocomplete
      options={controls}
      renderInput={props => (
        <TextField
          {...props}
          label={label || dictionary.riskControls.form.fieldControl}
          InputLabelProps={{ shrink: true }}
          {...TextFieldProps}
        />
      )}
      getOptionLabel={(e: any) => e.title}
      {...props}
      onChange={(e, value) => props.onChange(value)}
    />
  );
};
