import React, { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Box, Grid, IconButton, Paper, SvgIconTypeMap, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { routes } from 'config';
import { LayoutContext, LocalizationContext } from 'contexts';
import { getDashboardElementType } from 'helpers/dashboard';
import { StatusIcon } from 'shared/components/form/StatusIcon/StatusIcon';
import { CircularReportProgress } from 'shared/components/layout/CircularReportProgress/CircularReportProgress';
import { DashboardCardTitle } from './DashboardCardTitle';

interface InnerGridProps {
    innerGridTitle?: string;
    elements: any[];
    color?: string;
    mainTitle: any;
    StartIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}

export const InnerGrid: FC<InnerGridProps> = ({ StartIcon, ...props }) => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);
  const { isMobile } = useContext(LayoutContext);

  const handleNavigate = useCallback((id: number) => {
    const elementType = getDashboardElementType(dictionary, props.mainTitle.toLowerCase()).toLowerCase();

    switch (elementType) {
    case dictionary.dashboard.tasks.toLowerCase():
      navigate(routes.editTask(id.toString()));
      break;
    case dictionary.dashboard.reports.toLowerCase():
      navigate(routes.fulfillReport(id.toString()));
      break;
    case dictionary.dashboard.documents.toLowerCase():
      navigate(routes.editDocument(id.toString()));
      break;
    default:
      break;
    }
  }, [dictionary, navigate, props.mainTitle]);

  return (
    <>
      {props?.innerGridTitle &&
          <Box mb={{ xs: 1, sm: 3 }} mt={{ xs: 1, sm: 3 }}>
            <DashboardCardTitle title={props.innerGridTitle} numberOfElements={props.elements?.length} color={props.color}/>
          </Box>
      }
      <Grid container direction="column" wrap="nowrap" sx={{ height: '40vh', overflow: 'auto' }}>
        {props?.elements.map((element: any) => (
          <Grid item key={element.id} height="70px" mb={1}>
            <Box component={Paper} sx={{ background: '#fff' }}>
              <Grid
                container
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                pl={{ xs: 1, sm: 2 }}
                pr={{ xs: 1, sm: 2 }}
                pt={{ xs: 0, sm: 1 }}
                pb={{ xs: 0, sm: 1 }}
              >
                {element?.progress &&
                    <CircularReportProgress
                      value={Math.round(element?.progress.percentage)}
                      answered={element?.progress.answered}
                      total={element?.progress.total}
                    />
                }
                {props?.color &&
                    <StatusIcon color={props.color} />
                }
                <Grid item sx={{ maxWidth: { xs: '80%', lg: '60%' } }}>
                  <Grid container direction="column" display="flex" justifyContent="center" rowSpacing={{ xs: 0, sm: 1 }}>
                    <Grid item>
                      <Typography variant={isMobile ? 'subtitle1' : 'body1'}>
                        {element.title.length >= 20 ? element.title.substr(0, 20) + '...' : element.title}
                      </Typography>
                    </Grid>
                    {element?.updatedAt &&
                        <Grid item>
                          <Typography variant="subtitle2" sx={{ opacity: 0.5 }}>{element.updatedAt}</Typography>
                        </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item display="flex" justifyContent="center">
                  <IconButton onClick={() => handleNavigate(element.id)}>
                    <ChevronRight />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};