import React, { createContext, FC, useCallback, useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';

import { useAuthState } from 'react-firebase-hooks/auth';

import { setUserEmailDimension, setUserCompanyDimension } from 'tracking/tracking';
import { firebaseClient } from 'clients/firebaseClient';
import { UserType } from 'clients/users/userClient.types';
import { useMe } from 'shared/hooks/useMe';


import { ContextProps } from './types';

export const defaultContext: ContextProps = {
  user: undefined,
  isAdmin: false,
  login: () => {},
  impersonate: () => {},
  ssoLogin: () => {},
  logout: () => {},
  isLoggedIn: false,
  loading: false,
  error: undefined,
  firebaseUser: undefined,
};


export const UserContext = createContext(defaultContext);
export const UserContextProvider: FC = ({ children }) => {

  const [firebaseUser, loading, error] = useAuthState(firebaseClient.getAuth());

  const queryClient = useQueryClient();
  const { user } = useMe({
    enabled: !!firebaseUser && !firebaseUser.isAnonymous,
  });

  const isLoggedIn = useMemo(() => !!firebaseUser, [firebaseUser]);


  const isPWA = useMemo(() => {
    return window.hasOwnProperty('matchMedia') && window?.matchMedia('(display-mode: standalone)').matches;
  }, []);

  const isAdmin = useMemo(() => user?.type === UserType.Admin, [user]);

  const ssoLogin = async (provider: string) => {
    try {
      let ssoProvider: any = new firebaseClient.auth.GoogleAuthProvider();
      if(provider === 'twitter') {
        ssoProvider = new firebaseClient.auth.TwitterAuthProvider();
      }
      if(provider === 'microsoft') {
        ssoProvider = new firebaseClient.auth.OAuthProvider('microsoft.com');
      }
      if(provider === 'facebook') {
        ssoProvider = new firebaseClient.auth.FacebookAuthProvider();
      }
      if(provider === 'google') {
        ssoProvider.addScope('email');
        ssoProvider.addScope('profile');
        ssoProvider.setCustomParameters({ prompt: 'select_account' });
      }
      try {
        if(isPWA) {
          firebaseClient.auth.signInWithRedirect(ssoProvider);
          return Promise.resolve({
            success: true,
          });
        } else {
          const response = await firebaseClient.auth.signInWithPopup(ssoProvider);

          if (response.user) {
            return Promise.resolve({
              success: true,
            });
          } else {
            return Promise.reject({
              success: false,
              message: 'Wrong Email',
            });
          }
        }
      } catch(e: any) {
        return Promise.reject({
          success: false,
          message: e.message,
        });
      }
    } catch(error: any) {
      return Promise.reject({
        success: false,
        error: error.code,
        message: error.message,
      });
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const response = await firebaseClient.auth.signInWithEmailAndPassword(email, password);
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        error: error.code,
        message: error.message,
      });
    }
  };

  const impersonate = async (token: string) => {
    try {
      const response = await firebaseClient.auth.signInWithCustomToken(token);
      return Promise.resolve({
        success: !!response,
      });
    } catch (error: any) {
      return Promise.reject({
        error: error.code,
        message: error.message,
      });
    }
  };

  const logout = useCallback(() => {
    firebaseClient.auth.signOut();
    queryClient.clear();
  }, [queryClient]);
  
  useEffect(() => {
    if(firebaseUser?.email) {
      setUserEmailDimension(firebaseUser.email);
    }
  }, [firebaseUser?.email]);

  useEffect(() => {
    if(user?.company?.name) {
      setUserCompanyDimension(user.company.name);
    }
  }, [user?.company]);


  return (
    <UserContext.Provider
      value={{
        user,
        firebaseUser,
        isAdmin,
        login,
        logout,
        ssoLogin,
        impersonate,
        isLoggedIn,
        loading,
        error,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
