import React, { FC, useContext } from 'react';
import {
  Grid,
} from '@mui/material';

import { CompanyContext } from 'contexts';
import { useUsers } from 'shared/hooks/useUsers';
import { BodyLoading } from 'shared/components/layout/BodyLoading/BodyLoading';
import { Filters } from 'views/LoggedIn/Users/partials/UsersFilters';
import { UserCard } from 'views/LoggedIn/Users/components/UserCard/UserCard';

interface Props {
  filters: Filters;
}

export const UsersList: FC<Props> = ({ filters }) => {

  const { company } = useContext(CompanyContext);

  const { users, status } = useUsers({
    company: company?.id,
    ...filters,
  });

  if (status === 'loading' || !users) {
    return <BodyLoading height="100vh"/>;
  }

  return (
    <Grid container spacing={2}>
      {users.map(user => (
        <Grid key={user.id} item xs={12} sm={6} lg={4}>
          <UserCard {...user} />
        </Grid>
      ))}
    </Grid>
  );
};
